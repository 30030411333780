// packages/web/src/components/reminders/ActiveReminders.tsx
import React, { useState, useEffect } from 'react';
import { Bell, ChevronRight, ChevronDown, Calendar, AlertTriangle, X, Edit } from 'lucide-react';
import { Contact } from '../../types';
import { Reminder, reminderService } from '../../services/reminderService';
import { api } from '../../services/api';
import ReminderForm from './ReminderForm';

const ActiveReminders: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [remindersByContact, setRemindersByContact] = useState<Record<number, Reminder[]>>({});
  const [expandedContacts, setExpandedContacts] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingReminder, setEditingReminder] = useState<Reminder | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Fetch all contacts
      const contactsData = await api.getContacts();
      setContacts(contactsData);
      
      // Get reminders for each contact with an email
      const reminderMap: Record<number, Reminder[]> = {};
      
      for (const contact of contactsData) {
        if (contact.email) {
          const reminders = await reminderService.getRemindersForContact(contact.id);
          // Only keep pending reminders
          const pendingReminders = reminders.filter(r => r.status === 'pending');
          
          if (pendingReminders.length > 0) {
            reminderMap[contact.id] = pendingReminders;
          }
        }
      }
      
      setRemindersByContact(reminderMap);
      
      // Expand contacts with active reminders by default
      setExpandedContacts(Object.keys(reminderMap).map(id => parseInt(id)));
    } catch (err) {
      console.error('Failed to fetch reminders data:', err);
      setError('Failed to load reminders');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleExpand = (contactId: number) => {
    setExpandedContacts(prev => 
      prev.includes(contactId)
        ? prev.filter(id => id !== contactId)
        : [...prev, contactId]
    );
  };

  const handleUpdateReminder = async (reminderData: any) => {
    if (!editingReminder) return;
    
    try {
      await reminderService.updateReminder(editingReminder.id, reminderData);
      setEditingReminder(null);
      // Refresh data after update
      fetchData();
    } catch (err) {
      console.error('Failed to update reminder:', err);
    }
  };

  const handleDeleteReminder = async (reminder: Reminder) => {
    if (!window.confirm('Are you sure you want to delete this reminder?')) {
      return;
    }
    
    try {
      await reminderService.deleteReminder(reminder.id);
      // Refresh the data
      fetchData();
    } catch (err) {
      console.error('Failed to delete reminder:', err);
    }
  };

  const handleCancelReminder = async (reminder: Reminder) => {
    try {
      await reminderService.cancelReminder(reminder.id);
      // Refresh the data
      fetchData();
    } catch (err) {
      console.error('Failed to cancel reminder:', err);
    }
  };

  // Get contacts with active reminders
  const contactsWithReminders = contacts.filter(contact => 
    remindersByContact[contact.id] && remindersByContact[contact.id].length > 0
  );

  // Format the reminder date for display
  const formatReminderDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-6">Active Reminders</h2>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Active Reminders</h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center">
          <AlertTriangle size={20} className="mr-2" />
          {error}
        </div>
      )}
      
      {contactsWithReminders.length === 0 ? (
        <div className="text-center py-8 bg-white rounded-lg border">
          <Bell size={40} className="mx-auto mb-3 text-gray-300" />
          <p className="text-gray-500">No active reminders</p>
        </div>
      ) : (
        <div className="space-y-4">
          {contactsWithReminders.map(contact => (
            <div key={contact.id} className="bg-white rounded-lg border overflow-hidden">
              <button
                onClick={() => handleToggleExpand(contact.id)}
                className="w-full px-4 py-3 flex items-center justify-between bg-gray-50 hover:bg-gray-100"
              >
                <div className="flex items-center gap-2">
                  {expandedContacts.includes(contact.id) ? (
                    <ChevronDown size={20} />
                  ) : (
                    <ChevronRight size={20} />
                  )}
                  <span className="font-medium">{contact.name}</span>
                  <span className="text-gray-500 text-sm">
                    ({remindersByContact[contact.id]?.length || 0} active)
                  </span>
                </div>
              </button>
              
              {expandedContacts.includes(contact.id) && remindersByContact[contact.id] && (
                <div className="p-4 space-y-2">
                  {remindersByContact[contact.id]
                    .sort((a, b) => {
                      // Sort by next send date
                      if (!a.nextSendDate && !b.nextSendDate) return 0;
                      if (!a.nextSendDate) return 1;
                      if (!b.nextSendDate) return -1;
                      return new Date(a.nextSendDate).getTime() - new Date(b.nextSendDate).getTime();
                    })
                    .map(reminder => (
                      <div
                        key={reminder.id}
                        className="flex items-start justify-between p-3 rounded-lg border border-blue-100 bg-blue-50"
                      >
                        <div className="flex-1">
                          <div className="font-medium">{reminder.subject}</div>
                          
                          <div className="flex items-center text-sm text-gray-600 mt-1">
                            <Calendar size={14} className="mr-1" />
                            <span>
                              Due: {formatReminderDate(reminder.nextSendDate || reminder.date)}
                            </span>
                          </div>
                          
                          <div className="text-sm text-gray-600 mt-1">
                            Type: {reminder.type === 'once' ? 'One time' : reminder.type}
                          </div>
                        </div>
                        
                        <div className="flex space-x-1">
                          <button
                            onClick={() => setEditingReminder(reminder)}
                            className="p-1 text-blue-500 hover:text-blue-700"
                            title="Edit reminder"
                          >
                            <Edit size={16} />
                          </button>
                          
                          <button
                            onClick={() => handleCancelReminder(reminder)}
                            className="p-1 text-gray-500 hover:text-gray-700"
                            title="Cancel reminder"
                          >
                            <Bell size={16} className="line-through" />
                          </button>
                          
                          <button
                            onClick={() => handleDeleteReminder(reminder)}
                            className="p-1 text-red-500 hover:text-red-700"
                            title="Delete reminder"
                          >
                            <X size={16} />
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      
      {editingReminder && (
        <ReminderForm
          contact={contacts.find(c => c.id === editingReminder.contactId)!}
          initialData={{
            contactId: editingReminder.contactId,
            type: editingReminder.type,
            subject: editingReminder.subject,
            message: editingReminder.message,
            date: editingReminder.date,
            time: editingReminder.time,
            endDate: editingReminder.endDate,
            occurrences: editingReminder.occurrences,
            interval: editingReminder.interval,
          }}
          isEditing={true}
          onSave={handleUpdateReminder}
          onCancel={() => setEditingReminder(null)}
        />
      )}
    </div>
  );
};

export default ActiveReminders;