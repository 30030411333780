// Import your types
import { Contact } from '../types';

// Define the API base URL
const API_URL = '/api';

export const api = {
  // Auth
  getCurrentUser: async () => {
    const response = await fetch(`${API_URL}/me`, {
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Not authenticated');
    return response.json();
  },

  loginWithGoogle: () => {
    window.location.href = `${API_URL}/auth/google`;
  },

  logout: async () => {
    try {
      // Clear local storage token
      localStorage.removeItem('authToken');
      
      // Call the logout endpoint
      await fetch(`${API_URL}/auth/logout`, {
        credentials: 'include'
      });
      
      // Reset the local application state
      window.location.href = window.location.origin;
    } catch (error) {
      console.error('Logout failed:', error);
      // Even if the logout fails, reset the client-side state
      window.location.href = window.location.origin;
    }
  },
  
  // Token-based authentication
  verifyToken: async (token: string) => {
    const response = await fetch(`${API_URL}/verify-token`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    });
    
    return response.json();
  },

  // Contacts
  getContacts: async () => {
    const response = await fetch(`${API_URL}/contacts`, {
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to fetch contacts');
    return response.json();
  },

  createContact: async (contactData: Omit<Contact, 'id'>) => {
    const response = await fetch(`${API_URL}/contacts`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactData),
    });
    if (!response.ok) throw new Error('Failed to create contact');
    return response.json();
  },

  updateContact: async (id: number, contactData: Partial<Contact>) => {
    const response = await fetch(`${API_URL}/contacts/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactData),
    });
    if (!response.ok) throw new Error('Failed to update contact');
    return response.json();
  },

  deleteContact: async (id: number) => {
    try {
      console.log('Deleting contact with ID:', id);
      
      const response = await fetch(`${API_URL}/contacts/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        // Try to get detailed error message from response
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || errorData.message || `Error: HTTP ${response.status}`;
        } catch (e) {
          // If we can't parse JSON, use response text or status
          errorMessage = await response.text().catch(() => `Error: HTTP ${response.status}`);
        }
        
        console.error('Delete contact API error:', errorMessage);
        throw new Error(errorMessage);
      }
      
      // Success - return true for 204 No Content
      return true;
    } catch (error) {
      console.error('API deleteContact error:', error);
      throw error;
    }
  },

  // Groups
  getGroups: async () => {
    try {
      const response = await fetch(`${API_URL}/groups`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch groups');
      return response.json();
    } catch (error) {
      console.error('getGroups error:', error);
      throw error;
    }
  },

  createGroup: async (groupData: any) => {
    const response = await fetch(`${API_URL}/groups`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(groupData),
    });
    if (!response.ok) throw new Error('Failed to create group');
    return response.json();
  },

  updateGroup: async (id: number, groupData: any) => {
    const response = await fetch(`${API_URL}/groups/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(groupData),
    });
    if (!response.ok) throw new Error('Failed to update group');
    return response.json();
  },

  deleteGroup: async (id: number) => {
    const response = await fetch(`${API_URL}/groups/${id}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to delete group');
  },

  // Todos
  getTodos: async (contactId: number) => {
    const response = await fetch(`${API_URL}/todos/contact/${contactId}`, {
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to fetch todos');
    return response.json();
  },

  createTodo: async (contactId: number, todoData: any) => {
    const response = await fetch(`${API_URL}/todos/contact/${contactId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(todoData),
    });
    if (!response.ok) throw new Error('Failed to create todo');
    return response.json();
  },

  updateTodo: async (todoId: string, todoData: any) => {
    const response = await fetch(`${API_URL}/todos/${todoId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(todoData),
    });
    if (!response.ok) throw new Error('Failed to update todo');
    return response.json();
  },

  deleteTodo: async (todoId: string) => {
    const response = await fetch(`${API_URL}/todos/${todoId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (!response.ok) throw new Error('Failed to delete todo');
  },

  // Interactions
  getInteractions: async (contactId: number) => {
    const response = await fetch(`${API_URL}/interactions/contact/${contactId}`, {
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to fetch interactions');
    return response.json();
  },

  createInteraction: async (contactId: number, interactionData: any) => {
    const response = await fetch(`${API_URL}/interactions/contact/${contactId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(interactionData),
    });
    if (!response.ok) throw new Error('Failed to create interaction');
    return response.json();
  },

  updateInteraction: async (interactionId: number, interactionData: any) => {
    const response = await fetch(`${API_URL}/interactions/${interactionId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(interactionData),
    });
    if (!response.ok) throw new Error('Failed to update interaction');
    return response.json();
  },

  deleteInteraction: async (interactionId: number) => {
    try {
      console.log('Deleting interaction with ID:', interactionId);
      
      const response = await fetch(`${API_URL}/interactions/${interactionId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        // Try to get detailed error message
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || errorData.message || `Error: HTTP ${response.status}`;
        } catch (e) {
          // If we can't parse JSON, use response text or status
          errorMessage = await response.text().catch(() => `Error: HTTP ${response.status}`);
        }
        
        console.error('Delete interaction API error:', errorMessage);
        throw new Error(errorMessage);
      }
      
      // Success
      return true;
    } catch (error) {
      console.error('API deleteInteraction error:', error);
      throw error;
    }
  },

    // User Settings
  getUserSettings: async () => {
    const response = await fetch(`${API_URL}/user/settings`, {
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to fetch user settings');
    return response.json();
  },

  updateUserSettings: async (settings: any) => {
    const response = await fetch(`${API_URL}/user/settings`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    });
    if (!response.ok) throw new Error('Failed to update user settings');
    return response.json();
  },

  // This function checks all contacts and updates their warm/cold status based on settings
  updateContactStatuses: async () => {
    const response = await fetch(`${API_URL}/contacts/update-status`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) throw new Error('Failed to update contact statuses');
    return response.json();
  },
  
  // Get reminder counts for multiple contacts at once
  getBatchReminderCounts: async (contactIds: number[]) => {
    if (!contactIds || contactIds.length === 0) return {};
    
    try {
      const response = await fetch(`${API_URL}/reminders/counts`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contactIds }),
      });
      
      if (!response.ok) throw new Error('Failed to fetch reminder counts');
      
      return response.json();
    } catch (error) {
      console.error('Failed to fetch batch reminder counts:', error);
      return {};
    }
  },
  
  // Notifications
  getNotifications: async () => {
    try {
      const response = await fetch(`${API_URL}/notifications`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch notifications');
      return response.json();
    } catch (error) {
      console.error('Error fetching notifications:', error);
      throw error;
    }
  },
  
  markNotificationAsRead: async (id: string) => {
    try {
      const response = await fetch(`${API_URL}/notifications/${id}/read`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) throw new Error('Failed to mark notification as read');
      return response.json();
    } catch (error) {
      console.error('Error marking notification as read:', error);
      throw error;
    }
  },
  
  markAllNotificationsAsRead: async () => {
    try {
      const response = await fetch(`${API_URL}/notifications/mark-all-read`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) throw new Error('Failed to mark all notifications as read');
      return response.json();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      throw error;
    }
  },
  
  deleteNotification: async (id: string) => {
    try {
      const response = await fetch(`${API_URL}/notifications/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to delete notification');
      return true;
    } catch (error) {
      console.error('Error deleting notification:', error);
      throw error;
    }
  },
  
  generateNotifications: async () => {
    try {
      const response = await fetch(`${API_URL}/notifications/generate/all`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) throw new Error('Failed to generate notifications');
      return response.json();
    } catch (error) {
      console.error('Error generating notifications:', error);
      throw error;
    }
  },

  telegramConnection: {
    getStatus: async () => {
      try {
        const response = await fetch('/api/telegram/status', {
          credentials: 'include'
        });
        if (!response.ok) throw new Error('Failed to get Telegram connection status');
        return response.json();
      } catch (error) {
        console.error('Error fetching Telegram status:', error);
        throw error;
      }
    },
    
    generatePairingCode: async () => {
      try {
        const response = await fetch('/api/telegram/pairing-code', {
          method: 'POST', // Changed to POST as generating a code is an action
          credentials: 'include'
        });
        if (!response.ok) throw new Error('Failed to generate Telegram pairing code');
        return response.json();
      } catch (error) {
        console.error('Error generating Telegram pairing code:', error);
        throw error;
      }
    },
    
    disconnect: async () => {
      try {
        const response = await fetch('/api/telegram/disconnect', {
          method: 'POST',
          credentials: 'include'
        });
        if (!response.ok) throw new Error('Failed to disconnect Telegram');
        return response.json();
      } catch (error) {
        console.error('Error disconnecting Telegram:', error);
        throw error;
      }
    }
  }
};

