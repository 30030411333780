import React, { useRef } from 'react';
import { UserPlus, Users, Calendar, Bell, ThermometerSun, MessageSquare, Bot, Send, Check, ArrowRight } from 'lucide-react';

interface LandingPageProps {
  onLogin: () => void;
}

const LandingPage: React.FC<LandingPageProps> = ({ onLogin }) => {
  // Create a ref for the "How It Works" section
  const howItWorksRef = useRef<HTMLElement>(null);

  // Function to scroll to the "How It Works" section
  const scrollToHowItWorks = () => {
    howItWorksRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white overflow-x-hidden">
      {/* Navbar */}
      <nav className="w-full py-4 px-6 flex justify-between items-center bg-white/80 backdrop-blur-md fixed top-0 z-10">
        <div className="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="w-8 h-8">
            <circle cx="100" cy="100" r="90" fill="none" stroke="#3B82F6" strokeWidth="8" />
            <circle cx="100" cy="100" r="60" fill="none" stroke="#3B82F6" strokeWidth="8" />
            <circle cx="100" cy="80" r="20" fill="#3B82F6" />
            <path d="M60,150 C60,120 140,120 140,150" fill="#3B82F6" stroke="#3B82F6" strokeWidth="2" />
          </svg>
          <span className="text-xl font-bold text-gray-800">Inner Circle</span>
        </div>
        <div className="flex items-center gap-4">
          <a 
            href="/pricing" 
            className="text-blue-600 hover:text-blue-800 font-medium px-4 py-2 rounded-lg border border-blue-200 bg-blue-50 hover:bg-blue-100 transition-colors"
            onClick={() => {
              // Track analytics for pricing navigation
              if (window.gtag) {
                window.gtag('event', 'navigation', {
                  from: 'home',
                  to: 'pricing',
                  method: 'navbar_button'
                });
              }
            }}
          >
            Pricing
          </a>
          <button onClick={onLogin} className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg shadow-md transition-all hover:scale-105 font-medium">
            Login
          </button>
        </div>
      </nav>

      {/* Hero Section - Updated for better immediate understanding */}
      <section className="relative pt-32 pb-20 px-4 lg:min-h-[80vh] flex items-center">
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-12 items-center">
          <div className="text-left">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 text-gray-800 leading-tight">
              Never Lose Touch With Your <span className="text-blue-600">Inner Circle</span>
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              A personal CRM powered by AI that helps you maintain meaningful relationships through natural conversation.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button 
                onClick={onLogin} 
                className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-6 rounded-lg text-lg font-medium shadow-lg flex items-center justify-center"
              >
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
                  />
                </svg>
                Login with Google
              </button>
              <button 
                onClick={scrollToHowItWorks} 
                className="border border-gray-300 hover:border-gray-400 text-gray-700 px-8 py-6 rounded-lg text-lg font-medium shadow-sm hover:shadow flex items-center justify-center group"
              >
                See how it works
                <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
              </button>
            </div>
          </div>
          
          {/* Rest of hero section remains unchanged */}
          <div className="relative order-first md:order-last">
            {/* Chat demo content (unchanged) */}
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-4 max-w-md mx-auto transform rotate-1 hover:rotate-0 transition-transform duration-300">
              {/* Chat demo content */}
              <div className="flex items-center gap-2 mb-4 p-2 border-b">
                <Bot size={20} className="text-blue-600" />
                <h3 className="font-medium">Inner Circle Assistant</h3>
              </div>
              
              <div className="space-y-3 mb-4">
                <div className="bg-blue-500 text-white p-3 rounded-lg rounded-br-none max-w-xs ml-auto text-sm">
                  Remind me to call Mom for her birthday tomorrow
                </div>
                
                <div className="bg-gray-100 p-3 rounded-lg rounded-bl-none max-w-sm text-sm">
                  <p className="mb-2">I've set a reminder to call your mom for her birthday tomorrow at 10:00 AM.</p>
                  <div className="flex items-center text-blue-700 text-xs">
                    <Check size={14} className="mr-1 flex-shrink-0" />
                    Added to your calendar with a reminder
                  </div>
                </div>
                
                <div className="bg-blue-500 text-white p-3 rounded-lg rounded-br-none max-w-xs ml-auto text-sm">
                  Who haven't I talked to in over a month?
                </div>
                
                <div className="bg-gray-100 p-3 rounded-lg rounded-bl-none max-w-sm text-sm">
                  <p className="mb-2">Based on your interaction history, you haven't connected with these people in over a month:</p>
                  <ul className="list-disc pl-5 space-y-1 text-gray-700">
                    <li>Sarah Johnson (last contact: 45 days ago)</li>
                    <li>David Lee (last contact: 38 days ago)</li>
                    <li>Emma Clark (last contact: 32 days ago)</li>
                  </ul>
                </div>
              </div>
              
              <div className="flex items-center gap-2 mt-4 bg-white rounded-full border p-2 pl-4">
                <input
                  type="text"
                  placeholder="Chat with your assistant..."
                  className="flex-1 outline-none text-sm"
                  disabled
                />
                <button className="bg-blue-500 text-white p-2 rounded-full">
                  <Send size={14} />
                </button>
              </div>
            </div>
            
            {/* Decorative elements */}
            <div className="absolute -z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-blue-200 rounded-full opacity-20 blur-3xl"></div>
            <div className="absolute -z-10 -bottom-10 -right-10 w-40 h-40 bg-purple-200 rounded-full opacity-30 blur-2xl"></div>
          </div>
        </div>
      </section>

      {/* Features Highlight - Condensed version to show key benefits quickly */}
      <section className="py-16 bg-gray-50">
        {/* Unchanged content */}
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4 text-gray-800">Voice or Text, It's Your Choice</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Control your personal CRM through natural conversation, whether you prefer to type or speak.
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <FeatureHighlight 
              icon={<MessageSquare size={28} className="text-blue-600" />}
              title="Chat Naturally"
              description="Speak or type commands in natural language to manage your relationships effortlessly."
            />
            
            <FeatureHighlight 
              icon={<ThermometerSun size={28} className="text-orange-500" />}
              title="Track Relationship Warmth"
              description="See which connections are growing cold and need attention."
            />
            
            <FeatureHighlight 
              icon={<Bell size={28} className="text-purple-500" />}
              title="Smart Reminders"
              description="Get intelligent prompts to reach out at the right times."
            />
          </div>
        </div>
      </section>

      {/* AI Assistant Demo Section - Add the ref here */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Your AI Relationship Assistant</h2>
          <p className="text-xl text-center mb-16 text-gray-600 max-w-3xl mx-auto">
            Simply chat with our AI assistant to manage contacts, schedule meetings, and keep your relationships strong.
          </p>
          
          <div className="flex flex-col lg:flex-row gap-12 items-stretch">
            <div className="lg:w-1/2">
              <div className="bg-gray-50 rounded-2xl p-6 shadow-md h-full border border-gray-100">
                <div className="flex items-center gap-2 mb-6 pb-2 border-b border-gray-200">
                  <Bot size={24} className="text-blue-600" />
                  <h3 className="font-semibold text-lg">Inner Circle Assistant</h3>
                </div>
                
                <div className="space-y-4">
                  <div className="bg-blue-500 text-white p-3 rounded-lg rounded-br-none max-w-xs ml-auto">
                    Add a new contact - John Smith from the marketing department
                  </div>
                  
                  <div className="bg-white p-4 rounded-lg rounded-bl-none max-w-sm border border-gray-100 shadow-sm">
                    <p className="mb-2">I'll add John Smith to your contacts.</p>
                    <div className="flex items-center text-blue-700 text-sm font-medium">
                      <Check size={16} className="mr-1" />
                      Created a new contact: John Smith
                    </div>
                  </div>
                  
                  <div className="bg-blue-500 text-white p-3 rounded-lg rounded-br-none max-w-xs ml-auto">
                    Schedule a meeting with him next Tuesday at 2pm
                  </div>
                  
                  <div className="bg-white p-4 rounded-lg rounded-bl-none max-w-sm border border-gray-100 shadow-sm">
                    <p className="mb-2">I've scheduled a meeting with John Smith for next Tuesday at 2:00 PM.</p>
                    <div className="flex items-center text-blue-700 text-sm font-medium">
                      <Check size={16} className="mr-1" />
                      Added calendar event: "Meeting with John Smith" on Tuesday, 2:00 PM with Google Meet link
                    </div>
                  </div>
                  
                  <div className="bg-blue-500 text-white p-3 rounded-lg rounded-br-none max-w-xs ml-auto">
                    Remind me to follow up with him in 3 days
                  </div>
                  
                  <div className="bg-white p-4 rounded-lg rounded-bl-none max-w-sm border border-gray-100 shadow-sm">
                    <p className="mb-2">I've set a reminder to follow up with John Smith in 3 days.</p>
                    <div className="flex items-center text-blue-700 text-sm font-medium">
                      <Check size={16} className="mr-1" />
                      Created a one-time email reminder for John Smith
                    </div>
                  </div>
                </div>
                
                <div className="flex items-center gap-2 mt-6 bg-white rounded-full border p-2 pl-4 shadow-sm">
                  <input
                    type="text"
                    placeholder="Chat with your assistant..."
                    className="flex-1 outline-none"
                    disabled
                  />
                  <button className="bg-blue-500 hover:bg-blue-600 transition-colors text-white p-2 rounded-full">
                    <Send size={16} />
                  </button>
                </div>
              </div>
            </div>
            
            <div className="lg:w-1/2 space-y-6">
              <div className="bg-blue-50 p-8 rounded-2xl border border-blue-100 shadow-sm">
                <h3 className="text-xl font-semibold mb-4 flex items-center gap-2 text-blue-800">
                  <Bot size={24} className="text-blue-600" />
                  Natural Language Commands
                </h3>
                <p className="mb-5 text-gray-700">Our AI understands natural language, so you can manage your relationships with simple commands:</p>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start gap-3">
                    <Check size={18} className="text-green-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium">"Add Sarah from accounting to my contacts"</span>
                      <p className="text-sm text-gray-500 mt-1">Creates a new contact with proper categorization</p>
                    </div>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check size={18} className="text-green-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium">"Schedule a coffee with David next Friday"</span>
                      <p className="text-sm text-gray-500 mt-1">Adds to your calendar with appropriate details</p>
                    </div>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check size={18} className="text-green-500 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium">"Who in my network have I not talked to in over a month?"</span>
                      <p className="text-sm text-gray-500 mt-1">Analyzes relationship history to identify neglected connections</p>
                    </div>
                  </li>
                </ul>
              </div>
              
              <div className="bg-gray-50 p-8 rounded-2xl border border-gray-200 shadow-sm">
                <h3 className="text-xl font-semibold mb-3 text-gray-800">Everything in One Chat</h3>
                <p className="text-gray-700">No more switching between apps or navigating complex interfaces. The Inner Circle AI Assistant lets you manage all your relationship tasks from a single chat interface.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section - Rearranged to be more visually interesting */}
      <section className="py-20 bg-gradient-to-br from-blue-50 via-white to-purple-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Powerful Features, Simple Interface</h2>
          <p className="text-xl text-center mb-16 text-gray-600 max-w-3xl mx-auto">
            All the tools you need to maintain strong relationships, with none of the complexity.
          </p>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard 
              icon={<Bot size={40} className="text-blue-600" />}
              title="AI-Powered Assistant"
              description="Chat naturally with your AI assistant to manage contacts, schedule meetings, and get relationship insights all in one place."
            />
            
            <FeatureCard 
              icon={<ThermometerSun size={40} className="text-orange-500" />}
              title="Relationship Warmth"
              description="Automatically identify when relationships are going cold so you can nurture them back to warm."
            />
            
            <FeatureCard 
              icon={<MessageSquare size={40} className="text-purple-500" />}
              title="Log Interactions"
              description="Easily record every meeting, call, and email to build a complete history of your relationships."
            />
            
            <FeatureCard 
              icon={<Users size={40} className="text-indigo-500" />}
              title="Smart Groups"
              description="Organize contacts into custom groups and get insights on how you're maintaining relationships with each group."
            />
            
            <FeatureCard 
              icon={<Bell size={40} className="text-yellow-500" />}
              title="Intelligent Reminders"
              description="Get timely reminders for birthdays, follow-ups, and keeping relationships warm - delivered right to your inbox."
            />
            
            <FeatureCard 
              icon={<Calendar size={40} className="text-green-500" />}
              title="Calendar Integration"
              description="Sync with Google Calendar to track meetings and automatically log interactions with your contacts."
            />
          </div>
        </div>
      </section>

      {/* How It Works Section - Add the ref here */}
      <section ref={howItWorksRef} className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-800">How Inner Circle Works</h2>
          
          <div className="grid md:grid-cols-3 gap-12 max-w-5xl mx-auto">
            <StepCard
              number="1"
              title="Chat with Your AI"
              description="Simply tell your AI assistant what you need, from adding contacts to scheduling meetings."
            />
            
            <StepCard
              number="2"
              title="Build Relationship History"
              description="Record interactions and let the AI track relationship warmth automatically."
            />
            
            <StepCard
              number="3"
              title="Never Miss a Connection"
              description="Get smart reminders and insights to keep your important relationships strong."
            />
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-800">What People Are Saying</h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            <TestimonialCard
              quote="The AI assistant is a game-changer. I just chat naturally about my contacts and meetings, and it handles everything for me. It's like having a personal relationship manager."
              author="Alex Johnson, Entrepreneur"
            />
            
            <TestimonialCard
              quote="As someone who struggles with remembering to keep in touch, Inner Circle's AI nudges me at just the right times to reconnect with people who matter."
              author="Maria Garcia, Project Manager"
            />
            
            <TestimonialCard
              quote="The combination of AI chat and relationship warmth tracking helps me prioritize which connections need attention. I've strengthened so many valuable relationships this way."
              author="David Chen, Sales Director"
            />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-blue-600 to-blue-700 text-white text-center">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">Ready to maintain stronger relationships?</h2>
          <p className="text-xl mb-10 text-blue-100">Join Inner Circle today and experience relationship management that feels like a natural conversation.</p>
          <button
            onClick={onLogin}
            className="bg-white text-blue-600 hover:bg-blue-50 px-8 py-6 rounded-lg text-lg font-medium shadow-lg flex items-center mx-auto"
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
              />
            </svg>
            Login with Google
          </button>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-10 bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-6 md:mb-0">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="w-8 h-8 mr-3">
                  <circle cx="100" cy="100" r="90" fill="none" stroke="#ffffff" strokeWidth="8" />
                  <circle cx="100" cy="100" r="60" fill="none" stroke="#ffffff" strokeWidth="8" />
                  <circle cx="100" cy="80" r="20" fill="#ffffff" />
                  <path d="M60,150 C60,120 140,120 140,150" fill="#ffffff" stroke="#ffffff" strokeWidth="2" />
                </svg>
                <span className="text-xl font-semibold">Inner Circle</span>
              </div>
              <p className="mt-2 text-gray-400">© 2025 Inner Circle. All rights reserved.</p>
            </div>
            <div className="flex gap-6">
              <a href="/pricing" className="text-gray-400 hover:text-white transition-colors">
                Pricing
              </a>
              <a href="/privacy-policy" className="text-gray-400 hover:text-white transition-colors">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureHighlight: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ 
  icon, title, description 
}) => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 text-center hover:shadow-md transition-shadow">
      <div className="inline-flex items-center justify-center w-14 h-14 rounded-full bg-blue-50 mb-4">
        {icon}
      </div>
      <h3 className="text-lg font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ 
  icon, title, description 
}) => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 h-full hover:shadow-md transition-shadow hover:translate-y-[-5px] transition-transform duration-300">
      <div className="mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-3 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const StepCard: React.FC<{ number: string; title: string; description: string }> = ({
  number, title, description
}) => {
  return (
    <div className="text-center relative">
      <div className="w-16 h-16 bg-blue-600 text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-6 relative z-10 shadow-md">
        {number}
      </div>
      <h3 className="text-xl font-semibold mb-3 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
      
      {/* Connector lines between steps (hidden on mobile) */}
      {number !== "3" && (
        <div className="hidden md:block absolute top-8 left-[calc(50%+2rem)] right-0 h-0.5 bg-blue-200 z-0"></div>
      )}
    </div>
  );
};

const TestimonialCard: React.FC<{ quote: string; author: string }> = ({ quote, author }) => {
  return (
    <div className="bg-white rounded-xl p-8 shadow-sm h-full border border-gray-100 hover:shadow-md transition-shadow">
      <svg className="w-10 h-10 text-blue-300 mb-6" fill="currentColor" viewBox="0 0 24 24">
        <path d="M6.5 10.5c3.5 0 3.5-4.5 0-4.5C3 6 1 10 1 12.5 1 15 2.5 18 6 18c2 0 3.5-1.5 3.5-3.5 0-2-1.5-4-3-4zm11.5 0c3.5 0 3.5-4.5 0-4.5-3.5 0-5.5 4-5.5 6.5 0 2.5 1.5 5.5 5 5.5 2 0 3.5-1.5 3.5-3.5 0-2-1.5-4-3-4z" />
      </svg>
      <p className="text-gray-600 mb-6 leading-relaxed">{quote}</p>
      <p className="font-medium text-gray-800">{author}</p>
    </div>
  );
};

export default LandingPage;