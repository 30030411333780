// packages/web/src/services/calendarService.ts
import { api } from './api';
import { reminderService } from './reminderService';

interface CalendarEvent {
  id: string;
  title: string;
  start: Date | string;
  end?: Date | string;
  type: 'meeting' | 'birthday' | 'todo' | 'reminder';
  contactId?: number;
  contactName?: string;
  location?: string;
  description?: string;
  meetLink?: string;
  allDay?: boolean;
  source: 'google' | 'inner-circle';
}

interface CalendarAuthStatus {
  isAuthorized: boolean;
  email?: string;
}

export const calendarService = {
  getAuthStatus: async (): Promise<CalendarAuthStatus> => {
    try {
      const response = await fetch('/api/calendar-auth/status', {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to get calendar authorization status');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error checking calendar auth status:', error);
      return { isAuthorized: false };
    }
  },
  
  getAuthUrl: async (): Promise<string> => {
    try {
      const response = await fetch('/api/calendar-auth/authorize', {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to generate authorization URL');
      }
      
      const data = await response.json();
      return data.authUrl;
    } catch (error) {
      console.error('Error getting calendar auth URL:', error);
      throw error;
    }
  },
  
  revokeAuthorization: async (): Promise<void> => {
    try {
      const response = await fetch('/api/calendar-auth/revoke', {
        method: 'POST',
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to revoke calendar authorization');
      }
    } catch (error) {
      console.error('Error revoking calendar auth:', error);
      throw error;
    }
  },
  
  getEvents: async (startDate: Date, endDate: Date): Promise<CalendarEvent[]> => {
    try {
      console.log('Fetching calendar events:', {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      });
      
      // Update the endpoint to match the new route
      const response = await fetch('/api/calendar/get-events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        }),
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Calendar events fetch error:', {
          status: response.status,
          statusText: response.statusText,
          errorText
        });
        throw new Error(`Failed to fetch calendar events: ${response.status} ${errorText}`);
      }
      
      const events = await response.json();
      console.log(`Received ${events.length} calendar events`);
      
      // Convert date strings to Date objects
      return events.map((event: any) => ({
        ...event,
        start: new Date(event.start),
        end: event.end ? new Date(event.end) : undefined,
        source: 'google'
      }));
    } catch (error) {
      console.error('Error fetching calendar events:', error);
      throw error;
    }
  },
  
  createEvent: async (eventData: any): Promise<CalendarEvent> => {
    try {
      // Ensure required fields are present before making the request
      if (!eventData.title) {
        throw new Error('Event title is required');
      }
      
      if (!eventData.start) {
        throw new Error('Event start date is required');
      }
      
      // Ensure end date exists, if not, set it to 30 minutes after start
      if (!eventData.end) {
        eventData.end = new Date(new Date(eventData.start).getTime() + 30 * 60 * 1000);
        console.log('Added default end date 30 minutes after start:', eventData.end);
      }
      
      // Convert dates to ISO strings if they're Date objects
      const formattedData = {
        ...eventData,
        start: eventData.start instanceof Date ? eventData.start.toISOString() : eventData.start,
        end: eventData.end instanceof Date ? eventData.end.toISOString() : eventData.end
      };
      
      console.log('Creating calendar event with formatted data:', {
        title: formattedData.title,
        start: formattedData.start,
        end: formattedData.end,
        // Don't log sensitive data
        hasAttendees: !!formattedData.attendees
      });
      
      const endpoint = '/api/calendar/events';
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
        credentials: 'include'
      });
      
      if (!response.ok) {
        // Get detailed error information
        const errorData = await response.json().catch(() => ({ error: 'Unknown error' }));
        throw new Error(`Failed to create event: ${response.status} - ${errorData.error || errorData.message || 'Unknown error'}`);
      }
      
      const event = await response.json();
      return {
        ...event,
        start: new Date(event.start),
        end: event.end ? new Date(event.end) : undefined,
        source: 'google'
      };
    } catch (error) {
      console.error('Error creating calendar event:', error);
      throw error;
    }
  },
  
  updateEvent: async (eventId: string, eventData: any): Promise<CalendarEvent> => {
    try {
      console.log(`Updating calendar event ${eventId} with data:`, {
        ...eventData,
        // Don't log entire attendees list for privacy
        attendees: eventData.attendees ? `[${eventData.attendees.length} attendees]` : undefined
      });
      
      const response = await fetch(`/api/calendar/events/${eventId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Calendar event update error:', {
          status: response.status,
          statusText: response.statusText,
          errorText
        });
        throw new Error(`Failed to update calendar event: ${response.status} ${errorText}`);
      }
      
      const event = await response.json();
      console.log('Successfully updated calendar event:', event.id);
      
      // Convert date strings to Date objects
      return {
        ...event,
        start: new Date(event.start),
        end: event.end ? new Date(event.end) : undefined,
        source: 'google'
      };
    } catch (error) {
      console.error('Error updating calendar event:', error);
      throw error;
    }
  },
  
  deleteEvent: async (eventId: string): Promise<void> => {
    try {
      console.log(`Deleting calendar event: ${eventId}`);
      
      const response = await fetch(`/api/calendar/events/${eventId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Calendar event deletion error:', {
          status: response.status,
          statusText: response.statusText,
          errorText
        });
        throw new Error(`Failed to delete calendar event: ${response.status} ${errorText}`);
      }
      
      console.log(`Successfully deleted calendar event: ${eventId}`);
    } catch (error) {
      console.error('Error deleting calendar event:', error);
      throw error;
    }
  },
  
  getReminders: async () => {
    try {
      return await reminderService.getAllReminders();
    } catch (error) {
      console.error('Error fetching reminders for calendar:', error);
      return [];
    }
  }
};

export default calendarService;