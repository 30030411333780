// src/components/settings/CalendarAuthorization.tsx
import React, { useState, useEffect } from 'react';
import { Calendar, AlertTriangle, CheckCircle, XCircle } from 'lucide-react';
import { calendarService } from '../../services/calendarService';

interface CalendarAuthorizationProps {
  onComplete?: () => void;
}

const CalendarAuthorization: React.FC<CalendarAuthorizationProps> = ({ onComplete }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const status = await calendarService.getAuthStatus();
      setIsAuthorized(status.isAuthorized);
      setUserEmail(status.email || '');
    } catch (err) {
      setError('Failed to check authorization status');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const startAuthorization = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const authUrl = await calendarService.getAuthUrl();
      
      // Open the authorization URL in a new window
      window.open(authUrl, 'google-calendar-auth', 'width=600,height=700');
      
      // Check status periodically
      const checkInterval = setInterval(async () => {
        const status = await calendarService.getAuthStatus();
        
        if (status.isAuthorized) {
          clearInterval(checkInterval);
          setIsAuthorized(true);
          setUserEmail(status.email || '');
          if (onComplete) onComplete();
        }
      }, 2000); // Check every 2 seconds
      
      // Clear interval after 2 minutes (maximum wait time)
      setTimeout(() => {
        clearInterval(checkInterval);
        setIsLoading(false);
      }, 2 * 60 * 1000);
    } catch (err) {
      setError('Failed to start authorization process');
      console.error(err);
      setIsLoading(false);
    }
  };

  const revokeAuthorization = async () => {
    if (!window.confirm('Are you sure you want to revoke calendar authorization? Your Google Calendar events will no longer appear in Inner Circle.')) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      await calendarService.revokeAuthorization();
      
      setIsAuthorized(false);
      setUserEmail('');
    } catch (err) {
      setError('Failed to revoke authorization');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-lg border shadow-sm mb-6">
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg border shadow-sm mb-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Calendar className="mr-2" />
        Google Calendar Authorization
      </h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-200 flex items-center">
          <AlertTriangle size={20} className="mr-2" />
          {error}
        </div>
      )}
      
      <div className="mb-4">
        <p className="text-gray-700 mb-2">
          Connecting Google Calendar allows you to see your events in Inner Circle and create interactions automatically from calendar meetings.
        </p>
        
        {isAuthorized ? (
          <div className="mt-4 p-3 bg-green-50 text-green-700 rounded-lg border border-green-200 flex items-center">
            <CheckCircle size={20} className="mr-2" />
            Calendar is connected to: <strong className="ml-1">{userEmail}</strong>
          </div>
        ) : (
          <div className="mt-4 p-3 bg-amber-50 text-amber-700 rounded-lg border border-amber-200 flex items-center">
            <XCircle size={20} className="mr-2" />
            Google Calendar is not connected. Connect your calendar to view and manage events.
          </div>
        )}
      </div>
      
      <div className="flex justify-end">
        {isAuthorized ? (
          <button
            onClick={revokeAuthorization}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
            disabled={isLoading}
          >
            Disconnect Calendar
          </button>
        ) : (
          <button
            onClick={startAuthorization}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            disabled={isLoading}
          >
            Connect Google Calendar
          </button>
        )}
      </div>
    </div>
  );
};

export default CalendarAuthorization;