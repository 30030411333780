// packages/web/src/components/reminders/RemindersList.tsx
import React, { useState, useEffect } from 'react';
import { 
  Calendar, 
  Clock, 
  Bell, 
  BellOff, 
  Edit, 
  Trash2, 
  AlertTriangle,
  Repeat, 
  ChevronRight, 
  ChevronDown 
} from 'lucide-react';
import { Contact } from '../../types';
import { Reminder, reminderService } from '../../services/reminderService';
import ReminderForm, { ReminderData } from './ReminderForm';
import { formatReminderTime as formatReminderTimeFromUtils } from '../../utils/dateUtils';

interface RemindersListProps {
  contact: Contact;
  onUpdateContact?: (updatedContact: Contact) => void;
}

const formatLocalReminderTime = (utcDateTime: string) => {
  // Convert from UTC to user's local time for display
  const date = new Date(utcDateTime);
  return date.toLocaleString(); // This uses the user's browser locale and timezone
};

const RemindersList: React.FC<RemindersListProps> = ({ contact, onUpdateContact }) => {
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [editingReminder, setEditingReminder] = useState<Reminder | null>(null);

  useEffect(() => {
    fetchReminders();
  }, [contact.id]);

  const fetchReminders = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await reminderService.getRemindersForContact(contact.id);
      setReminders(data);
    } catch (err) {
      setError('Failed to load reminders');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddReminder = async (reminderData: ReminderData) => {
    try {
      const newReminder = await reminderService.createReminder(reminderData);
      setReminders([...reminders, newReminder]);
      setIsAddModalOpen(false);
    } catch (err) {
      console.error('Failed to create reminder:', err);
      throw err;
    }
  };

  const handleUpdateReminder = async (reminderData: ReminderData) => {
    if (!editingReminder) return;
    
    try {
      const updatedReminder = await reminderService.updateReminder(
        editingReminder.id,
        reminderData
      );
      
      setReminders(reminders.map(reminder => 
        reminder.id === updatedReminder.id ? updatedReminder : reminder
      ));
      
      setEditingReminder(null);
    } catch (err) {
      console.error('Failed to update reminder:', err);
      throw err;
    }
  };

  const handleDeleteReminder = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this reminder?')) {
      return;
    }
    
    try {
      await reminderService.deleteReminder(id);
      setReminders(reminders.filter(reminder => reminder.id !== id));
    } catch (err) {
      console.error('Failed to delete reminder:', err);
      setError('Failed to delete reminder');
    }
  };

  const handleCancelReminder = async (id: string) => {
    try {
      const updatedReminder = await reminderService.cancelReminder(id);
      setReminders(reminders.map(reminder => 
        reminder.id === id ? updatedReminder : reminder
      ));
    } catch (err) {
      console.error('Failed to cancel reminder:', err);
      setError('Failed to cancel reminder');
    }
  };

  // Format the reminder type for display
  const formatReminderType = (reminder: Reminder): string => {
    switch (reminder.type) {
      case 'once':
        return 'One time';
      case 'multiple':
        return `${reminder.occurrences} times, every ${reminder.interval} days`;
      case 'daily':
        return 'Daily';
      case 'weekly':
        return 'Weekly';
      case 'monthly':
        return 'Monthly';
      case 'annually':
        return 'Annually';
      default:
        return reminder.type;
    }
  };

  // Format the reminder schedule for display
  const formatSchedule = (reminder: Reminder): string => {
    const date = new Date(reminder.date).toLocaleDateString();
    const time = reminder.time || '09:00';
    
    let schedule = `${date} at ${time}`;
    
    if (['daily', 'weekly', 'monthly', 'annually'].includes(reminder.type)) {
      schedule += ` (${reminder.type})`;
      
      if (reminder.endDate) {
        schedule += ` until ${new Date(reminder.endDate).toLocaleDateString()}`;
      }
    } else if (reminder.type === 'multiple') {
      schedule += `, repeating ${reminder.occurrences} times every ${reminder.interval} days`;
    }
    
    return schedule;
  };

  return (
    <div className="bg-white rounded-lg border p-4 mb-5 overflow-hidden">
      <div 
        className="flex justify-between items-center cursor-pointer mb-4" 
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center">
          <Bell className="mr-2 text-blue-500" size={20} />
          <h3 className="text-lg font-semibold">Email Reminders</h3>
          {reminders.length > 0 && (
            <span className="ml-2 bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full text-xs">
              {reminders.length}
            </span>
          )}
        </div>
        <div className="flex items-center">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsAddModalOpen(true);
            }}
            className="text-blue-600 hover:text-blue-800 mr-2 text-sm"
          >
            Add Reminder
          </button>
          {isExpanded ? (
            <ChevronDown size={20} className="text-gray-500" />
          ) : (
            <ChevronRight size={20} className="text-gray-500" />
          )}
        </div>
      </div>

      {isExpanded && (
        <>
          {error && (
            <div className="mb-4 p-2 bg-red-50 text-red-700 rounded-lg text-sm flex items-center">
              <AlertTriangle size={16} className="mr-1" />
              {error}
            </div>
          )}

          {isLoading ? (
            <div className="py-4 text-center text-gray-500">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500 mx-auto mb-2"></div>
              Loading reminders...
            </div>
          ) : reminders.length === 0 ? (
            <div className="py-4 text-center text-gray-500">
              <BellOff size={24} className="mx-auto mb-2 text-gray-400" />
              No reminders set for this contact.
              <div className="mt-2">
                <button
                  onClick={() => setIsAddModalOpen(true)}
                  className="text-blue-500 hover:text-blue-700 text-sm"
                >
                  Set up a reminder
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-3">
              {reminders.map((reminder) => (
                <div 
                  key={reminder.id}
                  className={`p-3 rounded-lg border ${
                    reminder.status === 'pending' 
                      ? 'border-blue-200 bg-blue-50' 
                      : reminder.status === 'sent'
                      ? 'border-green-200 bg-green-50'
                      : reminder.status === 'canceled'
                      ? 'border-gray-200 bg-gray-50'
                      : 'border-red-200 bg-red-50'
                  }`}
                >
                  <div className="flex justify-between">
                    <div className="font-medium">{reminder.subject}</div>
                    <div className="flex space-x-1">
                      {/* Only show edit button for pending reminders */}
                      {reminder.status === 'pending' && (
                        <button
                          onClick={() => setEditingReminder(reminder)}
                          className="p-1 text-blue-500 hover:text-blue-700"
                          title="Edit reminder"
                        >
                          <Edit size={16} />
                        </button>
                      )}
                      <button
                        onClick={() => handleCancelReminder(reminder.id)}
                        className={`p-1 text-gray-500 hover:text-gray-700 ${
                          reminder.status !== 'pending' ? 'hidden' : ''
                        }`}
                        title="Cancel reminder"
                      >
                        <BellOff size={16} />
                      </button>
                      <button
                        onClick={() => handleDeleteReminder(reminder.id)}
                        className="p-1 text-red-500 hover:text-red-700"
                        title="Delete reminder"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </div>
                  
                  <div className="mt-1 text-sm flex items-center">
                    <Calendar size={14} className="inline mr-1 text-gray-500" />
                    <span className="text-gray-600">{formatSchedule(reminder)}</span>
                  </div>
                  
                  <div className="mt-1 text-sm flex items-center">
                    <Repeat size={14} className="inline mr-1 text-gray-500" />
                    <span className="text-gray-600">{formatReminderType(reminder)}</span>
                  </div>

                  {reminder.status === 'pending' && reminder.nextSendDate && (
                    <div className="mt-1 text-sm text-blue-600">
                      Next reminder: {formatLocalReminderTime(reminder.nextSendDate)}
                    </div>
                  )}
                  
                  {reminder.status === 'sent' && (
                    <div className="mt-1 text-sm text-green-600">
                      Last sent: {new Date(reminder.nextSendDate || reminder.date).toLocaleDateString()}
                    </div>
                  )}
                  
                  {reminder.status === 'canceled' && (
                    <div className="mt-1 text-sm text-gray-600">
                      This reminder has been canceled
                    </div>
                  )}
                  
                  {reminder.status === 'failed' && (
                    <div className="mt-1 text-sm text-red-600">
                      Failed to send. Please check email authorization.
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Conditionally render the form for either adding or editing */}
      {(isAddModalOpen || editingReminder) && (
        <ReminderForm
          contact={contact}
          initialData={editingReminder ? {
            contactId: contact.id,
            type: editingReminder.type,
            subject: editingReminder.subject,
            message: editingReminder.message,
            date: editingReminder.date,
            time: editingReminder.time,
            endDate: editingReminder.endDate,
            occurrences: editingReminder.occurrences,
            interval: editingReminder.interval,
          } : undefined}
          isEditing={!!editingReminder}
          onSave={editingReminder ? handleUpdateReminder : handleAddReminder}
          onCancel={() => {
            setIsAddModalOpen(false);
            setEditingReminder(null);
          }}
        />
      )}
    </div>
  );
};

export default RemindersList;