import React, { useState, useEffect } from 'react';
import { 
  Calendar as CalendarIcon, 
  ChevronLeft, 
  ChevronRight, 
  Plus, 
  Video, 
  Clock, 
  Calendar as CalendarCheck, 
  AlertTriangle,
  Cake,
  CheckSquare,
  Bell
} from 'lucide-react';
import { api } from '../../services/api';
import { calendarService } from '../../services/calendarService';
import CalendarEventModal from './CalendarEventModal';
import CalendarAuthPrompt from './CalendarAuthPrompt';
import { CalendarEvent, EventType, convertToCalendarEvent } from '../../types/calendar';
import { Contact } from '../../types';

const ensureEventDates = (event: any): CalendarEvent => {
  return {
    ...event,
    start: event.start instanceof Date ? event.start : new Date(event.start),
    end: event.end ? (event.end instanceof Date ? event.end : new Date(event.end)) : undefined
  };
};

const Calendar: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState<'month' | 'week' | 'day'>('month');
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isCalendarConnected, setIsCalendarConnected] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  useEffect(() => {
    fetchCalendarStatus();
  }, []);

  useEffect(() => {
    if (isCalendarConnected) {
      fetchEvents();
    }
  }, [currentDate, currentView, isCalendarConnected]);

  const fetchCalendarStatus = async () => {
    try {
      const status = await calendarService.getAuthStatus();
      setIsCalendarConnected(status.isAuthorized);
      setShowAuthPrompt(!status.isAuthorized);
    } catch (err) {
      console.error('Error checking calendar authorization status:', err);
      setError('Failed to check calendar connection status');
      setShowAuthPrompt(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
    
    // Initialize event arrays
    let googleEvents: CalendarEvent[] = [];
    let birthdayEvents: CalendarEvent[] = [];
    let todoEvents: CalendarEvent[] = [];
    let reminderEvents: CalendarEvent[] = [];
    
    try {
      // Calculate the date range based on the current view
      const { startDate, endDate } = getDateRangeForView(currentDate, currentView);
      
      // Fetch events from Google Calendar
      if (isCalendarConnected) {
        try {
          const rawEvents = await calendarService.getEvents(startDate, endDate);
          // Convert to consistent format
          googleEvents = rawEvents.map(event => convertToCalendarEvent(event));
        } catch (err) {
          console.error('Error fetching Google Calendar events:', err);
          // Keep googleEvents as empty array but continue with other event types
        }
      }
      
      // Fetch other events from Inner Circle
      try {
        birthdayEvents = await fetchBirthdayEvents(startDate, endDate);
      } catch (err) {
        console.error('Error fetching birthday events:', err);
        birthdayEvents = [];
      }
      
      try {
        todoEvents = await fetchTodoEvents(startDate, endDate);
      } catch (err) {
        console.error('Error fetching todo events:', err);
        todoEvents = [];
      }
      
      try {
        reminderEvents = await fetchReminderEvents(startDate, endDate);
      } catch (err) {
        console.error('Error fetching reminder events:', err);
        reminderEvents = [];
      }
      
      // Combine all events
      setEvents([
        ...googleEvents,
        ...birthdayEvents,
        ...todoEvents,
        ...reminderEvents
      ]);
    } catch (err) {
      console.error('Error fetching calendar events:', err);
      setError('Failed to load calendar events');
      setEvents([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchBirthdayEvents = async (start: Date, end: Date): Promise<CalendarEvent[]> => {
    try {
      // Get all contacts
      const contacts = await api.getContacts();
      
      // Filter contacts with birthdays in the date range
      const birthdayEvents: CalendarEvent[] = [];
      
      contacts.forEach((contact: Contact) => {
        if (contact.birthday) {
          const birthdayDate = new Date(contact.birthday);
          // For each year in the range, create a birthday event
          const currentYear = start.getFullYear();
          for (let year = currentYear; year <= end.getFullYear(); year++) {
            const birthday = new Date(birthdayDate);
            birthday.setFullYear(year);
            
            if (birthday >= start && birthday <= end) {
              birthdayEvents.push({
                id: `birthday-${contact.id}-${year}`,
                title: `${contact.name}'s Birthday`,
                start: birthday,
                type: 'birthday',
                contactId: contact.id,
                contactName: contact.name,
                allDay: true,
                source: 'inner-circle'
              });
            }
          }
        }
      });
      
      return birthdayEvents;
    } catch (error) {
      console.error('Error fetching birthday events:', error);
      return [];
    }
  };

  const fetchTodoEvents = async (start: Date, end: Date): Promise<CalendarEvent[]> => {
    try {
      // Get all contacts with their todos
      const contacts = await api.getContacts();
      
      // Filter todos with due dates in the range
      const todoEvents: CalendarEvent[] = [];
      
      contacts.forEach((contact: Contact) => {
        if (contact.todos && contact.todos.length > 0) {
          contact.todos.forEach((todo: any) => {
            if (todo.dueDate && !todo.completed) {
              const dueDate = new Date(todo.dueDate);
              if (dueDate >= start && dueDate <= end) {
                todoEvents.push({
                  id: `todo-${todo.id}`,
                  title: todo.task,
                  start: dueDate,
                  type: 'todo',
                  contactId: contact.id,
                  contactName: contact.name,
                  allDay: true,
                  source: 'inner-circle'
                });
              }
            }
          });
        }
      });
      
      return todoEvents;
    } catch (error) {
      console.error('Error fetching todo events:', error);
      return [];
    }
  };

  const fetchReminderEvents = async (start: Date, end: Date): Promise<CalendarEvent[]> => {
    try {
      // Get all reminders
      const reminders = await calendarService.getReminders();
      
      // Filter reminders with dates in the range
      const reminderEvents: CalendarEvent[] = reminders
        .filter(reminder => {
          const reminderDate = reminder.nextSendDate 
            ? new Date(reminder.nextSendDate) 
            : new Date(reminder.date);
          return reminderDate >= start && reminderDate <= end && reminder.status === 'pending';
        })
        .map(reminder => {
          const reminderDate = reminder.nextSendDate 
            ? new Date(reminder.nextSendDate) 
            : new Date(reminder.date);
          
          // If there's a time component, use it
          if (reminder.time) {
            const [hours, minutes] = reminder.time.split(':').map(Number);
            reminderDate.setHours(hours, minutes);
          }
          
          return {
            id: `reminder-${reminder.id}`,
            title: reminder.subject,
            start: reminderDate,
            type: 'reminder',
            contactId: reminder.contactId,
            description: reminder.message,
            allDay: !reminder.time,
            source: 'inner-circle'
          };
        });
      
      return reminderEvents;
    } catch (error) {
      console.error('Error fetching reminder events:', error);
      return [];
    }
  };

  const getDateRangeForView = (date: Date, view: 'month' | 'week' | 'day') => {
    const startDate = new Date(date);
    const endDate = new Date(date);
    
    if (view === 'month') {
      startDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + 1, 0);
    } else if (view === 'week') {
      const day = startDate.getDay();
      startDate.setDate(startDate.getDate() - day);
      endDate.setDate(startDate.getDate() + 6);
    } else if (view === 'day') {
      // Start and end are the same day
    }
    
    return { startDate, endDate };
  };

  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    if (currentView === 'month') {
      newDate.setMonth(newDate.getMonth() - 1);
    } else if (currentView === 'week') {
      newDate.setDate(newDate.getDate() - 7);
    } else if (currentView === 'day') {
      newDate.setDate(newDate.getDate() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (currentView === 'month') {
      newDate.setMonth(newDate.getMonth() + 1);
    } else if (currentView === 'week') {
      newDate.setDate(newDate.getDate() + 7);
    } else if (currentView === 'day') {
      newDate.setDate(newDate.getDate() + 1);
    }
    setCurrentDate(newDate);
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  const handleAddEvent = () => {
    if (!isCalendarConnected) {
      setShowAuthPrompt(true);
      return;
    }
    setSelectedEvent(null);
    setIsAddModalOpen(true);
  };

  const handleSelectEvent = (event: CalendarEvent) => {
    setSelectedEvent(event);
    setIsAddModalOpen(true);
  };

  const handleSaveEvent = async (eventData: any) => {
    try {
      if (selectedEvent) {
        // Update existing event
        const updatedEvent = await calendarService.updateEvent(selectedEvent.id, eventData);
        setEvents(prev => prev.map(event => 
          event.id === updatedEvent.id ? ensureEventDates(updatedEvent) : event
        ));
      } else {
        // Create new event
        const newEvent = await calendarService.createEvent(eventData);
        setEvents(prev => [...prev, ensureEventDates(newEvent)]);
      }
      setIsAddModalOpen(false);
      fetchEvents(); // Refresh events
    } catch (err) {
      console.error('Error saving event:', err);
      setError('Failed to save event');
    }
  };

  const handleDeleteEvent = async (eventId: string) => {
    if (!window.confirm('Are you sure you want to delete this event?')) {
      return;
    }
    
    try {
      await calendarService.deleteEvent(eventId);
      setEvents(prev => prev.filter(event => event.id !== eventId));
      setIsAddModalOpen(false);
    } catch (err) {
      console.error('Error deleting event:', err);
      setError('Failed to delete event');
    }
  };

  const handleAuthRequest = async () => {
    try {
      const authUrl = await calendarService.getAuthUrl();
      // Open auth window
      window.open(authUrl, 'google-calendar-auth', 'width=600,height=700');
      
      // Poll for auth completion
      const checkInterval = setInterval(async () => {
        const status = await calendarService.getAuthStatus();
        if (status.isAuthorized) {
          clearInterval(checkInterval);
          setIsCalendarConnected(true);
          setShowAuthPrompt(false);
          fetchEvents(); // Refresh events after connection
        }
      }, 2000);
      
      // Stop polling after 2 minutes
      setTimeout(() => clearInterval(checkInterval), 2 * 60 * 1000);
    } catch (err) {
      console.error('Error requesting calendar authorization:', err);
      setError('Failed to initiate calendar authorization');
    }
  };

  const getEventIcon = (type: EventType) => {
    switch (type) {
      case 'meeting':
        return <Video size={16} className="text-purple-500" />;
      case 'birthday':
        return <Cake size={16} className="text-pink-500" />;
      case 'todo':
        return <CheckSquare size={16} className="text-blue-500" />;
      case 'reminder':
        return <Bell size={16} className="text-orange-500" />;
      default:
        return <CalendarCheck size={16} />;
    }
  };

  // Helper function to generate the days of a month view
  const generateMonthView = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    
    // First day of the month
    const firstDay = new Date(year, month, 1);
    // Last day of the month
    const lastDay = new Date(year, month + 1, 0);
    
    // Get day of week for first day (0 = Sunday, 1 = Monday, etc.)
    let firstDayOfWeek = firstDay.getDay();
    if (firstDayOfWeek === 0) firstDayOfWeek = 7; // Adjust Sunday to be 7
    
    // Number of days to show from previous month
    const daysFromPrevMonth = firstDayOfWeek - 1;
    
    // Total days in current month
    const daysInMonth = lastDay.getDate();
    
    // Create array for all days we'll display
    const days = [];
    
    // Add days from previous month
    const prevMonth = new Date(year, month, 0);
    const prevMonthDays = prevMonth.getDate();
    
    for (let i = prevMonthDays - daysFromPrevMonth + 1; i <= prevMonthDays; i++) {
      days.push({
        date: new Date(year, month - 1, i),
        isCurrentMonth: false
      });
    }
    
    // Add days from current month
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        date: new Date(year, month, i),
        isCurrentMonth: true
      });
    }
    
    // Add days from next month to complete the grid (6 rows of 7 days)
    const totalDaysToShow = 42; // 6 weeks
    const daysFromNextMonth = totalDaysToShow - days.length;
    
    for (let i = 1; i <= daysFromNextMonth; i++) {
      days.push({
        date: new Date(year, month + 1, i),
        isCurrentMonth: false
      });
    }
    
    return days;
  };

  // Get events for a specific day
  const getEventsForDay = (date: Date) => {
    const dayStart = new Date(date);
    dayStart.setHours(0, 0, 0, 0);
    
    const dayEnd = new Date(date);
    dayEnd.setHours(23, 59, 59, 999);
    
    return events.filter(event => {
      const eventDate = new Date(event.start);
      return eventDate >= dayStart && eventDate <= dayEnd;
    });
  };

  // Check if a date is today
  const isToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  if (loading && events.length === 0) {
    return (
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-6">Calendar</h2>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold flex items-center">
          <CalendarIcon className="mr-2" />
          Calendar
        </h2>
        <button
          onClick={handleAddEvent}
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          <Plus size={20} />
          <span>Add Event</span>
        </button>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center">
          <AlertTriangle size={20} className="mr-2" />
          {error}
          <button 
            className="ml-auto text-sm text-red-700 hover:text-red-900"
            onClick={() => setError(null)}
          >
            Dismiss
          </button>
        </div>
      )}

      {showAuthPrompt && (
        <CalendarAuthPrompt 
          onAuthorize={handleAuthRequest} 
          onClose={() => setShowAuthPrompt(false)} 
        />
      )}

      <div className="bg-white rounded-lg shadow p-4 mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={handleNext}
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <ChevronRight size={20} />
            </button>
            <button
              onClick={handleToday}
              className="px-3 py-1 border rounded-md hover:bg-gray-50 text-sm"
            >
              Today
            </button>
            <h3 className="text-xl font-semibold">
              {currentDate.toLocaleDateString('default', { 
                month: 'long', 
                year: 'numeric',
                day: currentView === 'day' ? 'numeric' : undefined
              })}
              {currentView === 'week' && (
                <span> (Week {Math.ceil(currentDate.getDate() / 7)})</span>
              )}
            </h3>
          </div>
          <div className="flex space-x-1">
            <button
              onClick={() => setCurrentView('month')}
              className={`px-3 py-1 rounded-md text-sm ${
                currentView === 'month'
                  ? 'bg-blue-100 text-blue-800'
                  : 'hover:bg-gray-100'
              }`}
            >
              Month
            </button>
            <button
              onClick={() => setCurrentView('week')}
              className={`px-3 py-1 rounded-md text-sm ${
                currentView === 'week'
                  ? 'bg-blue-100 text-blue-800'
                  : 'hover:bg-gray-100'
              }`}
            >
              Week
            </button>
            <button
              onClick={() => setCurrentView('day')}
              className={`px-3 py-1 rounded-md text-sm ${
                currentView === 'day'
                  ? 'bg-blue-100 text-blue-800'
                  : 'hover:bg-gray-100'
              }`}
            >
              Day
            </button>
          </div>
        </div>
        
        {/* Calendar View */}
        {currentView === 'month' && (
          <div className="grid grid-cols-7 gap-1">
            {/* Day labels */}
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
              <div 
                key={day} 
                className="text-center font-medium text-gray-500 py-2"
              >
                {day}
              </div>
            ))}
            
            {/* Calendar days */}
            {generateMonthView().map((day, index) => {
              const dayEvents = getEventsForDay(day.date);
              const eventLimit = 3; // Max events to show directly
              
              return (
                <div
                  key={index}
                  className={`min-h-28 border p-1 ${
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-400'
                  } ${isToday(day.date) ? 'border-blue-500 border-2' : 'border-gray-200'}`}
                >
                  <div className="text-right p-1">
                    {day.date.getDate()}
                  </div>
                  
                  <div className="space-y-1">
                    {dayEvents.slice(0, eventLimit).map(event => (
                      <div
                        key={event.id}
                        className={`text-xs p-1 rounded truncate cursor-pointer ${
                          event.type === 'meeting' ? 'bg-purple-100 text-purple-800' :
                          event.type === 'birthday' ? 'bg-pink-100 text-pink-800' :
                          event.type === 'todo' ? 'bg-blue-100 text-blue-800' :
                          'bg-orange-100 text-orange-800'
                        }`}
                        onClick={() => handleSelectEvent(event)}
                      >
                        <div className="flex items-center">
                          {getEventIcon(event.type)}
                          <span className="ml-1 truncate">{event.title}</span>
                        </div>
                      </div>
                    ))}
                    
                    {dayEvents.length > eventLimit && (
                      <div 
                        className="text-xs text-gray-500 p-1 cursor-pointer hover:bg-gray-100 rounded text-center"
                        onClick={() => {
                          setCurrentDate(day.date);
                          setCurrentView('day');
                        }}
                      >
                        + {dayEvents.length - eventLimit} more
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        
        {currentView === 'week' && (
          <div className="grid grid-cols-7 gap-1">
            {/* Get the first day of the week (Monday) */}
            {Array.from({ length: 7 }).map((_, index) => {
              const date = new Date(currentDate);
              const dayOfWeek = currentDate.getDay() || 7; // Convert Sunday (0) to 7
              const diff = index + 1 - dayOfWeek; // +1 because we want Monday as first day
              date.setDate(currentDate.getDate() + diff);
              
              const dayEvents = getEventsForDay(date);
              
              return (
                <div key={index} className="min-h-[500px] border border-gray-200">
                  <div className={`text-center p-2 font-medium ${
                    isToday(date) ? 'bg-blue-100 text-blue-800' : 'bg-gray-50'
                  }`}>
                    {date.toLocaleDateString('default', { 
                      weekday: 'short',
                      day: 'numeric',
                    })}
                  </div>
                  
                  <div className="p-2 space-y-2">
                    {dayEvents.map(event => (
                      <div
                        key={event.id}
                        className={`p-2 rounded text-sm cursor-pointer ${
                          event.type === 'meeting' ? 'bg-purple-100 text-purple-800' :
                          event.type === 'birthday' ? 'bg-pink-100 text-pink-800' :
                          event.type === 'todo' ? 'bg-blue-100 text-blue-800' :
                          'bg-orange-100 text-orange-800'
                        }`}
                        onClick={() => handleSelectEvent(event)}
                      >
                        <div className="flex items-center">
                          {getEventIcon(event.type)}
                          <span className="ml-1 font-medium">{event.title}</span>
                        </div>
                        {event.time && (
                          <div className="text-xs flex items-center mt-1">
                            <Clock size={12} className="mr-1" />
                            {new Date(event.start).toLocaleTimeString([], { 
                              hour: '2-digit', 
                              minute: '2-digit'
                            })}
                          </div>
                        )}
                        {event.contactName && (
                          <div className="text-xs mt-1">
                            Contact: {event.contactName}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        
        {currentView === 'day' && (
          <div className="border border-gray-200 min-h-[600px]">
            <div className={`text-center p-3 font-medium ${
              isToday(currentDate) ? 'bg-blue-100 text-blue-800' : 'bg-gray-50'
            }`}>
              {currentDate.toLocaleDateString('default', { 
                weekday: 'long',
                day: 'numeric',
                month: 'long'
              })}
            </div>
            
            <div className="p-4 space-y-3">
              {getEventsForDay(currentDate).map(event => (
                <div
                  key={event.id}
                  className={`p-3 rounded cursor-pointer ${
                    event.type === 'meeting' ? 'bg-purple-100 text-purple-800' :
                    event.type === 'birthday' ? 'bg-pink-100 text-pink-800' :
                    event.type === 'todo' ? 'bg-blue-100 text-blue-800' :
                    'bg-orange-100 text-orange-800'
                  }`}
                  onClick={() => handleSelectEvent(event)}
                >
                  <div className="flex items-center">
                    {getEventIcon(event.type)}
                    <span className="ml-2 font-medium text-lg">{event.title}</span>
                  </div>
                  
                  {event.time && (
                    <div className="flex items-center mt-2">
                      <Clock size={16} className="mr-2" />
                      {new Date(event.start).toLocaleTimeString([], { 
                        hour: '2-digit', 
                        minute: '2-digit'
                      })}
                      {event.end && ` - ${
                        new Date(event.end).toLocaleTimeString([], { 
                          hour: '2-digit', 
                          minute: '2-digit'
                        })
                      }`}
                    </div>
                  )}
                  
                  {event.contactName && (
                    <div className="mt-2">
                      Contact: <span className="font-medium">{event.contactName}</span>
                    </div>
                  )}
                  
                  {event.location && (
                    <div className="mt-2">
                      Location: {event.location}
                    </div>
                  )}
                  
                  {event.meetLink && (
                    <div className="mt-2">
                      <a 
                        href={event.meetLink} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 flex items-center hover:underline"
                      >
                        <Video size={16} className="mr-1" />
                        Join Google Meet
                      </a>
                    </div>
                  )}
                  
                  {event.description && (
                    <div className="mt-2 text-gray-600 border-t pt-2">
                      {event.description}
                    </div>
                  )}
                </div>
              ))}
              
              {getEventsForDay(currentDate).length === 0 && (
                <div className="text-center py-8 text-gray-500">
                  No events scheduled for this day
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Event legend */}
      <div className="bg-white rounded-lg shadow p-4 mb-6">
        <h3 className="text-lg font-medium mb-2">Event Types</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-purple-100 border border-purple-200 rounded"></div>
            <span>Meetings</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-pink-100 border border-pink-200 rounded"></div>
            <span>Birthdays</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-blue-100 border border-blue-200 rounded"></div>
            <span>TODOs</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-orange-100 border border-orange-200 rounded"></div>
            <span>Reminders</span>
          </div>
        </div>
      </div>

      {/* Calendar Event Modal */}
      {isAddModalOpen && (
        <CalendarEventModal 
          isOpen={isAddModalOpen}
          event={selectedEvent}
          onClose={() => setIsAddModalOpen(false)}
          onSave={handleSaveEvent}
          onDelete={selectedEvent?.source === 'google' ? handleDeleteEvent : undefined}
          availableContacts={[]} // Will be fetched inside the component
        />
      )}
    </div>
  );
};

export default Calendar;