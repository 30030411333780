// packages/web/src/types/calendar.ts
// Create a shared type definition file for calendar types

export type EventType = 'meeting' | 'birthday' | 'todo' | 'reminder';

export interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end?: Date;
  type: EventType;
  contactId?: number;
  contactName?: string;
  location?: string;
  description?: string;
  meetLink?: string;
  allDay?: boolean;
  source: 'google' | 'inner-circle';
  time?: string;
}

export interface CalendarEventDTO {
  id: string;
  title: string;
  start: string | Date;
  end?: string | Date;
  type: EventType;
  contactId?: number;
  contactName?: string;
  location?: string;
  description?: string;
  meetLink?: string;
  allDay?: boolean;
  source: 'google' | 'inner-circle';
}

// Converter function to ensure consistent format
export function convertToCalendarEvent(dto: CalendarEventDTO): CalendarEvent {
  return {
    ...dto,
    start: dto.start instanceof Date ? dto.start : new Date(dto.start),
    end: dto.end ? (dto.end instanceof Date ? dto.end : new Date(dto.end)) : undefined
  };
}