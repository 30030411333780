// packages/web/src/components/reminders/ReminderForm.tsx
import React, { useState, useEffect } from 'react';
import { X, Calendar, Clock, Mail, AlertCircle } from 'lucide-react';
import { Contact } from '../../types';

export type ReminderType = 'once' | 'multiple' | 'daily' | 'weekly' | 'monthly' | 'annually';

export interface ReminderData {
  contactId: number;
  type: ReminderType;
  subject: string;
  message: string;
  date: string; // ISO date string
  time?: string; // HH:MM format
  endDate?: string; // For recurring reminders
  occurrences?: number; // For multiple reminders
  interval?: number; // For multiple reminders (days between)
}

interface ReminderFormProps {
  contact: Contact;
  initialData?: ReminderData;
  isEditing?: boolean;
  onSave: (reminderData: ReminderData) => Promise<void>;
  onCancel: () => void;
}

const ReminderForm: React.FC<ReminderFormProps> = ({ 
  contact, 
  initialData, 
  isEditing = false,
  onSave, 
  onCancel 
}) => {
  const [reminderData, setReminderData] = useState<ReminderData>(initialData || {
    contactId: contact.id,
    type: 'once',
    subject: `Reminder to contact ${contact.name}`,
    message: `Hi,\n\nThis is a reminder to get in touch with ${contact.name}.\n\nBest regards,`,
    date: new Date().toISOString().split('T')[0],
    time: '09:00',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showEmailWarning, setShowEmailWarning] = useState(false);

  // Check if contact has email
  useEffect(() => {
    if (!contact.email) {
      setShowEmailWarning(true);
    }
  }, [contact]);

  const handleChange = (field: keyof ReminderData, value: any) => {
    setReminderData(prev => ({ ...prev, [field]: value }));

    // Reset end date if not a recurring reminder
    if (field === 'type' && !['daily', 'weekly', 'monthly', 'annually'].includes(value)) {
      setReminderData(prev => ({ ...prev, endDate: undefined }));
    }

    // Reset occurrences if not multiple
    if (field === 'type' && value !== 'multiple') {
      setReminderData(prev => ({ ...prev, occurrences: undefined, interval: undefined }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!contact.email) {
      setShowEmailWarning(true);
      return;
    }
  
    setError(null);
    setIsSubmitting(true);
  
    try {
      // Get user's timezone
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
      // Include timezone in the reminder data
      const dataToSubmit = {
        ...reminderData,
        timezone: timezone
      };
      
      // Log for debugging
      console.log(`${isEditing ? 'Updating' : 'Submitting'} reminder with timezone:`, timezone);
      console.log('Reminder data:', dataToSubmit);
      
      await onSave(dataToSubmit);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save reminder');
      console.error(`Error ${isEditing ? 'updating' : 'saving'} reminder:`, err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddEmail = () => {
    // Navigate to edit contact or show edit modal
    // For now, just close the warning
    setShowEmailWarning(false);
  };

  if (showEmailWarning) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <div className="flex items-center mb-4 text-amber-600">
            <AlertCircle className="mr-2" />
            <h3 className="text-lg font-semibold">Email Required</h3>
          </div>
          
          <p className="mb-4">
            {contact.name} doesn't have an email address. An email address is required to send reminders.
          </p>
          
          <div className="flex justify-end gap-2">
            <button
              onClick={onCancel}
              className="px-4 py-2 border rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleAddEmail}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Add Email
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {isEditing ? 'Edit Email Reminder' : 'Set Up Email Reminder'}
          </h2>
          <button onClick={onCancel} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-200">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Reminder Type
            </label>
            <select
              value={reminderData.type}
              onChange={(e) => handleChange('type', e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            >
              <option value="once">One time</option>
              <option value="multiple">Multiple times</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="annually">Annually</option>
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                <Calendar className="inline-block mr-1 h-4 w-4" /> Date
              </label>
              <input
                type="date"
                value={reminderData.date}
                onChange={(e) => handleChange('date', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                <Clock className="inline-block mr-1 h-4 w-4" /> Time
              </label>
              <input
                type="time"
                value={reminderData.time}
                onChange={(e) => handleChange('time', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Show for Multiple Times */}
          {reminderData.type === 'multiple' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Number of Occurrences
                </label>
                <input
                  type="number"
                  min="2"
                  max="10"
                  value={reminderData.occurrences || 2}
                  onChange={(e) => handleChange('occurrences', parseInt(e.target.value))}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Days Between Reminders
                </label>
                <input
                  type="number"
                  min="1"
                  max="90"
                  value={reminderData.interval || 7}
                  onChange={(e) => handleChange('interval', parseInt(e.target.value))}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          )}

          {/* Show for recurring reminders */}
          {['daily', 'weekly', 'monthly', 'annually'].includes(reminderData.type) && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date (Optional)
              </label>
              <input
                type="date"
                value={reminderData.endDate || ''}
                onChange={(e) => handleChange('endDate', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                min={reminderData.date}
              />
              <p className="mt-1 text-sm text-gray-500">
                If no end date is set, the reminder will continue indefinitely.
              </p>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Subject
            </label>
            <input
              type="text"
              value={reminderData.subject}
              onChange={(e) => handleChange('subject', e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Message
            </label>
            <textarea
              value={reminderData.message}
              onChange={(e) => handleChange('message', e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              rows={5}
              required
            />
          </div>

          <div className="flex items-center">
            <Mail className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-sm text-gray-600">
              Reminder will be sent to: <strong>{contact.email}</strong>
            </span>
          </div>

          <div className="flex justify-end gap-2 pt-4 border-t">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ${
                isSubmitting ? 'opacity-70 cursor-not-allowed' : ''
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : isEditing ? 'Update Reminder' : 'Save Reminder'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReminderForm;