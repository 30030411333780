import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';

interface GroupInputProps {
  selectedGroups: string[];
  existingGroups: string[];
  onChange: (groups: string[]) => void;
}

const GroupInput: React.FC<GroupInputProps> = ({
  selectedGroups,
  existingGroups,
  onChange
}) => {
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  
  // Debug logging to track values 
  useEffect(() => {
    console.log("GroupInput - selectedGroups:", selectedGroups);
    console.log("GroupInput - existingGroups:", existingGroups);
  }, [selectedGroups, existingGroups]);

  // Get suggestions based on input value - ensure filter works with empty arrays
  // If input is empty, show all available groups, otherwise filter by what user typed
  const suggestions = (existingGroups || []).filter(group => 
    (inputValue.trim() === '' || group.toLowerCase().includes(inputValue.toLowerCase())) &&
    !(selectedGroups || []).includes(group)
  );

  // Handle click outside to close suggestions
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node) &&
          inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAddGroup = (group: string) => {
    if (!selectedGroups.includes(group)) {
      onChange([...selectedGroups, group]);
    }
    setInputValue('');
    setShowSuggestions(false);
  };

  const handleRemoveGroup = (groupToRemove: string) => {
    onChange(selectedGroups.filter(group => group !== groupToRemove));
  };

  // Force show suggestions when input is focused 
  const handleInputFocus = () => {
    // Show all available groups that aren't already selected when focusing the input
    // This allows users to see available groups without typing
    setShowSuggestions(true);
    console.log("Showing all available groups on focus");
    
    // Display all available groups that aren't already selected
    const availableGroups = existingGroups.filter(g => !selectedGroups.includes(g));
    console.log("Available groups:", availableGroups);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      if (suggestions.length > 0) {
        console.log("Adding suggested group:", suggestions[0]);
        handleAddGroup(suggestions[0]);
      } else {
        console.log("Adding new group:", inputValue.trim());
        handleAddGroup(inputValue.trim());
      }
    } else if (e.key === 'Escape') {
      setShowSuggestions(false);
    } else if (e.key === 'ArrowDown' && showSuggestions && suggestions.length > 0) {
      // Could implement keyboard navigation through suggestions
      e.preventDefault();
    }
  };

  return (
    <div className="space-y-2">
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={(e) => {
            const value = e.target.value;
            setInputValue(value);
            // Show suggestions when typing and input is not empty
            const shouldShow = value.trim().length > 0;
            setShowSuggestions(shouldShow);
            
            if (shouldShow) {
              // Log debugging info for suggestions
              console.log("Input changed to:", value);
              console.log("Matching suggestions:", existingGroups.filter(g => 
                g.toLowerCase().includes(value.toLowerCase()) && 
                !selectedGroups.includes(g)));
            }
          }}
          onKeyDown={handleInputKeyDown}
          onFocus={handleInputFocus}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          placeholder="Type to add or select groups..."
        />
        
        {/* Suggestions dropdown - always render container with conditional display */}
        <div 
          ref={suggestionsRef}
          className={`absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto ${
            showSuggestions && suggestions.length > 0 ? 'block' : 'hidden'
          }`}
          style={{minHeight: suggestions.length > 0 ? '40px' : '0'}}
        >
          {suggestions.length > 0 ? (
            suggestions.map(suggestion => (
              <button
                key={suggestion}
                className="w-full px-4 py-2 text-left bg-white hover:bg-blue-50 focus:outline-none focus:bg-blue-50"
                onClick={() => handleAddGroup(suggestion)}
              >
                {suggestion}
              </button>
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500 italic">
              No matching groups
            </div>
          )}
        </div>
      </div>

      {/* Selected groups */}
      <div className="flex flex-wrap gap-2">
        {selectedGroups.map(group => (
          <span
            key={group}
            className="flex items-center gap-1 bg-blue-50 text-blue-700 px-2 py-1 rounded-full text-sm"
          >
            {group}
            <button
              onClick={() => handleRemoveGroup(group)}
              className="hover:text-blue-900"
            >
              <X size={14} />
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default GroupInput;