import React, { useState, useEffect } from 'react';
import { Users, Plus, X, UserPlus } from 'lucide-react';
import { Contact } from '../../types';
import { api } from '../../services/api';
import { analytics } from '../../services/analytics';

interface Group {
  id: number;
  name: string;
  description: string;
}

interface GroupManagerProps {
  contacts: Contact[];
  onUpdateContactGroups: (contactId: number, groups: string[]) => void;
}

const GroupManager: React.FC<GroupManagerProps> = ({ contacts: initialContacts, onUpdateContactGroups }) => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [contacts, setContacts] = useState<Contact[]>(initialContacts);
  const [loading, setLoading] = useState(true);
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [selectedGroupForAddingContacts, setSelectedGroupForAddingContacts] = useState<string | null>(null);
  const [newGroup, setNewGroup] = useState({ name: '', description: '' });

  useEffect(() => {
    Promise.all([
      fetchGroups(),
      fetchContacts()
    ]).then(() => {
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  }, []);

  const fetchGroups = async () => {
    try {
      const data = await api.getGroups();
      setGroups(data);
    } catch (error) {
      console.error('Failed to fetch groups:', error);
    }
  };

  const fetchContacts = async () => {
    try {
      const data = await api.getContacts();
      setContacts(data);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    }
  };

  const handleAddGroup = async () => {
    if (newGroup.name.trim()) {
      try {
        const createdGroup = await api.createGroup(newGroup);
        setGroups([...groups, createdGroup]);
        analytics.trackEvent('create_group', {
          has_description: !!newGroup.description
        });
        setNewGroup({ name: '', description: '' });
        setIsAddingGroup(false);
      } catch (error) {
        console.error('Failed to create group:', error);
      }
    }
  };

  const getContactsInGroup = (groupName: string) => {
    return contacts.filter(contact => contact.groups.includes(groupName));
  };

  const getContactsNotInGroup = (groupName: string) => {
    return contacts.filter(contact => !contact.groups.includes(groupName));
  };

  const handleAddToGroup = async (groupName: string, contactId: number) => {
    const contact = contacts.find(c => c.id === contactId);
    if (contact) {
      const newGroups = [...contact.groups, groupName];
      try {
        // Update in API
        await api.updateContact(contactId, { ...contact, groups: newGroups });
        // Update local state
        setContacts(contacts.map(c => 
          c.id === contactId ? { ...c, groups: newGroups } : c
        ));
        // Callback for parent component
        onUpdateContactGroups(contactId, newGroups);
      } catch (error) {
        console.error('Failed to update contact groups:', error);
      }
    }
  };

  const handleRemoveFromGroup = async (groupName: string, contactId: number) => {
    const contact = contacts.find(c => c.id === contactId);
    if (contact) {
      const newGroups = contact.groups.filter(g => g !== groupName);
      try {
        // Update in API
        await api.updateContact(contactId, { ...contact, groups: newGroups });
        // Update local state
        setContacts(contacts.map(c => 
          c.id === contactId ? { ...c, groups: newGroups } : c
        ));
        // Callback for parent component
        onUpdateContactGroups(contactId, newGroups);
      } catch (error) {
        console.error('Failed to update contact groups:', error);
      }
    }
  };

  const handleDeleteGroup = async (groupId: number) => {
    try {
      await api.deleteGroup(groupId);
      setGroups(groups.filter(g => g.id !== groupId));
    } catch (error) {
      console.error('Failed to delete group:', error);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold flex items-center gap-2">
          <Users />
          Groups
        </h2>
        <button
          onClick={() => setIsAddingGroup(true)}
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          <Plus size={20} />
          New Group
        </button>
      </div>

      {/* Add Group Form */}
      {isAddingGroup && (
        <div className="mb-6 bg-white p-4 rounded-lg border">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Create New Group</h3>
            <button onClick={() => setIsAddingGroup(false)} className="text-gray-500">
              <X size={20} />
            </button>
          </div>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Group Name
              </label>
              <input
                type="text"
                value={newGroup.name}
                onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Enter group name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <input
                type="text"
                value={newGroup.description}
                onChange={(e) => setNewGroup({ ...newGroup, description: e.target.value })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Enter group description"
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setIsAddingGroup(false)}
                className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleAddGroup}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                Create Group
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Groups List */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {groups.length === 0 ? (
          <div className="col-span-2 text-center py-8 bg-white rounded-lg border">
            <p className="text-gray-500">No groups found. Create your first group!</p>
          </div>
        ) : (
          groups.map(group => (
            <div
              key={group.id}
              className="bg-white p-4 rounded-lg border hover:shadow-md transition-shadow"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold">{group.name}</h3>
                  <p className="text-gray-600 text-sm">{group.description}</p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setSelectedGroupForAddingContacts(group.name)}
                    className="flex items-center gap-1 text-blue-500 hover:text-blue-600"
                  >
                    <UserPlus size={20} />
                    <span>Add</span>
                  </button>
                  <button
                    onClick={() => handleDeleteGroup(group.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X size={20} />
                  </button>
                </div>
              </div>

              {/* Add Contacts Modal */}
              {selectedGroupForAddingContacts === group.name && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white rounded-lg p-6 w-full max-w-md">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-semibold">Add Contacts to {group.name}</h3>
                      <button
                        onClick={() => setSelectedGroupForAddingContacts(null)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <X size={24} />
                      </button>
                    </div>
                    <div className="max-h-96 overflow-y-auto">
                      {getContactsNotInGroup(group.name).map(contact => (
                        <div
                          key={contact.id}
                          className="flex items-center justify-between p-2 hover:bg-gray-50 rounded"
                        >
                          <span>{contact.name}</span>
                          <button
                            onClick={() => handleAddToGroup(group.name, contact.id)}
                            className="text-blue-500 hover:text-blue-600"
                          >
                            <Plus size={20} />
                          </button>
                        </div>
                      ))}
                      {getContactsNotInGroup(group.name).length === 0 && (
                        <p className="text-gray-500 text-center py-4">
                          No contacts available to add
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Contacts in group:</h4>
                <div className="space-y-2">
                  {getContactsInGroup(group.name).map(contact => (
                    <div
                      key={contact.id}
                      className="flex justify-between items-center bg-gray-50 p-2 rounded"
                    >
                      <span>{contact.name}</span>
                      <button
                        onClick={() => handleRemoveFromGroup(group.name, contact.id)}
                        className="text-red-600 hover:text-red-800"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                  {getContactsInGroup(group.name).length === 0 && (
                    <p className="text-gray-500 text-sm">No contacts in this group</p>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default GroupManager;