import React, { useState, useEffect } from 'react';
import { 
  Calendar, Phone, Mail, Users, ThermometerSun, ThermometerSnowflake,
  Plus, Check, Video, MessageSquare, Trash2, ArrowLeft, 
  Linkedin, Facebook, MessageCircle, Twitter, Edit
} from 'lucide-react';
import { Contact, Interaction, Todo } from '../../types';
import { api } from '../../services/api';
import { getTimeAgo, daysSinceLastInteraction, shouldBeCold } from '../../utils/contactUtils';
import SocialLinks from './SocialLinks';
import RemindersList from '../reminders/RemindersList';
import EditContactModal from './EditContactModal';

interface ContactDetailsProps {
  contact: Contact;
  onUpdate: (updatedContact: Contact) => void;
  onClose: () => void;
  onDelete: (contactId: number) => void;
  existingGroups?: string[];
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  contact,
  onUpdate,
  onClose,
  onDelete,
  existingGroups = []
}) => {
  const [newInteraction, setNewInteraction] = useState<{
    type: Interaction['type'];
    notes: string;
    date: string;
  }>({
    type: 'meeting',
    notes: '',
    date: new Date().toISOString().split('T')[0],
  });

  const [newTodo, setNewTodo] = useState({
    task: '',
    dueDate: '',
  });

  const [isDeleting, setIsDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  const [userSettings, setUserSettings] = useState({
    coldThresholdDays: 30 // default value
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await api.getUserSettings();
        setUserSettings(settings);
      } catch (error) {
        console.error('Failed to fetch user settings:', error);
      }
    };
    
    fetchSettings();
  }, []);

  const handleDeleteContact = async (contactId: number) => {
    if (!window.confirm('Are you sure you want to delete this contact?')) {
      return;
    }
    
    try {
      setIsDeleting(true);
      console.log('Attempting to delete contact:', contactId);
      
      // Direct fetch call to avoid any potential issues in the API service
      const response = await fetch(`/api/contacts/${contactId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      // Check for errors and handle them appropriately
      if (!response.ok) {
        const errorText = await response.text().catch(() => 'Unknown error');
        console.error('Delete error response:', response.status, errorText);
        
        if (response.status === 500) {
          // Special handling for server errors
          alert('Server error when deleting contact. This contact may have related data that prevents deletion.');
          return;
        }
        
        throw new Error(`Failed to delete contact: ${response.status}`);
      }
      
      console.log('Contact deleted successfully');
      
      // Call the parent's onDelete function to update UI state
      onDelete(contactId);
      // Close the modal
      onClose();
    } catch (error) {
      console.error('Delete error:', error);
      alert('Failed to delete contact. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  const deleteInteraction = async (interactionId: number) => {
    try {
      if (!window.confirm('Are you sure you want to delete this interaction?')) {
        return;
      }
      
      console.log('Deleting interaction:', interactionId);
      await api.deleteInteraction(interactionId);
      
      // Update the local state to remove the deleted interaction
      const updatedInteractions = contact.interactions.filter(
        interaction => interaction.id !== interactionId
      );
      
      const updatedContact = {
        ...contact,
        interactions: updatedInteractions
      };
      
      // Update parent component's state
      onUpdate(updatedContact);
    } catch (error) {
      console.error('Failed to delete interaction:', error);
      alert('Failed to delete interaction. Please try again.');
    }
  };

  const addInteraction = async () => {
    try {
      setErrorMessage(null);
      const interactionData = {
        type: newInteraction.type,
        date: newInteraction.date,
        notes: newInteraction.notes
      };

      // Use the new API endpoint to create the interaction
      const createdInteraction = await api.createInteraction(contact.id, interactionData);
      
      // Check if this interaction is the newest
      const isNewerInteraction = new Date(createdInteraction.date) > new Date(contact.lastInteraction);
      
      // Update the local state with the new interaction from the server
      const updatedContact = {
        ...contact,
        interactions: [createdInteraction, ...contact.interactions],
        // Update lastInteraction if this is more recent
        lastInteraction: isNewerInteraction ? createdInteraction.date : contact.lastInteraction,
        // If the contact was cold, make it warm again after a new interaction
        warmth: contact.warmth === 'cold' ? 'warm' : contact.warmth
      };
      
      onUpdate(updatedContact);
      
      setNewInteraction({ 
        type: 'meeting', 
        notes: '', 
        date: new Date().toISOString().split('T')[0] 
      });
      
      // Show a success message if the contact was changed from cold to warm
      if (contact.warmth === 'cold') {
        setErrorMessage({ 
          type: 'success', 
          text: 'Contact has been marked as warm again due to the new interaction!' 
        });
      }
    } catch (error) {
      console.error('Failed to add interaction:', error);
      setErrorMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to add interaction. Please try again.' 
      });
    }
  };

  const addTodo = async () => {
    if (!newTodo.task.trim()) return;

    try {
      setErrorMessage(null);
      const todoData = {
        task: newTodo.task,
        dueDate: newTodo.dueDate || undefined,
        completed: false
      };

      const createdTodo = await api.createTodo(contact.id, todoData);
      
      const updatedContact = {
        ...contact,
        todos: [...contact.todos, createdTodo]
      };
      
      onUpdate(updatedContact);
      setNewTodo({ task: '', dueDate: '' });
    } catch (error) {
      console.error('Failed to add todo:', error);
      setErrorMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to add todo. Please try again.' 
      });
    }
  };

  const toggleTodo = async (todoId: string) => {
    const todo = contact.todos.find(t => t.id === todoId);
    if (!todo) return;

    try {
      setErrorMessage(null);
      const updatedTodo = await api.updateTodo(todoId, {
        ...todo,
        completed: !todo.completed
      });
      
      const updatedTodos = contact.todos.map(t => 
        t.id === todoId ? updatedTodo : t
      );
      
      const updatedContact = {
        ...contact,
        todos: updatedTodos
      };
      
      onUpdate(updatedContact);
    } catch (error) {
      console.error('Failed to update todo status:', error);
      setErrorMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to update todo. Please try again.' 
      });
    }
  };

  const deleteTodo = async (todoId: string) => {
    try {
      setErrorMessage(null);
      await api.deleteTodo(todoId);
      
      const updatedTodos = contact.todos.filter(todo => todo.id !== todoId);
      const updatedContact = {
        ...contact,
        todos: updatedTodos
      };
      
      onUpdate(updatedContact);
    } catch (error) {
      console.error('Failed to delete todo:', error);
      setErrorMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to delete todo. Please try again.' 
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-white overflow-y-auto z-50">
      {/* Top navigation bar with back button */}
      <div className="sticky top-0 left-0 right-0 bg-white shadow-sm z-10 px-4 py-2">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center justify-between">
            <button
              onClick={onClose}
              className="flex items-center gap-2 text-blue-600 hover:text-blue-800 py-2"
              aria-label="Back to contacts"
            >
              <ArrowLeft size={20} />
              <span>Back to Contacts</span>
            </button>
            
            <div className="flex gap-2">
              <button
                onClick={() => setIsEditModalOpen(true)}
                className="flex items-center gap-1 px-3 py-2 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50"
              >
                <Edit size={16} />
                Edit Contact
              </button>
              <button
                onClick={() => handleDeleteContact(contact.id)}
                disabled={isDeleting}
                className={`flex items-center gap-1 px-3 py-2 text-red-600 border border-red-600 rounded-lg hover:bg-red-50 ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                <Trash2 size={16} />
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main content with proper spacing */}
      <div className="max-w-4xl mx-auto p-4 pt-6">
        {/* Error message display */}
        {errorMessage && (
          <div className={`mb-4 px-4 py-3 rounded ${
            errorMessage.type === 'success' 
              ? 'bg-green-50 border border-green-200 text-green-700' 
              : 'bg-red-50 border border-red-200 text-red-700'
          }`}>
            <p>{errorMessage.text}</p>
          </div>
        )}
        
        {/* Header */}
        <div className="mb-6">
          <h2 className="text-2xl font-bold">{contact.name}</h2>
        </div>

        {/* Basic Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="space-y-4">
            <div className="flex items-center gap-2 text-gray-600">
              <Mail size={20} />
              <span>{contact.email || 'No email'}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Phone size={20} />
              <span>{contact.phone || 'No phone'}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Calendar size={20} />
              <span>{contact.birthday || 'No birthday set'}</span>
            </div>
            <div className="flex items-center gap-2">
              {contact.warmth === 'warm' ? (
                <ThermometerSun className="text-orange-500" size={20} />
              ) : (
                <ThermometerSnowflake className="text-blue-500" size={20} />
              )}
              <span className="capitalize">{contact.warmth}</span>
            </div>
            
            {/* Social Media Links - ADD THIS SECTION */}
            {(contact.linkedin || contact.facebook || contact.telegram || contact.xcom) && (
              <div className="mt-4">
                <SocialLinks
                  linkedin={contact.linkedin}
                  facebook={contact.facebook}
                  telegram={contact.telegram}
                  xcom={contact.xcom}
                  onUpdate={(updates) => {
                    const updatedContact = {
                      ...contact,
                      ...updates
                    };
                    onUpdate(updatedContact);
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <div className="flex items-center gap-2 mb-2">
              <Users size={20} />
              <span className="font-medium">Groups</span>
            </div>
            <div className="flex flex-wrap gap-2">
              {contact.groups && contact.groups.length > 0 ? (
                contact.groups.map(group => (
                  <span
                    key={group}
                    className="bg-gray-100 px-2 py-1 rounded-full text-sm"
                  >
                    {group}
                  </span>
                ))
              ) : (
                <span className="text-gray-500 text-sm">No groups</span>
              )}
            </div>
          </div>
        </div>

        {/* Contact Status Section */}
        <div className="p-4 bg-gray-50 rounded-lg mb-8">
          <h3 className="text-lg font-semibold mb-2">Contact Status</h3>
          
          <div className="flex flex-col gap-2">
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-6">
              <div>
                <span className="text-gray-600">Last interaction: </span>
                <span className="font-medium">{getTimeAgo(contact.lastInteraction)}</span>
              </div>
              
              <div>
                <span className="text-gray-600">Status: </span>
                <span className={`font-medium ${contact.warmth === 'warm' ? 'text-orange-500' : 'text-blue-500'}`}>
                  {contact.warmth === 'warm' ? 'Warm' : 'Cold'}
                </span>
              </div>
            </div>
            
            {contact.warmth === 'warm' && (
              <div className="mt-2">
                {(() => {
                  const daysSince = daysSinceLastInteraction(contact.lastInteraction);
                  const daysRemaining = userSettings.coldThresholdDays - daysSince;
                  
                  if (daysRemaining <= 0) {
                    return (
                      <div className="p-2 bg-red-50 border border-red-200 rounded-lg text-red-700">
                        <p>This contact will be marked as <strong>cold</strong> soon based on your settings.</p>
                        <p className="text-sm mt-1">
                          Your setting: Mark contacts as cold after {userSettings.coldThresholdDays} days of no interaction.
                        </p>
                      </div>
                    );
                  } else if (daysRemaining <= 7) {
                    return (
                      <div className="p-2 bg-yellow-50 border border-yellow-200 rounded-lg text-yellow-700">
                        <p>This contact will be marked as <strong>cold</strong> in {daysRemaining} days if no new interactions occur.</p>
                        <p className="text-sm mt-1">
                          Your setting: Mark contacts as cold after {userSettings.coldThresholdDays} days of no interaction.
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div className="text-sm text-gray-500">
                        Your setting: Mark contacts as cold after {userSettings.coldThresholdDays} days of no interaction.
                        <div className="mt-1">
                          {daysSince} days since last interaction, {daysRemaining} days remaining until cold.
                        </div>
                      </div>
                    );
                  }
                })()}
              </div>
            )}
            
            {contact.warmth === 'cold' && (
              <div className="mt-2 text-sm text-gray-500">
                <p>This contact was automatically marked as cold because there hasn't been an interaction in at least {userSettings.coldThresholdDays} days.</p>
                <p className="mt-1">Add a new interaction to make this contact warm again.</p>
              </div>
            )}
          </div>
        </div>

        {/* Reminders section */}
        <RemindersList contact={contact} onUpdateContact={onUpdate} />

        {/* Interactions */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4">Interactions</h3>
          <div className="flex gap-2 mb-4">
            <select
              value={newInteraction.type}
              onChange={(e) => {
                const value = e.target.value as Interaction['type'];
                setNewInteraction({
                  ...newInteraction,
                  type: value
                });
              }}
              className="px-3 py-2 border rounded-lg"
            >
              <option value="meeting">Meeting</option>
              <option value="call">Call</option>
              <option value="email">Email</option>
              <option value="other">Other</option>
            </select>
            <input
              type="date"
              value={newInteraction.date}
              onChange={(e) => setNewInteraction({
                ...newInteraction,
                date: e.target.value
              })}
              className="px-3 py-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Notes"
              value={newInteraction.notes}
              onChange={(e) => setNewInteraction({
                ...newInteraction,
                notes: e.target.value
              })}
              className="flex-1 px-3 py-2 border rounded-lg"
            />
            <button
              onClick={addInteraction}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              <Plus size={20} />
            </button>
          </div>
          <div className="space-y-2">
            {contact.interactions && contact.interactions.length > 0 ? (
              contact.interactions.map(interaction => (
                <div
                  key={interaction.id}
                  className="flex items-start gap-3 p-3 bg-gray-50 rounded-lg"
                >
                  {interaction.type === 'meeting' && <Video size={20} className="text-blue-500" />}
                  {interaction.type === 'call' && <Phone size={20} className="text-green-500" />}
                  {interaction.type === 'email' && <Mail size={20} className="text-orange-500" />}
                  {interaction.type === 'other' && <MessageSquare size={20} className="text-purple-500" />}
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium capitalize">{interaction.type}</span>
                      <span className="text-sm text-gray-500">{interaction.date}</span>
                    </div>
                    <p className="text-gray-600">{interaction.notes}</p>
                  </div>
                  
                  {/* Add this delete button */}
                  <button
                    onClick={() => deleteInteraction(interaction.id as number)}
                    className="p-1 text-red-500 hover:bg-red-50 rounded-full"
                    title="Delete interaction"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              ))
            ) : (
              <p className="text-gray-500 text-center py-4">No interactions recorded</p>
            )}
          </div>
        </div>

        {/* Todos */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Todo List</h3>
          <div className="flex gap-2 mb-4">
            <input
              type="text"
              placeholder="New todo"
              value={newTodo.task}
              onChange={(e) => setNewTodo({ ...newTodo, task: e.target.value })}
              className="flex-1 px-3 py-2 border rounded-lg"
            />
            <input
              type="date"
              value={newTodo.dueDate}
              onChange={(e) => setNewTodo({ ...newTodo, dueDate: e.target.value })}
              className="px-3 py-2 border rounded-lg"
            />
            <button
              onClick={addTodo}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              <Plus size={20} />
            </button>
          </div>
          <div className="space-y-2">
            {contact.todos && contact.todos.length > 0 ? (
              contact.todos.map(todo => (
                <div
                  key={todo.id}
                  className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg"
                >
                  <button
                    onClick={() => toggleTodo(todo.id)}
                    className={`p-1 rounded-full ${
                      todo.completed ? 'bg-green-500' : 'border-2 border-gray-300'
                    }`}
                  >
                    {todo.completed && <Check size={16} className="text-white" />}
                  </button>
                  <div className="flex-1">
                    <span className={todo.completed ? 'line-through text-gray-500' : ''}>
                      {todo.task}
                    </span>
                    {todo.dueDate && (
                      <span className="ml-2 text-sm text-gray-500">
                        Due: {todo.dueDate}
                      </span>
                    )}
                  </div>
                  <button
                    onClick={() => deleteTodo(todo.id)}
                    className="p-1 text-red-500 hover:bg-red-50 rounded-full"
                    title="Delete todo"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              ))
            ) : (
              <p className="text-gray-500 text-center py-4">No todos</p>
            )}
          </div>
        </div>
      </div>

      {/* Edit Contact Modal */}
      {isEditModalOpen && (
        <EditContactModal
          contact={contact}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onUpdate={onUpdate}
          existingGroups={existingGroups}
        />
      )}
    </div>
  );
};

export default ContactDetails;