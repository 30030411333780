// src/components/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md my-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy for Inner Circle</h1>
      <p className="text-gray-600 mb-6">Last Updated: March 14, 2025</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-4">
          Welcome to Inner Circle. This Privacy Policy explains how we collect, use, and protect your personal 
          information when you use our application.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
        
        <h3 className="text-xl font-medium mb-2">2.1 Information You Provide</h3>
        <ul className="list-disc pl-8 mb-4">
          <li>Account information (name, email address)</li>
          <li>Contact information you input into the app</li>
          <li>Group information you create</li>
          <li>Interactions and todos you record</li>
          <li>Email content for reminders</li>
        </ul>

        <h3 className="text-xl font-medium mb-2">2.2 Information Collected Automatically</h3>
        <ul className="list-disc pl-8 mb-4">
          <li>Usage data and analytics</li>
          <li>Error logs and performance data</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
        <p className="mb-4">We use your information to:</p>
        <ul className="list-disc pl-8 mb-4">
          <li>Provide and maintain the Inner Circle service</li>
          <li>Send email reminders on your behalf (only with your explicit permission)</li>
          <li>Improve and personalize your experience</li>
          <li>Respond to your requests and communications</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Data Sharing and Disclosure</h2>
        <p className="mb-4">
          We do not sell your personal information. We may share information in the following circumstances:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>With your consent</li>
          <li>To comply with legal obligations</li>
          <li>To protect rights, privacy, safety or property</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Gmail API Usage</h2>
        <p className="mb-4">
          Our application uses Google's Gmail API to send email reminders on your behalf. We:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Only send emails that you explicitly authorize</li>
          <li>Never read your emails</li>
          <li>Only access the permissions you grant</li>
          <li>Delete your Gmail access tokens upon request</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Data Security</h2>
        <p className="mb-4">
          We implement appropriate security measures to protect your personal information against 
          unauthorized access or alteration.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Your Rights</h2>
        <p className="mb-4">You have the right to:</p>
        <ul className="list-disc pl-8 mb-4">
          <li>Access your personal information</li>
          <li>Correct inaccurate information</li>
          <li>Delete your data</li>
          <li>Withdraw consent for email functionality</li>
          <li>Export your data</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Children's Privacy</h2>
        <p className="mb-4">
          Our service is not directed to children under 13. We do not knowingly collect information from children under 13.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting 
          the new Privacy Policy on this page.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us at{' '}
          <a href="mailto:evs.abyss@gmail.com" className="text-blue-600 hover:underline">
            evs.abyss@gmail.com
          </a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;