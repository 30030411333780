// packages/web/src/components/Pricing.tsx
// This component is accessible via /pricing path
import React from 'react';
import { Check, X, ArrowRight, Home } from 'lucide-react';
import { analytics } from '../services/analytics';

interface PricingFeatureProps {
  available?: boolean;
  children: React.ReactNode;
}

const PricingFeature: React.FC<PricingFeatureProps> = ({ 
  available = false, 
  children 
}) => {
  return (
    <li className="flex items-start gap-2">
      {available ? (
        <Check size={18} className="text-green-500 mt-0.5 flex-shrink-0" />
      ) : (
        <X size={18} className="text-gray-400 mt-0.5 flex-shrink-0" />
      )}
      <span className={available ? "text-gray-700" : "text-gray-400"}>
        {children}
      </span>
    </li>
  );
};

const Pricing: React.FC = () => {
  const handleSubscribe = (tier: string) => {
    // Track the subscription click in Google Analytics
    analytics.trackEvent('pricing_button_click', {
      tier: tier,
      action: 'subscribe'
    });
    
    // Redirect to login page
    window.location.href = '/';
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white overflow-x-hidden">
      {/* Navbar with home button and highlighted pricing */}
      <nav className="w-full py-4 px-6 flex justify-between items-center bg-white/80 backdrop-blur-md fixed top-0 z-10 shadow-sm">
        <div className="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="w-8 h-8">
            <circle cx="100" cy="100" r="90" fill="none" stroke="#3B82F6" strokeWidth="8" />
            <circle cx="100" cy="100" r="60" fill="none" stroke="#3B82F6" strokeWidth="8" />
            <circle cx="100" cy="80" r="20" fill="#3B82F6" />
            <path d="M60,150 C60,120 140,120 140,150" fill="#3B82F6" stroke="#3B82F6" strokeWidth="2" />
          </svg>
          <span className="text-xl font-bold text-gray-800">Inner Circle</span>
        </div>
        <div className="flex items-center gap-4">
          {/* Highlighted pricing button */}
          <span className="text-blue-600 font-medium border-b-2 border-blue-600 pb-1">
            Pricing
          </span>
          {/* Button to go back to main page */}
          <a 
            href="/" 
            className="flex items-center gap-1 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg shadow-md transition-all hover:scale-105 font-medium"
            onClick={() => {
              analytics.trackEvent('navigation', {
                from: 'pricing',
                to: 'home',
                method: 'navbar_button'
              });
            }}
          >
            <Home size={18} />
            <span>Home</span>
          </a>
        </div>
      </nav>

      {/* Pricing Cards */}
      <section className="pt-40 pb-24 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-3 gap-8">
            {/* Free Tier */}
            <div className="bg-white rounded-2xl shadow-md border border-gray-100 overflow-hidden hover:shadow-lg transition-shadow">
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-800 mb-4">Free Tier</h3>
                <p className="text-gray-600 mb-6">Perfect for trying out Inner Circle</p>
                <div className="mb-6">
                  <span className="text-4xl font-bold text-gray-800">$0</span>
                  <span className="text-gray-500">/month</span>
                </div>
                <a 
                  href="/"
                  className="block w-full bg-gray-800 hover:bg-gray-700 text-white py-2 px-4 rounded-lg text-center font-medium"
                >
                  Get Started
                </a>
              </div>
              
              <div className="border-t border-gray-100 p-8">
                <h4 className="font-medium text-gray-800 mb-4">What's included:</h4>
                <ul className="space-y-3">
                  <PricingFeature available>
                    Limited Contacts: 5 contacts maximum
                  </PricingFeature>
                  <PricingFeature available>
                    Basic Features: Contact management, basic groups, simple todos
                  </PricingFeature>
                  <PricingFeature available>
                    Limited AI: 10 queries per month
                  </PricingFeature>
                  <PricingFeature available>
                    Maximum 10 todos
                  </PricingFeature>
                  <PricingFeature available>
                    Maximum 3 groups
                  </PricingFeature>
                  <PricingFeature>
                    No integrations with other services
                  </PricingFeature>
                </ul>
              </div>
            </div>

            {/* Medium Tier */}
            <div className="bg-white rounded-2xl shadow-lg border-2 border-blue-500 overflow-hidden transform md:scale-105 relative">
              <div className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 rounded-bl-lg text-sm font-medium">
                POPULAR
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-800 mb-4">Medium Tier</h3>
                <p className="text-gray-600 mb-6">For individuals who value their connections</p>
                <div className="mb-6">
                  <span className="text-4xl font-bold text-gray-800">$4.99</span>
                  <span className="text-gray-500">/month</span>
                  <p className="text-sm text-gray-500 mt-1">or $49/year (save 18%)</p>
                </div>
                <a 
                  href="/"
                  className="block w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg text-center font-medium"
                >
                  Subscribe Now
                </a>
              </div>
              
              <div className="border-t border-gray-100 p-8">
                <h4 className="font-medium text-gray-800 mb-4">Everything in Free, plus:</h4>
                <ul className="space-y-3">
                  <PricingFeature available>
                    Expanded Contacts: Up to 100 contacts
                  </PricingFeature>
                  <PricingFeature available>
                    Unlimited groups and todos
                  </PricingFeature>
                  <PricingFeature available>
                    Email, calendar and messengers integrations
                  </PricingFeature>
                  <PricingFeature available>
                    Enhanced AI: Up to 100 AI assistant interactions per month
                  </PricingFeature>
                </ul>
              </div>
            </div>

            {/* Premium Tier */}
            <div className="bg-white rounded-2xl shadow-md border border-gray-100 overflow-hidden hover:shadow-lg transition-shadow">
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-800 mb-4">Premium Tier</h3>
                <p className="text-gray-600 mb-6">For professionals and relationship experts</p>
                <div className="mb-6">
                  <span className="text-4xl font-bold text-gray-800">$12.99</span>
                  <span className="text-gray-500">/month</span>
                  <p className="text-sm text-gray-500 mt-1">or $120/year (save 23%)</p>
                </div>
                <a 
                  href="/"
                  className="block w-full bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white py-2 px-4 rounded-lg text-center font-medium"
                >
                  Go Premium
                </a>
              </div>
              
              <div className="border-t border-gray-100 p-8">
                <h4 className="font-medium text-gray-800 mb-4">Everything in Medium, plus:</h4>
                <ul className="space-y-3">
                  <PricingFeature available>
                    Unlimited Contacts
                  </PricingFeature>
                  <PricingFeature available>
                    Priority support
                  </PricingFeature>
                  <PricingFeature available>
                    Advanced analytics
                  </PricingFeature>
                  <PricingFeature available>
                    Personal contact notification settings
                  </PricingFeature>
                  <PricingFeature available>
                    Full AI Access: Unlimited AI assistant interactions
                  </PricingFeature>
                  <PricingFeature available>
                    Early access to new demo features and integrations
                  </PricingFeature>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-blue-600 to-blue-700 text-white text-center">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">Start managing your relationships today</h2>
          <p className="text-xl mb-10 text-blue-100">No credit card required to try our free plan.</p>
          <a
            href="/"
            className="inline-flex items-center bg-white text-blue-600 hover:bg-blue-50 px-8 py-3 rounded-lg text-lg font-medium shadow-lg"
          >
            Get Started for Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </a>
        </div>
      </section>

      {/* Simple footer with pricing and privacy policy links */}
      <footer className="py-10 bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-6 md:mb-0">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="w-8 h-8 mr-3">
                  <circle cx="100" cy="100" r="90" fill="none" stroke="#ffffff" strokeWidth="8" />
                  <circle cx="100" cy="100" r="60" fill="none" stroke="#ffffff" strokeWidth="8" />
                  <circle cx="100" cy="80" r="20" fill="#ffffff" />
                  <path d="M60,150 C60,120 140,120 140,150" fill="#ffffff" stroke="#ffffff" strokeWidth="2" />
                </svg>
                <span className="text-xl font-semibold">Inner Circle</span>
              </div>
              <p className="mt-2 text-gray-400">© 2025 Inner Circle. All rights reserved.</p>
            </div>
            <div className="flex gap-6">
              <a href="/pricing" className="text-gray-400 hover:text-white transition-colors">
                Pricing
              </a>
              <a href="/privacy-policy" className="text-gray-400 hover:text-white transition-colors">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Pricing;