// packages/web/src/components/calendar/CalendarEventModal.tsx
import React, { useState, useEffect } from 'react';
import { X, Video, Trash2, AlertTriangle } from 'lucide-react';
import { api } from '../../services/api';
import { Contact } from '../../types';
import { CalendarEvent } from '../../types/calendar';

interface CalendarEventModalProps {
  isOpen: boolean;
  event: CalendarEvent | null;
  onClose: () => void;
  onSave: (eventData: CalendarEvent) => Promise<void>;
  onDelete?: (eventId: string) => Promise<void>;
  availableContacts?: Contact[];
}

const CalendarEventModal: React.FC<CalendarEventModalProps> = ({
  isOpen,
  event,
  onClose,
  onSave,
  onDelete,
  availableContacts = [],
}) => {
  const [eventData, setEventData] = useState<CalendarEvent>({
    id: '',
    title: '',
    start: new Date(),
    // Always initialize with end date 30 minutes after start
    end: new Date(new Date().getTime() + 30 * 60 * 1000),
    type: 'meeting',
    description: '',
    allDay: false,
    source: 'google'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [contacts, setContacts] = useState<Contact[]>(availableContacts);
  const [isCreateInteraction, setIsCreateInteraction] = useState(true);
  const [generateMeetLink, setGenerateMeetLink] = useState(false);

  useEffect(() => {
    if (event) {
      setEventData({
        ...event,
        // Ensure dates are Date objects
        start: event.start instanceof Date ? event.start : new Date(event.start),
        end: event.end ? (event.end instanceof Date ? event.end : new Date(event.end)) : undefined,
      });
    }
    
    if (availableContacts.length === 0) {
      fetchContacts();
    }
  }, [event, availableContacts]);

  const fetchContacts = async () => {
    try {
      const contactsData = await api.getContacts();
      setContacts(contactsData);
    } catch (err) {
      console.error('Failed to fetch contacts:', err);
      setError('Failed to load contacts');
    }
  };

  const handleChange = (field: keyof CalendarEvent, value: any) => {
    setEventData(prev => ({ ...prev, [field]: value }));
  };

  const handleContactChange = (contactId: number) => {
    if (!contactId) {
      setEventData(prev => ({ 
        ...prev, 
        contactId: undefined,
        contactName: undefined 
      }));
      return;
    }
    
    const selectedContact = contacts.find(c => c.id === contactId);
    if (selectedContact) {
      setEventData(prev => ({ 
        ...prev, 
        contactId: selectedContact.id,
        contactName: selectedContact.name 
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    
    try {
      // Make sure event data includes both start and end dates
      const formattedEvent = {
        ...eventData,
        // Ensure end date is always sent, default to 30 minutes after start if missing
        end: eventData.end || new Date(new Date(eventData.start).getTime() + 30 * 60 * 1000),
        createInteraction: isCreateInteraction,
        generateMeetLink,
      };
      
      console.log('Submitting event data:', formattedEvent);
      
      await onSave(formattedEvent);
    } catch (err) {
      console.error('Error saving event:', err);
      setError(err instanceof Error ? err.message : 'Failed to save event');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStartDateChange = (newStart: Date) => {
    // Update start date
    setEventData(prev => {
      // Calculate how long the event was (if end date exists)
      let duration = 30 * 60 * 1000; // Default 30 minutes
      if (prev.end) {
        const prevStart = prev.start instanceof Date ? prev.start : new Date(prev.start);
        const prevEnd = prev.end instanceof Date ? prev.end : new Date(prev.end);
        duration = prevEnd.getTime() - prevStart.getTime();
      }
      
      // Create new end date with same duration from new start
      const newEnd = new Date(newStart.getTime() + duration);
      
      // Return updated event data with new start and end
      return {
        ...prev,
        start: newStart,
        end: newEnd
      };
    });
  };

  const handleDelete = async () => {
    if (!event?.id || !onDelete) return;
    
    try {
      setIsSubmitting(true);
      await onDelete(event.id);
    } catch (err) {
      console.error('Error deleting event:', err);
      setError(err instanceof Error ? err.message : 'Failed to delete event');
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {event ? 'Edit Event' : 'Add New Event'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center">
            <AlertTriangle size={20} className="mr-2" />
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Event Title
            </label>
            <input
              type="text"
              value={eventData.title}
              onChange={(e) => handleChange('title', e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              placeholder="Meeting with..."
              required
              disabled={event?.source === 'inner-circle'}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={eventData.start.toISOString().split('T')[0]}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  const currentStart = new Date(eventData.start);
                  
                  // Keep the current time, just update the date
                  date.setHours(currentStart.getHours(), currentStart.getMinutes());
                  
                  // Use the synchronization function to update both start and end
                  handleStartDateChange(date);
                }}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                required
                disabled={event?.source === 'inner-circle'}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Time
              </label>
              <input
                type="time"
                value={new Date(eventData.start).toTimeString().substring(0, 5)}
                onChange={(e) => {
                  const [hours, minutes] = e.target.value.split(':').map(Number);
                  const newStart = new Date(eventData.start);
                  newStart.setHours(hours, minutes);
                  
                  // Use the synchronization function to update both start and end
                  handleStartDateChange(newStart);
                }}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                disabled={eventData.allDay || event?.source === 'inner-circle'}
              />
            </div>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="all-day"
              checked={eventData.allDay || false}
              onChange={(e) => handleChange('allDay', e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              disabled={event?.source === 'inner-circle'}
            />
            <label htmlFor="all-day" className="ml-2 block text-sm text-gray-700">
              All-day event
            </label>
          </div>

          {!eventData.allDay && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <input
                  type="date"
                  value={eventData.end ? new Date(eventData.end).toISOString().split('T')[0] : ''}
                  onChange={(e) => {
                    if (!eventData.end) {
                      const newEnd = new Date(eventData.start);
                      const date = new Date(e.target.value);
                      newEnd.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                      setEventData(prev => ({ ...prev, end: newEnd }));
                      return;
                    }
                    
                    const date = new Date(e.target.value);
                    const newEnd = new Date(eventData.end);
                    newEnd.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                    setEventData(prev => ({ ...prev, end: newEnd }));
                  }}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  disabled={event?.source === 'inner-circle'}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Time
                </label>
                <input
                  type="time"
                  value={eventData.end ? new Date(eventData.end).toTimeString().substring(0, 5) : ''}
                  onChange={(e) => {
                    if (!eventData.end) {
                      const newEnd = new Date(eventData.start);
                      const [hours, minutes] = e.target.value.split(':').map(Number);
                      newEnd.setHours(hours, minutes);
                      setEventData(prev => ({ ...prev, end: newEnd }));
                      return;
                    }
                    
                    const [hours, minutes] = e.target.value.split(':').map(Number);
                    const newEnd = new Date(eventData.end);
                    newEnd.setHours(hours, minutes);
                    setEventData(prev => ({ ...prev, end: newEnd }));
                  }}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  disabled={eventData.allDay || event?.source === 'inner-circle'}
                />
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Contact
            </label>
            <select
              value={eventData.contactId || ''}
              onChange={(e) => handleContactChange(parseInt(e.target.value))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              disabled={event?.source === 'inner-circle'}
            >
              <option value="">-- No Contact --</option>
              {contacts.map(contact => (
                <option key={contact.id} value={contact.id}>
                  {contact.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <input
              type="text"
              value={eventData.location || ''}
              onChange={(e) => handleChange('location', e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              placeholder="Location (optional)"
              disabled={event?.source === 'inner-circle'}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={eventData.description || ''}
              onChange={(e) => handleChange('description', e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              rows={3}
              placeholder="Event description (optional)"
              disabled={event?.source === 'inner-circle'}
            />
          </div>

          {/* Only show these options for Google Calendar events with a contact */}
          {(event === null || event.source === 'google') && eventData.contactId && (
            <>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="create-interaction"
                  checked={isCreateInteraction}
                  onChange={(e) => setIsCreateInteraction(e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="create-interaction" className="ml-2 block text-sm text-gray-700">
                  Create an interaction record for this meeting
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="generate-meet"
                  checked={generateMeetLink}
                  onChange={(e) => setGenerateMeetLink(e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="generate-meet" className="ml-2 flex items-center text-sm text-gray-700">
                  <Video size={16} className="mr-1" />
                  Generate Google Meet link
                </label>
              </div>
            </>
          )}

          {eventData.meetLink && (
            <div className="p-3 bg-blue-50 rounded-lg">
              <div className="text-sm font-medium text-blue-700">Google Meet Link</div>
              <a 
                href={eventData.meetLink} 
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 break-all hover:underline"
              >
                {eventData.meetLink}
              </a>
            </div>
          )}

          <div className="flex justify-between pt-4 border-t mt-4">
            <div>
              {event?.id && onDelete && event.source !== 'inner-circle' && (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="flex items-center gap-1 px-4 py-2 text-red-600 border border-red-600 rounded-lg hover:bg-red-50"
                  disabled={isSubmitting}
                >
                  <Trash2 size={16} />
                  Delete
                </button>
              )}
            </div>
            <div className="flex gap-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              {(event === null || event.source !== 'inner-circle') && (
                <button
                  type="submit"
                  className={`px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ${
                    isSubmitting ? 'opacity-70 cursor-not-allowed' : ''
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Saving...' : event?.id ? 'Update Event' : 'Add Event'}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CalendarEventModal;