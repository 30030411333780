// packages/web/src/components/settings/EmailAuthorization.tsx
import React, { useState, useEffect } from 'react';
import { Mail, AlertTriangle, CheckCircle, XCircle } from 'lucide-react';

interface EmailAuthorizationProps {
  onComplete?: () => void;
}

const EmailAuthorization: React.FC<EmailAuthorizationProps> = ({ onComplete }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/email-auth/status', {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to get authorization status');
      }
      
      const data = await response.json();
      setIsAuthorized(data.isAuthorized);
      setUserEmail(data.email);
    } catch (err) {
      setError('Failed to check authorization status');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const startAuthorization = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/email-auth/authorize', {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to start authorization process');
      }
      
      const data = await response.json();
      
      // Open the authorization URL in a new window
      window.open(data.authUrl, 'gmail-auth', 'width=600,height=700');
      
      // Check status periodically
      const checkInterval = setInterval(async () => {
        const statusResponse = await fetch('/api/email-auth/status', {
          credentials: 'include'
        });
        
        if (statusResponse.ok) {
          const statusData = await statusResponse.json();
          
          if (statusData.isAuthorized) {
            clearInterval(checkInterval);
            setIsAuthorized(true);
            setUserEmail(statusData.email);
            if (onComplete) onComplete();
          }
        }
      }, 2000); // Check every 2 seconds
      
      // Clear interval after 2 minutes (maximum wait time)
      setTimeout(() => {
        clearInterval(checkInterval);
        setIsLoading(false);
      }, 2 * 60 * 1000);
    } catch (err) {
      setError('Failed to start authorization process');
      console.error(err);
      setIsLoading(false);
    }
  };

  const revokeAuthorization = async () => {
    if (!window.confirm('Are you sure you want to revoke email authorization? All pending reminders will not be sent.')) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/email-auth/revoke', {
        method: 'POST',
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to revoke authorization');
      }
      
      setIsAuthorized(false);
      setUserEmail('');
    } catch (err) {
      setError('Failed to revoke authorization');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-lg border shadow-sm mb-6">
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg border shadow-sm mb-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Mail className="mr-2" />
        Email Authorization
      </h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-200 flex items-center">
          <AlertTriangle size={20} className="mr-2" />
          {error}
        </div>
      )}
      
      <div className="mb-4">
        <p className="text-gray-700 mb-2">
          To send email reminders, you need to authorize Inner Circle to send emails on your behalf using your Gmail account.
        </p>
        
        {isAuthorized ? (
          <div className="mt-4 p-3 bg-green-50 text-green-700 rounded-lg border border-green-200 flex items-center">
            <CheckCircle size={20} className="mr-2" />
            Email sending is authorized for: <strong className="ml-1">{userEmail}</strong>
          </div>
        ) : (
          <div className="mt-4 p-3 bg-amber-50 text-amber-700 rounded-lg border border-amber-200 flex items-center">
            <XCircle size={20} className="mr-2" />
            Email sending is not authorized. You need to connect your Gmail account.
          </div>
        )}
      </div>
      
      <div className="flex justify-end">
        {isAuthorized ? (
          <button
            onClick={revokeAuthorization}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
            disabled={isLoading}
          >
            Revoke Authorization
          </button>
        ) : (
          <button
            onClick={startAuthorization}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            disabled={isLoading}
          >
            Connect Gmail Account
          </button>
        )}
      </div>
    </div>
  );
};

export default EmailAuthorization;