// Update to packages/web/src/components/user/UserSettings.tsx
import React, { useState, useEffect } from 'react';
import { Save, RotateCcw } from 'lucide-react';
import { api } from '../../services/api';
import { User } from '../../types';
import EmailAuthorization from '../settings/EmailAuthorization';
import CalendarAuthorization from '../settings/CalendarAuthorization';
import TelegramConnection from '../settings/TelegramConnection';

interface UserSettingsProps {
  onClose: () => void;
}

// Define the settings interface
interface Settings {
  coldThresholdDays: number;
  notifications: {
    birthdays: boolean;
    dueTodos: boolean;
  };
}

const DEFAULT_SETTINGS: Settings = {
  coldThresholdDays: 30, // default to 30 days
  notifications: {
    birthdays: true,
    dueTodos: true
  }
};

const UserSettings: React.FC<UserSettingsProps> = ({ onClose }) => {
  const [settings, setSettings] = useState<Settings>(DEFAULT_SETTINGS);
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const userSettings = await api.getUserSettings();
      if (userSettings) {
        setSettings(userSettings);
      }
    } catch (error) {
      console.error('Failed to fetch user settings:', error);
      // Fall back to defaults if we can't load settings
      setSettings(DEFAULT_SETTINGS);
    }
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setSaveMessage(null);
      
      await api.updateUserSettings(settings);
      
      setSaveMessage({ type: 'success', text: 'Settings saved successfully!' });
      setTimeout(() => setSaveMessage(null), 3000);
    } catch (error) {
      console.error('Failed to save settings:', error);
      setSaveMessage({ type: 'error', text: 'Failed to save settings. Please try again.' });
    } finally {
      setIsSaving(false);
    }
  };

  const handleReset = () => {
    if (window.confirm('Are you sure you want to reset to default settings?')) {
      setSettings(DEFAULT_SETTINGS);
    }
  };

  return (
    <div className="fixed inset-0 bg-white overflow-y-auto z-50">
      {/* Top navigation bar */}
      <div className="sticky top-0 left-0 right-0 bg-white shadow-sm z-10 px-4 py-2">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Settings</h1>
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
            >
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="max-w-4xl mx-auto p-4 pt-6">
        {saveMessage && (
          <div className={`mb-6 p-3 rounded-lg ${
            saveMessage.type === 'success' 
              ? 'bg-green-50 border border-green-200 text-green-700' 
              : 'bg-red-50 border border-red-200 text-red-700'
          }`}>
            <p>{saveMessage.text}</p>
          </div>
        )}

        <div className="bg-white border rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Contact Status</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Mark contacts as cold after (days without interaction)
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  min="1"
                  max="365"
                  value={settings.coldThresholdDays}
                  onChange={(e) => setSettings({
                    ...settings,
                    coldThresholdDays: parseInt(e.target.value) || DEFAULT_SETTINGS.coldThresholdDays
                  })}
                  className="w-24 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <span className="ml-2 text-gray-600">days</span>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Contacts will automatically be marked as "cold" when they haven't had an interaction in this many days.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white border rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Notifications</h2>
          
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="birthday-notifications"
                checked={settings.notifications.birthdays}
                onChange={(e) => setSettings({
                  ...settings,
                  notifications: {
                    ...settings.notifications,
                    birthdays: e.target.checked
                  }
                })}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="birthday-notifications" className="ml-2 block text-sm text-gray-700">
                Birthday reminders
              </label>
            </div>
            
            <div className="flex items-center">
              <input
                type="checkbox"
                id="todo-notifications"
                checked={settings.notifications.dueTodos}
                onChange={(e) => setSettings({
                  ...settings,
                  notifications: {
                    ...settings.notifications,
                    dueTodos: e.target.checked
                  }
                })}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="todo-notifications" className="ml-2 block text-sm text-gray-700">
                Due TODO reminders
              </label>
            </div>
          </div>
        </div>

        {/* EmailAuthorization component */}
        <EmailAuthorization />

        {/* CalendarAuthorization component - ADD THIS */}
        <CalendarAuthorization />

        {/* TelegramConnection component - ADD THIS */}
        <TelegramConnection />

        <div className="flex justify-between">
          <button
            onClick={handleReset}
            className="flex items-center gap-2 px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
            disabled={isSaving}
          >
            <RotateCcw size={16} />
            Reset to Default
          </button>
          
          <button
            onClick={handleSave}
            className={`flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ${
              isSaving ? 'opacity-70 cursor-not-allowed' : ''
            }`}
            disabled={isSaving}
          >
            <Save size={16} />
            {isSaving ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;