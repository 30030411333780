// packages/web/src/components/calendar/CalendarAuthPrompt.tsx
import React from 'react';
import { Calendar, LucideCalendar, AlertCircle } from 'lucide-react';

interface CalendarAuthPromptProps {
  onAuthorize: () => void;
  onClose: () => void;
}

const CalendarAuthPrompt: React.FC<CalendarAuthPromptProps> = ({ onAuthorize, onClose }) => {
  return (
    <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
      <div className="flex items-start">
        <div className="flex-shrink-0 pt-0.5">
          <Calendar className="h-5 w-5 text-blue-600" />
        </div>
        <div className="ml-3 flex-1">
          <h3 className="text-lg font-medium text-blue-800">Connect Google Calendar</h3>
          <div className="mt-2 text-blue-700">
            <p>
              Connect your Google Calendar to see all your events and create meetings from Inner Circle.
              This will allow you to:
            </p>
            <ul className="list-disc ml-5 mt-2 space-y-1">
              <li>View all your calendar events alongside your contacts' data</li>
              <li>Automatically record interactions when you have calendar events with contacts</li>
              <li>Schedule meetings with your contacts that include Google Meet links</li>
              <li>See birthdays, todos, and reminders in your calendar</li>
            </ul>
          </div>
          <div className="mt-4 flex space-x-3">
            <button
              type="button"
              onClick={onAuthorize}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <LucideCalendar className="-ml-1 mr-2 h-4 w-4" />
              Connect Google Calendar
            </button>
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Skip for now
            </button>
          </div>
          <div className="mt-2 text-xs text-blue-500 flex items-center">
            <AlertCircle className="h-3 w-3 mr-1" />
            Your calendar data stays private and is only used to display and manage your events.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarAuthPrompt;