import React, { useState, useEffect, useRef } from 'react';
import { Search, UserPlus, ThermometerSun, ThermometerSnowflake, Edit2, X, CheckSquare, Bell, Upload } from 'lucide-react';
import AddContactModal from './AddContactModal';
import EditContactModal from './EditContactModal';
import ContactDetails from './ContactDetails';
import { Contact } from '../../types';
import { api } from '../../services/api';
import { getTimeAgo } from '../../utils/contactUtils';
import { reminderService } from '../../services/reminderService';
import ContactImporter from './ContactImporter';


const CONTACTS_PER_PAGE = 20; // Number of contacts to show per page

const ContactList: React.FC = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingContact, setEditingContact] = useState<Contact | null>(null);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterWarmth, setFilterWarmth] = useState<'all' | 'warm' | 'cold'>('all');
  const [filterGroup, setFilterGroup] = useState<string | null>(null);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [userSettings, setUserSettings] = useState({
    coldThresholdDays: 30 // default value
  });
  const [reminderCounts, setReminderCounts] = useState<Record<number, number>>({});
  const [showImporter, setShowImporter] = useState(false);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [hasInitialDataLoaded, setHasInitialDataLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      fetchContacts(),
      fetchGroups(),
      fetchUserSettings()
    ]).then(() => {
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  }, []);

  // Effect to fetch reminders for all contacts
  useEffect(() => {
    if (contacts.length > 0) {
      fetchReminderCounts();
    }
  }, [contacts]);

  const fetchUserSettings = async () => {
    try {
      const settings = await api.getUserSettings();
      setUserSettings(settings);
    } catch (error) {
      console.error('Failed to fetch user settings:', error);
    }
  };

  const fetchContacts = async () => {
    try {
      const data = await api.getContacts();
      setContacts(data);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    }
  };

  const fetchGroups = async () => {
    try {
      const groupsData = await api.getGroups();
      setGroups(groupsData.map((group: any) => group.name));
    } catch (error) {
      console.error('Failed to fetch groups:', error);
    }
  };

  // Optimized function to fetch reminder counts for all contacts at once
  // We'll only fetch counts for contacts that are currently visible on the page
  // plus a buffer of contacts for smooth pagination 
  const fetchReminderCounts = async () => {
    try {
      if (contacts.length === 0) return;
      
      // Create a set of contact IDs to fetch reminders for
      const contactIdsToFetch = new Set<number>();
      
      // Always include currently visible contacts
      paginatedContacts.forEach(contact => {
        if (contact.email) {
          contactIdsToFetch.add(contact.id);
        }
      });
      
      // Add contacts from the next page (if any) to preload data
      const nextPageContacts = filteredContacts.slice(endIndex, endIndex + CONTACTS_PER_PAGE);
      nextPageContacts.forEach(contact => {
        if (contact.email) {
          contactIdsToFetch.add(contact.id);
        }
      });
      
      // Convert to array
      const contactIds = Array.from(contactIdsToFetch);
      
      if (contactIds.length === 0) return;
      
      // Use the batch API to get reminder counts for visible contacts + next page
      const counts = await api.getBatchReminderCounts(contactIds);
      
      // Update the counts map - keep existing counts for contacts not in the current fetch
      setReminderCounts(prevCounts => {
        const newCounts = { ...prevCounts };
        contactIds.forEach(id => {
          newCounts[id] = counts[id] || 0;
        });
        return newCounts;
      });
    } catch (error) {
      console.error('Failed to fetch reminder counts:', error);
      
      // Fallback to individual fetches if the batch API fails, but only for visible contacts
      const visibleContactIds = paginatedContacts
        .filter(contact => contact.email)
        .map(contact => contact.id);
        
      const fallbackCounts: Record<number, number> = {};
      for (const id of visibleContactIds) {
        try {
          const reminders = await reminderService.getRemindersForContact(id);
          fallbackCounts[id] = reminders.filter(r => r.status === 'pending').length;
        } catch (e) {
          fallbackCounts[id] = 0;
        }
      }
      
      // Update only the fetched contacts' counts
      setReminderCounts(prevCounts => ({...prevCounts, ...fallbackCounts}));
    }
  };
  
  // Trigger reminder fetch when the page changes
  useEffect(() => {
    fetchReminderCounts();
  }, [currentPage]);

  const handleAddContact = async (contactData: Omit<Contact, 'id'>) => {
    try {
      console.log('Creating contact with data:', contactData);
      const newContact = await api.createContact(contactData);
      console.log('API response:', newContact);
      
      if (newContact && newContact.id) {
        setContacts(prevContacts => [...prevContacts, newContact]);
        console.log('Updated contacts state');
      } else {
        console.error('Invalid contact returned from API:', newContact);
      }
    } catch (error) {
      console.error('Failed to create contact:', error);
    }
  };

  const handleUpdateContact = async (contactId: number, contactData: Partial<Contact>) => {
    try {
      const updatedContact = await api.updateContact(contactId, contactData);
      setContacts(contacts.map(c => 
        c.id === contactId ? updatedContact : c
      ));
      
      // Refresh reminders count for this contact
      const reminders = await reminderService.getRemindersForContact(contactId);
      const pendingCount = reminders.filter(r => r.status === 'pending').length;
      setReminderCounts(prev => ({...prev, [contactId]: pendingCount}));
    } catch (error) {
      console.error('Failed to update contact:', error);
    }
  };

  const handleDeleteContact = async (contactId: number) => {
    if (!window.confirm('Are you sure you want to delete this contact?')) {
      return;
    }
    
    try {
      console.log('Attempting to delete contact:', contactId);
      
      // Direct fetch call to avoid any potential issues in the API service
      const response = await fetch(`/api/contacts/${contactId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      // Check for errors and handle them appropriately
      if (!response.ok) {
        const errorText = await response.text().catch(() => 'Unknown error');
        console.error('Delete error response:', response.status, errorText);
        
        if (response.status === 500) {
          // Special handling for server errors
          alert('Server error when deleting contact. This contact may have related data that prevents deletion.');
          return;
        }
        
        throw new Error(`Failed to delete contact: ${response.status}`);
      }
      
      console.log('Contact deleted successfully');
      
      // Update the contacts list by removing the deleted contact
      setContacts(prev => prev.filter(c => c.id !== contactId));
      
      // Remove reminder count for this contact
      setReminderCounts(prev => {
        const newCounts = {...prev};
        delete newCounts[contactId];
        return newCounts;
      });
      
      // If the currently selected contact is being deleted, close the detail view
      if (selectedContact?.id === contactId) {
        setSelectedContact(null);
      }
    } catch (error) {
      console.error('Delete error:', error);
      alert('Failed to delete contact. Please try again.');
    }
  };

  // We'll still apply filters to the full contact list
  // This ensures compatibility with other features that expect filtering to work
  const filteredContacts = contacts.filter(contact => {
    const matchesSearch = searchQuery.toLowerCase().trim() === '' ||
      contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.groups.some(group => group.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (contact.notes || '').toLowerCase().includes(searchQuery.toLowerCase());

    const matchesWarmth = filterWarmth === 'all' || contact.warmth === filterWarmth;
    
    const matchesGroup = !filterGroup || contact.groups.includes(filterGroup);

    return matchesSearch && matchesWarmth && matchesGroup;
  });
  
  // Calculate pagination - we'll still use client-side pagination
  // This avoids breaking other components that need access to all contacts
  const totalPages = Math.ceil(filteredContacts.length / CONTACTS_PER_PAGE);
  const startIndex = (currentPage - 1) * CONTACTS_PER_PAGE;
  const endIndex = startIndex + CONTACTS_PER_PAGE;
  const paginatedContacts = filteredContacts.slice(startIndex, endIndex);
  
  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filterWarmth, filterGroup]);
  
  // This reference will hold the complete set of contacts for other components
  // Even though we paginate the display, we keep the full list in memory
  const allContacts = useRef<Contact[]>([]);
  
  // Update the reference whenever contacts changes
  useEffect(() => {
    allContacts.current = contacts;
    if (contacts.length > 0 && !hasInitialDataLoaded) {
      setHasInitialDataLoaded(true);
    }
  }, [contacts, hasInitialDataLoaded]);

  const formatDate = (dateString: string) => {
    if (!dateString) return 'Never';
    
    const date = new Date(dateString);
    // Check if the date is valid
    if (isNaN(date.getTime())) return 'Invalid date';
    
    // Format as YYYY-MM-DD
    return date.toISOString().split('T')[0];
    
    // Or use toLocaleDateString for localized format
    // return date.toLocaleDateString();
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">My Inner Circle</h1>
        <div className="flex gap-2">
          <button 
            onClick={() => setShowImporter(!showImporter)}
            className="flex items-center gap-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors"
          >
            <Upload size={20} />
            <span>Import Contacts</span>
          </button>
          <button 
            onClick={() => setIsAddModalOpen(true)}
            className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            <UserPlus size={20} />
            <span>Add Contact</span>
          </button>
        </div>
      </div>
      {showImporter && (
        <div className="mb-6">
          <ContactImporter />
        </div>
      )}

      <div className="space-y-4 mb-6">
        <div className="relative">
          <Search className="absolute left-3 top-3 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search contacts, groups, or notes..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => setFilterWarmth('all')}
            className={`px-4 py-2 rounded-lg ${
              filterWarmth === 'all'
                ? 'bg-gray-200 text-gray-800'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            All
          </button>
          <button
            onClick={() => setFilterWarmth('warm')}
            className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
              filterWarmth === 'warm'
                ? 'bg-orange-100 text-orange-800'
                : 'bg-gray-100 text-gray-600 hover:bg-orange-50'
            }`}
          >
            <ThermometerSun size={16} />
            Warm
          </button>
          <button
            onClick={() => setFilterWarmth('cold')}
            className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
              filterWarmth === 'cold'
                ? 'bg-blue-100 text-blue-800'
                : 'bg-gray-100 text-gray-600 hover:bg-blue-50'
            }`}
          >
            <ThermometerSnowflake size={16} />
            Cold
          </button>
        </div>

        <div className="flex items-center gap-2">
          <label className="text-sm font-medium text-gray-700">
            Filter by group:
          </label>
          <select
            value={filterGroup || ''}
            onChange={(e) => setFilterGroup(e.target.value || null)}
            className="px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          >
            <option value="">All Groups</option>
            {Array.from(new Set(contacts.flatMap(c => c.groups))).sort().map(group => (
              <option key={group} value={group}>
                {group}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="space-y-4">
        {filteredContacts.length === 0 ? (
          <div className="text-center py-8">
            <p className="text-gray-500 text-lg">No contacts found</p>
            <p className="text-gray-400 text-sm mt-2">Try adjusting your search or filters</p>
          </div>
        ) : (
          <>
            <div className="flex justify-between mb-2 items-center">
              <p className="text-gray-600 text-sm">
                Showing {startIndex + 1} to {Math.min(endIndex, filteredContacts.length)} of {filteredContacts.length} contacts
              </p>
            </div>
            
            {/* Paginated contacts list */}
            {paginatedContacts.map(contact => (
              <div 
                key={contact.id} 
                className="border rounded-lg p-4 hover:shadow-lg transition-shadow bg-white cursor-pointer"
                onClick={() => setSelectedContact(contact)}
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-semibold">{contact.name}</h3>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (window.confirm('Are you sure you want to delete this contact?')) {
                          handleDeleteContact(contact.id);
                        }
                      }}
                      className="p-1 hover:bg-red-100 rounded-full text-red-500"
                    >
                      <X size={20} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingContact(contact);
                      }}
                      className="p-1 hover:bg-gray-100 rounded-full"
                    >
                      <Edit2 size={20} className="text-gray-600" />
                    </button>
                    {contact.warmth === 'warm' ? (
                      <ThermometerSun className="text-orange-500" size={24} />
                    ) : (
                      <ThermometerSnowflake className="text-blue-500" size={24} />
                    )}
                  </div>
                </div>
                
                <div className="mt-2 text-gray-600">
                  Last interaction: {getTimeAgo(contact.lastInteraction)}
                  {contact.warmth === 'cold' && (
                    <span className="ml-2 text-blue-500">• Cold</span>
                  )}
                  {contact.warmth === 'warm' && new Date(contact.lastInteraction) < new Date(Date.now() - (userSettings.coldThresholdDays * 24 * 60 * 60 * 1000)) && (
                    <span className="ml-2 text-orange-500">• Will be marked cold soon</span>
                  )}
                </div>
                
                <div className="mt-2 flex flex-wrap gap-2">
                  {contact.groups.map(group => (
                    <span 
                      key={group} 
                      className="bg-gray-100 px-2 py-1 rounded-full text-sm text-gray-700"
                    >
                      {group}
                    </span>
                  ))}
                </div>
                
                <div className="mt-2 flex gap-4">
                  <div className="flex items-center gap-2">
                    <CheckSquare size={16} className="text-blue-500" />
                    <span className="text-gray-700 text-sm">
                      {contact.todos.filter(todo => !todo.completed).length} active todos
                    </span>
                  </div>
                  
                  {/* Add reminders count */}
                  <div className="flex items-center gap-2">
                    <Bell size={16} className="text-blue-500" />
                    <span className="text-gray-700 text-sm">
                      {reminderCounts[contact.id] || 0} active reminders
                    </span>
                  </div>
                </div>
                
                <div className="mt-2 text-gray-700">
                  {contact.notes}
                </div>
              </div>
            ))}
            
            {/* Pagination controls */}
            {totalPages > 1 && (
              <div className="flex justify-center mt-6 gap-2">
                <button
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded border ${
                    currentPage === 1 
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                      : 'bg-white hover:bg-gray-50'
                  }`}
                >
                  Previous
                </button>
                
                {/* Page number indicators */}
                {Array.from({ length: Math.min(totalPages, 5) }).map((_, i) => {
                  // Show first page, last page, current page, and pages around current
                  let pageToShow = i + 1;
                  if (totalPages > 5) {
                    if (currentPage <= 3) {
                      // Near the start
                      pageToShow = i + 1;
                    } else if (currentPage >= totalPages - 2) {
                      // Near the end
                      pageToShow = totalPages - 4 + i;
                    } else {
                      // In the middle
                      pageToShow = currentPage - 2 + i;
                    }
                  }
                  
                  return (
                    <button
                      key={pageToShow}
                      onClick={() => setCurrentPage(pageToShow)}
                      className={`px-3 py-1 rounded border ${
                        currentPage === pageToShow
                          ? 'bg-blue-500 text-white'
                          : 'bg-white hover:bg-gray-50'
                      }`}
                    >
                      {pageToShow}
                    </button>
                  );
                })}
                
                <button
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded border ${
                    currentPage === totalPages
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white hover:bg-gray-50'
                  }`}
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>

      <AddContactModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAddContact}
        existingGroups={[...groups, ...Array.from(new Set(contacts.flatMap(c => c.groups)))]}
      />

      <EditContactModal
        contact={editingContact}
        isOpen={editingContact !== null}
        onClose={() => setEditingContact(null)}
        onUpdate={(updatedContact) => {
          handleUpdateContact(updatedContact.id, updatedContact);
        }}
        existingGroups={[...groups, ...Array.from(new Set(contacts.flatMap(c => c.groups)))]}
      />

      {selectedContact && (
        <ContactDetails
          contact={selectedContact}
          onClose={() => setSelectedContact(null)}
          onDelete={handleDeleteContact}
          onUpdate={(updatedContact) => {
            handleUpdateContact(updatedContact.id, updatedContact);
            setSelectedContact(updatedContact);
          }}
        />
      )}
    </div>
  );
};

export default ContactList;