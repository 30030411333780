import React, { useState, useEffect } from 'react';
import { Check, Clock, ChevronRight, ChevronDown } from 'lucide-react';
import { Contact } from '../../types';
import { api } from '../../services/api';

interface TodoListProps {
  contacts: Contact[];
  onUpdateContact: (contact: Contact) => void;
}

const TodoList: React.FC<TodoListProps> = ({ contacts: initialContacts, onUpdateContact }) => {
  const [expandedContacts, setExpandedContacts] = useState<number[]>([]);
  const [contacts, setContacts] = useState<Contact[]>(initialContacts);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const data = await api.getContacts();
      setContacts(data);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleExpanded = (contactId: number) => {
    setExpandedContacts(prev =>
      prev.includes(contactId)
        ? prev.filter(id => id !== contactId)
        : [...prev, contactId]
    );
  };

  // Get contacts with active todos
  const contactsWithTodos = contacts.filter(contact => 
    contact.todos && contact.todos.some(todo => !todo.completed)
  );

  const toggleTodo = async (contact: Contact, todoId: string) => {
    try {
      const todoToToggle = contact.todos.find(todo => todo.id === todoId);
      if (!todoToToggle) return;
      
      const updatedTodos = contact.todos.map(todo =>
        todo.id === todoId ? { ...todo, completed: !todo.completed } : todo
      );
      
      const updatedContact = {
        ...contact,
        todos: updatedTodos
      };
      
      await api.updateContact(contact.id, updatedContact);
      onUpdateContact(updatedContact);
      
      // Also update the local state
      setContacts(prevContacts => 
        prevContacts.map(c => c.id === contact.id ? updatedContact : c)
      );
    } catch (error) {
      console.error('Failed to update todo status:', error);
    }
  };

  const getDueStatus = (dueDate: string | undefined) => {
    if (!dueDate) return null;
    
    const today = new Date();
    const due = new Date(dueDate);
    const diffDays = Math.ceil((due.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) return 'overdue';
    if (diffDays === 0) return 'due-today';
    if (diffDays <= 3) return 'due-soon';
    return 'upcoming';
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Active TODOs</h2>
      
      {contactsWithTodos.length === 0 ? (
        <div className="text-center py-8 bg-white rounded-lg border">
          <p className="text-gray-500">No active TODOs</p>
        </div>
      ) : (
        <div className="space-y-4">
          {contactsWithTodos.map(contact => (
            <div key={contact.id} className="bg-white rounded-lg border overflow-hidden">
              <button
                onClick={() => toggleExpanded(contact.id)}
                className="w-full px-4 py-3 flex items-center justify-between bg-gray-50 hover:bg-gray-100"
              >
                <div className="flex items-center gap-2">
                  {expandedContacts.includes(contact.id) ? (
                    <ChevronDown size={20} />
                  ) : (
                    <ChevronRight size={20} />
                  )}
                  <span className="font-medium">{contact.name}</span>
                  <span className="text-gray-500 text-sm">
                    ({contact.todos?.filter(todo => !todo.completed).length || 0} active)
                  </span>
                </div>
              </button>
              
              {expandedContacts.includes(contact.id) && contact.todos && (
                <div className="p-4 space-y-2">
                  {contact.todos
                    .filter(todo => !todo.completed)
                    .sort((a, b) => {
                      if (!a.dueDate && !b.dueDate) return 0;
                      if (!a.dueDate) return 1;
                      if (!b.dueDate) return -1;
                      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
                    })
                    .map(todo => (
                      <div
                        key={todo.id}
                        className="flex items-center gap-3 p-2 rounded-lg hover:bg-gray-50"
                      >
                        <button
                          onClick={() => toggleTodo(contact, todo.id)}
                          className="w-5 h-5 border-2 rounded-full flex items-center justify-center hover:bg-gray-100"
                        >
                          {todo.completed && <Check size={14} className="text-gray-400" />}
                        </button>
                        <div className="flex-1">
                          <p className={todo.completed ? "line-through text-gray-400" : ""}>
                            {todo.task}
                          </p>
                          {todo.dueDate && (
                            <div className="flex items-center gap-1 mt-1">
                              <Clock size={14} className="text-gray-400" />
                              <span className={`text-sm ${
                                getDueStatus(todo.dueDate) === 'overdue'
                                  ? 'text-red-600'
                                  : getDueStatus(todo.dueDate) === 'due-today'
                                  ? 'text-orange-600'
                                  : getDueStatus(todo.dueDate) === 'due-soon'
                                  ? 'text-yellow-600'
                                  : 'text-gray-500'
                              }`}>
                                Due: {todo.dueDate}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TodoList;