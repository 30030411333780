import React, { useState } from 'react';
import Papa from 'papaparse';
import { Contact } from '../../types';
import { api } from '../../services/api';
import { Upload, Check } from 'lucide-react';

interface ContactImporterProps {
  onImportComplete?: () => void;
}

const ContactImporter: React.FC<ContactImporterProps> = ({ onImportComplete }) => {
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<any[]>([]);
  const [mapping, setMapping] = useState<Record<string, string>>({});
  const [importing, setImporting] = useState(false);
  const [results, setResults] = useState({ success: 0, error: 0, details: [] as any[] });
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');

  const requiredFields = ['name'];
  const availableFields = [
    'name', 'email', 'phone', 'notes', 'birthday', 
    'groups', 'linkedin', 'facebook', 'telegram', 'xcom'
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;
    
    setFile(selectedFile);
    setError('');
    
    Papa.parse(selectedFile, {
      header: true,
      skipEmptyLines: true,
      preview: 5,
      complete: (results) => {
        if (results.data.length === 0) {
          setError('The file appears to be empty.');
          return;
        }
        
        // Create initial mapping suggestions based on header names
        const headers = results.meta.fields || [];
        const initialMapping: Record<string, string> = {};
        
        headers.forEach(header => {
          // Try to find matching field
          const normalizedHeader = header.toLowerCase().trim();
          
          if (normalizedHeader.includes('name')) {
            initialMapping[header] = 'name';
          } else if (normalizedHeader.includes('email')) {
            initialMapping[header] = 'email';
          } else if (normalizedHeader.includes('phone')) {
            initialMapping[header] = 'phone';
          } else if (normalizedHeader.includes('note')) {
            initialMapping[header] = 'notes';
          } else if (normalizedHeader.includes('birth')) {
            initialMapping[header] = 'birthday';
          } else if (normalizedHeader.includes('group')) {
            initialMapping[header] = 'groups';
          } else if (normalizedHeader.includes('linked')) {
            initialMapping[header] = 'linkedin';
          } else if (normalizedHeader.includes('face') || normalizedHeader.includes('fb')) {
            initialMapping[header] = 'facebook';
          } else if (normalizedHeader.includes('tele')) {
            initialMapping[header] = 'telegram';
          } else if (normalizedHeader.includes('twitter') || normalizedHeader.includes('x.com')) {
            initialMapping[header] = 'xcom';
          }
        });
        
        setMapping(initialMapping);
        setPreview(results.data);
        setStep(2);
      },
      error: (error) => {
        setError(`Error parsing file: ${error.message}`);
      }
    });
  };

  const handleMappingChange = (csvField: string, appField: string) => {
    setMapping({
      ...mapping,
      [csvField]: appField
    });
  };

  const validateMapping = () => {
    // Check if name field is mapped
    return Object.values(mapping).includes('name');
  };

  const handleImport = async () => {
    if (!file) return;
    
    if (!validateMapping()) {
      setError('The "name" field is required. Please map a column to the name field.');
      return;
    }
    
    setImporting(true);
    setError('');
    setResults({ success: 0, error: 0, details: [] });
    
    try {
      // Parse the full file
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const importResults = { success: 0, error: 0, details: [] as any[] };
          
          // Explicitly type the row as a Record<string, any>
          for (const rowData of results.data as Record<string, any>[]) {
            try {
              // Map CSV data to contact fields
              const contactData: Partial<Contact> = {
                name: '',
                warmth: 'warm', // Default to warm
                groups: [], 
                notes: '',
                lastInteraction: new Date().toISOString()
              };
              
              // Apply mapping
              Object.keys(mapping).forEach(csvField => {
                const appField = mapping[csvField];
                
                if (appField && rowData[csvField] !== undefined) {
                  // Special handling for groups field
                  if (appField === 'groups') {
                    const groupsValue = rowData[csvField];
                    // If groups is a comma-separated string, split it
                    if (typeof groupsValue === 'string') {
                      (contactData as any)[appField] = groupsValue.split(',').map(g => g.trim());
                    } else {
                      (contactData as any)[appField] = [String(groupsValue)];
                    }
                  } else {
                    (contactData as any)[appField] = rowData[csvField];
                  }
                }
              });
              
              // Check required fields
              if (!contactData.name) {
                throw new Error('Name is required');
              }
              
              // Create the contact
              await api.createContact(contactData as Omit<Contact, 'id'>);
              importResults.success++;
              importResults.details.push({
                name: contactData.name,
                status: 'success',
                message: 'Contact imported successfully'
              });
              } catch (err) {
                importResults.error++;
                const errorMessage = err instanceof Error ? err.message : 'Failed to import contact';
                importResults.details.push({
                  name: rowData[Object.keys(mapping).find(key => mapping[key] === 'name') || ''] || 'Unknown',
                  status: 'error',
                  message: errorMessage
                });
              }
          }
          
          setResults(importResults);
          setStep(3);
          setImporting(false);
        },
        error: (error) => {
          setError(`Error parsing file: ${error.message}`);
          setImporting(false);
        }
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      setError(`Import failed: ${errorMessage}`);
      setImporting(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg border shadow-sm max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Import Contacts</h2>
      
      {/* Step 1: Upload File */}
      {step === 1 && (
        <div>
          <p className="mb-4 text-gray-600">
            Upload a CSV file containing your contacts. The file should have columns for contact information such as name, email, phone, etc.
          </p>
          
          <div className="mb-6">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-lg file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100
              "
            />
          </div>
          
          <div className="bg-blue-50 p-4 rounded-lg mb-4">
            <h3 className="font-medium text-blue-800 mb-2">Tips for preparing your CSV file:</h3>
            <ul className="list-disc pl-5 text-blue-700 text-sm">
              <li>Make sure your CSV has a header row with column names</li>
              <li>The file must include a column that can be mapped to "name"</li>
              <li>For groups, you can use a comma-separated list in a single column</li>
              <li>Date formats should be YYYY-MM-DD</li>
            </ul>
          </div>
        </div>
      )}
      
      {/* Step 2: Map Fields */}
      {step === 2 && (
        <div>
          <p className="mb-4 text-gray-600">
            Map the columns from your CSV file to the appropriate contact fields.
            <span className="font-medium text-gray-800"> "Name" is required.</span>
          </p>
          
          {preview.length > 0 && (
            <div className="mb-6">
              <h3 className="font-medium mb-2">Preview:</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full border border-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      {Object.keys(preview[0]).map((header, index) => (
                        <th key={index} className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {preview.map((row, rowIndex) => (
                      <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        {Object.keys(row).map((cell, cellIndex) => (
                          <td key={cellIndex} className="px-4 py-2 border-b text-sm">
                            {row[cell]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          
          <div className="mb-6">
            <h3 className="font-medium mb-2">Map Fields:</h3>
            <div className="space-y-3">
              {Object.keys(preview[0] || {}).map((csvField, index) => (
                <div key={index} className="flex items-center">
                  <span className="w-1/3 text-sm font-medium text-gray-700">{csvField}:</span>
                  <select
                    value={mapping[csvField] || ''}
                    onChange={(e) => handleMappingChange(csvField, e.target.value)}
                    className="w-2/3 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Don't import</option>
                    {availableFields.map((field) => (
                      <option key={field} value={field}>
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                        {requiredFields.includes(field) ? ' (Required)' : ''}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          </div>
          
          <div className="flex justify-between">
            <button
              onClick={() => setStep(1)}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Back
            </button>
            
            <button
              onClick={handleImport}
              disabled={importing || !validateMapping()}
              className={`px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                importing || !validateMapping() 
                  ? 'bg-blue-300 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {importing ? 'Importing...' : 'Import Contacts'}
            </button>
          </div>
        </div>
      )}
      
      {/* Step 3: Results */}
      {step === 3 && (
        <div>
          <div className="flex items-center justify-center mb-6">
            <div className="flex items-center mr-6">
              <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center mr-2">
                <span className="text-green-800 font-medium">{results.success}</span>
              </div>
              <span className="text-gray-700">Successful</span>
            </div>
            
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-red-100 flex items-center justify-center mr-2">
                <span className="text-red-800 font-medium">{results.error}</span>
              </div>
              <span className="text-gray-700">Failed</span>
            </div>
          </div>
          
          <div className="mb-6 max-h-64 overflow-y-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                </tr>
              </thead>
              <tbody>
                {results.details.map((detail, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-4 py-2 text-sm">{detail.name}</td>
                    <td className="px-4 py-2 text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        detail.status === 'success' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {detail.status}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-sm">{detail.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="flex justify-between">
            <button
              onClick={() => {
                setFile(null);
                setPreview([]);
                setMapping({});
                setResults({ success: 0, error: 0, details: [] });
                setStep(1);
              }}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Import Another File
            </button>
            
            <button
              onClick={() => {
                if (onImportComplete) {
                  onImportComplete();
                } else {
                  window.location.reload();
                }
              }}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Done
            </button>
          </div>
        </div>
      )}
      
      {error && (
        <div className="mt-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}
    </div>
  );
};

export default ContactImporter;