// Google Analytics service

// Initialize the gtag function
declare global {
    interface Window {
      gtag: (
        command: string,
        action: string,
        params?: any
      ) => void;
    }
  }
  
  // Google Analytics ID
  const GA_MEASUREMENT_ID = 'G-CR77MHQ3QK';
  
  // Check if analytics is enabled
  const isAnalyticsEnabled = () => {
    return localStorage.getItem('analyticsDisabled') !== 'true';
  };
  
  export const analytics = {
    // Track page views
    pageView: (pagePath: string, pageTitle: string) => {
      if (!window.gtag || !isAnalyticsEnabled()) return;
      
      window.gtag('event', 'page_view', {
        page_path: pagePath,
        page_title: pageTitle
      });
    },
  
    // Track user events
    trackEvent: (eventName: string, eventParams: Record<string, any> = {}) => {
      if (!window.gtag || !isAnalyticsEnabled()) return;
      
      window.gtag('event', eventName, eventParams);
    },
  
    // Track user login
    trackLogin: (method: string) => {
      if (!window.gtag || !isAnalyticsEnabled()) return;
      
      window.gtag('event', 'login', {
        method: method
      });
    }
  };
  
  // Toggle analytics on/off
  export const toggleAnalytics = (enabled: boolean) => {
    localStorage.setItem('analyticsDisabled', enabled ? 'false' : 'true');
    
    // If disabling, you can also set GA to not track this user
    if (!enabled && window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'denied'
      });
    } else if (enabled && window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    }
  };