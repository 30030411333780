import React, { useState, useEffect, useRef } from 'react';
import { X, ThermometerSun, ThermometerSnowflake, Linkedin, Facebook, MessageCircle, Twitter } from 'lucide-react';
import { Contact } from '../../types';
import GroupInput from '../groups/GroupInput';
import { analytics } from '../../services/analytics';

interface AddContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (contact: Omit<Contact, 'id'>) => void;
  existingGroups: string[];
}

const SUBMISSION_COOLDOWN = 2000; // 2 seconds cooldown

const AddContactModal: React.FC<AddContactModalProps> = ({ 
  isOpen, 
  onClose, 
  onAdd,
  existingGroups 
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    selectedGroups: [] as string[],
    notes: '',
    birthday: '',
    warmth: 'warm' as 'warm' | 'cold',
    // Adding social media fields
    linkedin: '',
    facebook: '',
    telegram: '',
    xcom: ''
  });
  
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const lastSubmitTimeRef = useRef<number>(0);

  // Log existing groups when they change (for debugging)
  useEffect(() => {
    console.log('Existing groups in AddContactModal:', existingGroups);
  }, [existingGroups]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate name is required
    if (!formData.name.trim()) {
      setError('Contact name is required');
      return;
    }
    
    // Throttle submissions to prevent rapid creation of contacts
    const now = Date.now();
    if (now - lastSubmitTimeRef.current < SUBMISSION_COOLDOWN) {
      setError(`Please wait before adding another contact`);
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    lastSubmitTimeRef.current = now;

    try {
      // Prepare contact data, ensuring all required fields are present
      const contactData = {
        name: formData.name.trim(),
        email: formData.email.trim() || undefined,
        phone: formData.phone.trim() || undefined,
        notes: formData.notes || "",
        // Only include birthday if it's not empty
        ...(formData.birthday ? { birthday: formData.birthday } : {}),
        warmth: formData.warmth,
        lastInteraction: new Date().toISOString(),
        groups: formData.selectedGroups || [],
        interactions: [],
        todos: [],
        // Add social media fields
        linkedin: formData.linkedin.trim() || undefined,
        facebook: formData.facebook.trim() || undefined,
        telegram: formData.telegram.trim() || undefined,
        xcom: formData.xcom.trim() || undefined
      };
      
      console.log('Submitting contact data:', contactData);
      
      // Call the onAdd function provided by parent
      onAdd(contactData);
      analytics.trackEvent('add_contact', {
        warmth: formData.warmth,
        has_email: !!formData.email,
        has_groups: formData.selectedGroups.length > 0,
        groups_count: formData.selectedGroups.length
      });
      
      // Reset form and close modal
      setFormData({
        name: '',
        email: '',
        phone: '',
        selectedGroups: [],
        notes: '',
        birthday: '',
        warmth: 'warm',
        linkedin: '',
        facebook: '',
        telegram: '',
        xcom: ''
      });
      
      onClose();
    } catch (error) {
      console.error('Error adding contact:', error);
      setError('Failed to add contact. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-semibold">Add New Contact</h2>
          </div>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700"
            disabled={isSubmitting}
          >
            <X size={24} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-200">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              required
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <input
              type="tel"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Birthday
            </label>
            <input
              type="date"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.birthday}
              onChange={(e) => setFormData({ ...formData, birthday: e.target.value })}
              disabled={isSubmitting}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Groups
            </label>
            <GroupInput
              selectedGroups={formData.selectedGroups}
              existingGroups={existingGroups}
              onChange={(groups) => setFormData({ ...formData, selectedGroups: groups })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Warmth Level
            </label>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => setFormData({ ...formData, warmth: 'warm' })}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  formData.warmth === 'warm'
                    ? 'bg-orange-100 text-orange-800'
                    : 'bg-gray-100 text-gray-600 hover:bg-orange-50'
                }`}
                disabled={isSubmitting}
              >
                <ThermometerSun size={20} />
                Warm
              </button>
              <button
                type="button"
                onClick={() => setFormData({ ...formData, warmth: 'cold' })}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  formData.warmth === 'cold'
                    ? 'bg-blue-100 text-blue-800'
                    : 'bg-gray-100 text-gray-600 hover:bg-blue-50'
                }`}
                disabled={isSubmitting}
              >
                <ThermometerSnowflake size={20} />
                Cold
              </button>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-1">
              <label className="block text-sm font-medium text-gray-700">
                Social Media
              </label>
              <button
                type="button"
                onClick={() => setShowSocialMedia(!showSocialMedia)}
                className={`px-3 py-1 rounded-md text-sm flex items-center gap-1 ${
                  showSocialMedia 
                  ? 'bg-gray-200 text-gray-700'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                {showSocialMedia ? 'Hide' : 'Add Social Media Links'}
                {!showSocialMedia && <span>→</span>}
              </button>
            </div>
            
            {showSocialMedia && (
              <div className="space-y-3 p-3 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2">
                  <Linkedin size={18} className="text-blue-700 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="LinkedIn URL or username"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.linkedin}
                    onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
                    disabled={isSubmitting}
                  />
                </div>
                
                <div className="flex items-center gap-2">
                  <Facebook size={18} className="text-blue-600 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="Facebook URL or username"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.facebook}
                    onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
                    disabled={isSubmitting}
                  />
                </div>
                
                <div className="flex items-center gap-2">
                  <MessageCircle size={18} className="text-blue-400 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="Telegram username (without @)"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.telegram}
                    onChange={(e) => setFormData({ ...formData, telegram: e.target.value })}
                    disabled={isSubmitting}
                  />
                </div>
                
                <div className="flex items-center gap-2">
                  <Twitter size={18} className="text-gray-800 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="X.com username (without @)"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.xcom}
                    onChange={(e) => setFormData({ ...formData, xcom: e.target.value })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notes
            </label>
            <textarea
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              rows={3}
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              disabled={isSubmitting}
            />
          </div>

          <div className="flex justify-end gap-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ${
                isSubmitting ? 'opacity-70 cursor-not-allowed' : ''
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Adding...' : 'Add Contact'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddContactModal;