// packages/web/src/services/reminderService.ts
import { ReminderData } from '../components/reminders/ReminderForm';

export interface Reminder extends ReminderData {
  id: string;
  createdAt: string;
  status: 'pending' | 'sent' | 'failed' | 'canceled';
  nextSendDate?: string;
  sendCount?: number; // Added to track how many times recurring reminders have been sent
}

// This service handles all reminder-related API calls
export const reminderService = {
  // Get all reminders for a contact
  getRemindersForContact: async (contactId: number): Promise<Reminder[]> => {
    try {
      const response = await fetch(`/api/reminders/contact/${contactId}`, {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch reminders');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error fetching reminders:', error);
      throw error;
    }
  },
  
  // Get all reminders for the current user
  getAllReminders: async (): Promise<Reminder[]> => {
    try {
      const response = await fetch('/api/reminders', {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch reminders');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error fetching all reminders:', error);
      throw error;
    }
  },
  
  // Create a new reminder
  createReminder: async (reminderData: ReminderData): Promise<Reminder> => {
    try {
      const response = await fetch('/api/reminders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reminderData),
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to create reminder');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error creating reminder:', error);
      throw error;
    }
  },
  
  // Update an existing reminder
  updateReminder: async (id: string, reminderData: Partial<ReminderData>): Promise<Reminder> => {
    try {
      console.log(`Updating reminder ${id} with data:`, reminderData);
      
      const response = await fetch(`/api/reminders/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reminderData),
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update reminder: ${errorText}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error updating reminder:', error);
      throw error;
    }
  },
  
  // Delete a reminder
  deleteReminder: async (id: string): Promise<void> => {
    try {
      const response = await fetch(`/api/reminders/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete reminder');
      }
    } catch (error) {
      console.error('Error deleting reminder:', error);
      throw error;
    }
  },
  
  // Cancel a reminder (alternative to deleting)
  cancelReminder: async (id: string): Promise<Reminder> => {
    try {
      const response = await fetch(`/api/reminders/${id}/cancel`, {
        method: 'POST',
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to cancel reminder');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error canceling reminder:', error);
      throw error;
    }
  },
  
  // Send a test email for this reminder
  sendTestEmail: async (id: string): Promise<{ success: boolean, message: string }> => {
    try {
      const response = await fetch(`/api/reminders/${id}/test`, {
        method: 'POST',
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ error: 'Unknown error' }));
        throw new Error(errorData.error || 'Failed to send test email');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error sending test email:', error);
      throw error;
    }
  },
  
  // Retry a failed reminder
  retryReminder: async (id: string): Promise<Reminder> => {
    try {
      // Reset the status to pending
      const response = await fetch(`/api/reminders/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 'pending' }),
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to retry reminder');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error retrying reminder:', error);
      throw error;
    }
  }
};