// packages/web/src/components/settings/TelegramConnection.tsx
import React, { useState, useEffect } from 'react';
import { Send, AlertTriangle, CheckCircle, XCircle, Copy, ExternalLink } from 'lucide-react';

interface TelegramConnectionProps {
  onComplete?: () => void;
}

const TelegramConnection: React.FC<TelegramConnectionProps> = ({ onComplete }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pairingCode, setPairingCode] = useState<string | null>(null);
  const [isGeneratingCode, setIsGeneratingCode] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);

  useEffect(() => {
    checkConnectionStatus();
  }, []);

  const checkConnectionStatus = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/telegram/status', {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to get connection status');
      }
      
      const data = await response.json();
      setIsConnected(data.isConnected);
    } catch (err) {
      setError('Failed to check connection status');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const generatePairingCode = async () => {
    try {
      setIsGeneratingCode(true);
      setError(null);
      setCodeCopied(false);
      
      const response = await fetch('/api/telegram/pairing-code', {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to generate pairing code');
      }
      
      const data = await response.json();
      setPairingCode(data.code);
      
      // Set up a timer to check connection status periodically
      const checkInterval = setInterval(async () => {
        try {
          const statusResponse = await fetch('/api/telegram/status', {
            credentials: 'include'
          });
          
          if (statusResponse.ok) {
            const statusData = await statusResponse.json();
            
            if (statusData.isConnected) {
              clearInterval(checkInterval);
              setIsConnected(true);
              setPairingCode(null);
              if (onComplete) onComplete();
            }
          }
        } catch (err) {
          console.error('Error checking connection status:', err);
        }
      }, 5000); // Check every 5 seconds
      
      // Clear interval after 15 minutes (maximum code validity)
      setTimeout(() => {
        clearInterval(checkInterval);
        if (!isConnected) {
          setPairingCode(null);
        }
      }, 15 * 60 * 1000);
    } catch (err) {
      setError('Failed to generate pairing code');
      console.error(err);
    } finally {
      setIsGeneratingCode(false);
    }
  };

  const disconnectTelegram = async () => {
    if (!window.confirm('Are you sure you want to disconnect Telegram? You will no longer be able to receive or send messages via Telegram.')) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/telegram/disconnect', {
        method: 'POST',
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to disconnect Telegram');
      }
      
      setIsConnected(false);
    } catch (err) {
      setError('Failed to disconnect Telegram');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const copyPairingCode = () => {
    if (pairingCode) {
      navigator.clipboard.writeText(pairingCode).then(() => {
        setCodeCopied(true);
        setTimeout(() => setCodeCopied(false), 2000);
      }).catch(err => {
        console.error('Error copying to clipboard:', err);
      });
    }
  };

  const openTelegramBot = () => {
    window.open('https://t.me/icCRMBot', '_blank');
  };

  if (isLoading && !pairingCode) {
    return (
      <div className="bg-white p-6 rounded-lg border shadow-sm mb-6">
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg border shadow-sm mb-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Send className="mr-2" />
        Telegram Connection
      </h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-200 flex items-center">
          <AlertTriangle size={20} className="mr-2" />
          {error}
        </div>
      )}
      
      <div className="mb-4">
        <p className="text-gray-700 mb-2">
          Connect Inner Circle with Telegram to manage your CRM directly through chat messaging. This allows you to add interactions, create todos, and get information about your contacts without opening the app.
        </p>
        
        {isConnected ? (
          <div className="mt-4 p-3 bg-green-50 text-green-700 rounded-lg border border-green-200 flex items-center">
            <CheckCircle size={20} className="mr-2" />
            <div>
              <div>Your Inner Circle account is connected to Telegram</div>
              <div className="text-sm mt-1">
                Bot: <a 
                  href="https://t.me/icCRMBot" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  @icCRMBot
                </a>
              </div>
            </div>
          </div>
        ) : pairingCode ? (
          <div className="mt-4">
            <div className="p-3 bg-blue-50 text-blue-700 rounded-lg border border-blue-200">
              <p className="font-medium mb-2">Connect with Telegram Bot</p>
              <ol className="list-decimal ml-5 mb-4 space-y-2">
                <li>Open our Telegram bot: <a 
                    href="https://t.me/icCRMBot"
                    className="text-blue-600 hover:text-blue-800 underline flex items-center inline-flex"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    @icCRMBot <ExternalLink size={14} className="ml-1" />
                  </a>
                </li>
                <li>Send the following code to the bot:</li>
              </ol>
              
              <div className="flex items-center justify-center mb-4">
                <div className="bg-white px-6 py-3 border rounded-lg text-2xl font-mono tracking-wider">
                  {pairingCode}
                </div>
                <button 
                  onClick={copyPairingCode}
                  className={`ml-2 p-2 rounded-lg hover:bg-blue-100 ${codeCopied ? 'text-green-600' : 'text-blue-600'}`}
                  title="Copy code"
                >
                  {codeCopied ? <CheckCircle size={20} /> : <Copy size={20} />}
                </button>
              </div>
              
              <p className="text-sm text-blue-600">This code will expire in 15 minutes</p>
            </div>
          </div>
        ) : (
          <div className="mt-4 p-3 bg-amber-50 text-amber-700 rounded-lg border border-amber-200 flex items-center">
            <XCircle size={20} className="mr-2" />
            Your Inner Circle account is not connected to Telegram
          </div>
        )}
      </div>
      
      <div className="flex justify-end">
        {isConnected ? (
          <button
            onClick={disconnectTelegram}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
            disabled={isLoading}
          >
            Disconnect Telegram
          </button>
        ) : pairingCode ? (
          <button
            onClick={() => setPairingCode(null)}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
            disabled={isLoading}
          >
            Cancel
          </button>
        ) : (
          <button
            onClick={generatePairingCode}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            disabled={isGeneratingCode}
          >
            {isGeneratingCode ? 'Generating...' : 'Connect Telegram'}
          </button>
        )}
      </div>
    </div>
  );
};

export default TelegramConnection;