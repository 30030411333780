// packages/web/src/App.tsx
import React, { useState, useEffect } from 'react';
import ContactList from './components/contacts/ContactList';
import GroupManager from './components/groups/GroupManager';
import TodoList from './components/todos/TodoList';
import ActiveReminders from './components/reminders/ActiveReminders';
import Calendar from './components/calendar/Calendar'; 
import UserSettings from './components/user/UserSettings';
import EnhancedAIAssistant from './components/ai/EnhancedAIAssistant';
import PrivacyPolicy from './components/PrivacyPolicy';
import LandingPage from './components/LandingPage';
import Pricing from './components/Pricing';
import { 
  Users, 
  UserCircle, 
  User as UserIcon,  
  CheckSquare, 
  X, 
  Settings, 
  LogOut,
  Bell,
  Calendar as CalendarIcon 
} from 'lucide-react';
import { Contact, User } from './types';
import { api } from './services/api';

function App() {
  const [activeTab, setActiveTab] = useState<'contacts' | 'groups' | 'todos' | 'reminders' | 'calendar' | 'settings'>('contacts');
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  // Check if we should show a special page
  const isPricingPath = window.location.pathname === '/pricing';
  const isPrivacyPolicyPath = window.location.pathname === '/privacy-policy';

  // Fetch notifications when user is loaded
  useEffect(() => {
    if (user) {
      fetchNotifications();
      
      // Set up a polling interval to fetch notifications every 2 minutes
      const interval = setInterval(fetchNotifications, 2 * 60 * 1000);
      
      return () => clearInterval(interval);
    }
  }, [user]);
  
  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isNotificationsOpen) {
        const notificationElement = document.getElementById('notifications-dropdown');
        const notificationButton = document.getElementById('notifications-button');
        
        if (notificationElement && notificationButton) {
          if (!notificationElement.contains(event.target as Node) && 
              !notificationButton.contains(event.target as Node)) {
            setIsNotificationsOpen(false);
          }
        }
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotificationsOpen]);

  useEffect(() => {
    // Skip auth check if we're viewing special pages
    if (isPrivacyPolicyPath || isPricingPath) {
      setLoading(false);
      return;
    }

    // Check for token parameter in URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    if (token) {
      // Store token in localStorage
      localStorage.setItem('authToken', token);
      // Remove token from URL
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    
    // Check if this window was opened by our authentication process
    const authTokenFromOpener = sessionStorage.getItem('pendingAuthToken');
    if (authTokenFromOpener) {
      localStorage.setItem('authToken', authTokenFromOpener);
      sessionStorage.removeItem('pendingAuthToken');
    }
    
    checkAuth();
  }, [isPrivacyPolicyPath, isPricingPath]);

  // Effect to update contact statuses when app loads
  useEffect(() => {
    if (user && !isPrivacyPolicyPath && !isPricingPath) {
      // Update contact statuses based on settings
      api.updateContactStatuses().catch(err => {
        console.error('Failed to update contact statuses:', err);
      });
    }
  }, [user, isPrivacyPolicyPath, isPricingPath]);

  const checkAuth = async () => {
    try {
      console.log("Checking authentication...");
      
      // Try session-based auth first (works in Chrome)
      try {
        const userData = await api.getCurrentUser();
        console.log("User data from session:", userData);
        setUser(userData);
        return;
      } catch (error) {
        console.log("Session auth failed, trying token fallbacks...");
      }
      
      // Try multiple storage locations for the token
      const getStoredToken = () => {
        // Check localStorage
        const localToken = localStorage.getItem('authToken');
        if (localToken) return localToken;
        
        // Check sessionStorage
        const sessionToken = sessionStorage.getItem('authToken');
        if (sessionToken) return sessionToken;
        
        // Check cookies
        const cookieMatch = document.cookie.match(/authToken=([^;]+)/);
        if (cookieMatch) return cookieMatch[1];
        
        return null;
      };
      
      const token = getStoredToken();
      console.log("Found token:", token ? "Yes" : "No");
      
      if (token) {
        try {
          const result = await api.verifyToken(token);
          if (result.success && result.user) {
            console.log("User data from token:", result.user);
            setUser(result.user);
            return;
          }
        } catch (err) {
          console.error("Token verification failed:", err);
        }
      }
      
      // If we get here, auth failed
      console.log("All authentication methods failed");
      setUser(null);
    } catch (error) {
      console.error('Authentication failed:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    api.loginWithGoogle();
  };
  
  const fetchNotifications = async () => {
    try {
      const data = await api.getNotifications();
      setNotifications(data);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };
  
  const handleMarkNotificationAsRead = async (id: string) => {
    try {
      await api.markNotificationAsRead(id);
      // Update local state
      setNotifications(prev => 
        prev.map(n => n.id === id ? { ...n, read: true } : n)
      );
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };
  
  const handleMarkAllAsRead = async () => {
    try {
      await api.markAllNotificationsAsRead();
      // Update local state
      setNotifications(prev => prev.map(n => ({ ...n, read: true })));
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  };

  // If viewing the privacy policy, show it without any authentication checks
  if (isPricingPath) {
    return <Pricing />;
  }
  if (isPrivacyPolicyPath) {
    return <PrivacyPolicy />;
  }

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>;
  }

  // If user is not logged in, show the landing page
  if (!user) {
    return <LandingPage onLogin={handleLogin} />;
  }

  // User is logged in, show the main application
  return (
    <div className="min-h-screen flex flex-col">
      <nav className="bg-white shadow-sm z-50 sticky top-0 left-0 right-0">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 py-3">
          <div className="flex items-center justify-between">
            {/* App title - can be hidden on very small screens if needed */}
            <h1 className="text-xl font-semibold text-gray-800 hidden xs:block">Inner Circle</h1>
            
            {/* Navigation tabs - optimized for mobile */}
            <div className="flex justify-center flex-1 sm:justify-start sm:flex-none">
              <div className="flex space-x-1 sm:space-x-2">
                <button
                  onClick={() => setActiveTab('contacts')}
                  className={`flex items-center px-2 sm:px-3 py-2 rounded-lg ${
                    activeTab === 'contacts'
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <UserCircle size={20} />
                  <span className="hidden sm:inline ml-1">Contacts</span>
                </button>
                <button
                  onClick={() => setActiveTab('groups')}
                  className={`flex items-center px-2 sm:px-3 py-2 rounded-lg ${
                    activeTab === 'groups'
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <Users size={20} />
                  <span className="hidden sm:inline ml-1">Groups</span>
                </button>
                <button
                  onClick={() => setActiveTab('todos')}
                  className={`flex items-center px-2 sm:px-3 py-2 rounded-lg ${
                    activeTab === 'todos'
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <CheckSquare size={20} />
                  <span className="hidden sm:inline ml-1">TODO</span>
                </button>
                <button
                  onClick={() => setActiveTab('reminders')}
                  className={`flex items-center px-2 sm:px-3 py-2 rounded-lg ${
                    activeTab === 'reminders'
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <Bell size={20} />
                  <span className="hidden sm:inline ml-1">Reminders</span>
                </button>
                {/* Calendar Tab */}
                <button
                  onClick={() => setActiveTab('calendar')}
                  className={`flex items-center px-2 sm:px-3 py-2 rounded-lg ${
                    activeTab === 'calendar'
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <CalendarIcon size={20} />
                  <span className="hidden sm:inline ml-1">Calendar</span>
                </button>
              </div>
            </div>
            
            {/* Notifications */}
            <div className="relative">
              <button
                id="notifications-button"
                onClick={() => {
                  setIsNotificationsOpen(!isNotificationsOpen);
                  setIsMenuOpen(false); // Close other menus
                }}
                className="flex items-center p-2 hover:bg-gray-100 rounded-full relative mr-2"
                aria-label="Notifications"
              >
                <Bell size={20} />
                {notifications.filter(n => !n.read).length > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {notifications.filter(n => !n.read).length}
                  </span>
                )}
              </button>
              
              {/* Notifications Dropdown */}
              {isNotificationsOpen && (
                <div id="notifications-dropdown" className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border py-2 z-50">
                  <div className="flex justify-between items-center px-4 py-2 border-b">
                    <h3 className="font-semibold">Notifications</h3>
                    {notifications.filter(n => !n.read).length > 0 && (
                      <button 
                        onClick={handleMarkAllAsRead}
                        className="text-xs text-blue-600 hover:text-blue-800"
                      >
                        Mark all as read
                      </button>
                    )}
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    {notifications.length === 0 ? (
                      <p className="text-gray-500 text-center py-4">No notifications</p>
                    ) : (
                      notifications.map(notification => (
                        <div
                          key={notification.id}
                          className={`px-4 py-2 hover:bg-gray-50 border-b last:border-b-0 ${
                            !notification.read ? 'bg-blue-50' : ''
                          }`}
                          onClick={() => handleMarkNotificationAsRead(notification.id)}
                        >
                          <div className="flex items-start">
                            <div className={`flex-shrink-0 w-2 h-2 rounded-full mt-1.5 mr-3 ${
                              !notification.read 
                                ? notification.type === 'todo' 
                                  ? 'bg-orange-500' 
                                  : notification.type === 'birthday' 
                                    ? 'bg-purple-500' 
                                    : notification.type === 'contact_status' 
                                      ? 'bg-red-500' 
                                      : 'bg-blue-500'
                                : 'bg-gray-300'
                            }`}></div>
                            <div>
                              <p className={`text-sm ${!notification.read ? 'font-medium' : ''}`}>{notification.message}</p>
                              <p className="text-xs text-gray-500 mt-1">
                                {new Date(notification.createdAt).toLocaleString(undefined, { 
                                  month: 'short', 
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit' 
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
            
            {/* User avatar and dropdown */}
            <div className="relative">
              <button
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  setIsNotificationsOpen(false); // Close other menus
                }}
                className="flex items-center hover:bg-gray-100 rounded-lg px-2 py-2"
              >
                {user?.avatarUrl ? (
                  <img
                    src={user.avatarUrl}
                    alt={user.name}
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center">
                    {user?.name?.charAt(0)?.toUpperCase() || 'U'}
                  </div>
                )}
                <span className="font-medium hidden sm:inline ml-2">{user?.name}</span>
              </button>

              {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border py-2 z-50">
                  <div className="px-4 py-2 border-b flex justify-between items-center">
                    <h3 className="font-semibold">Account</h3>
                    <button 
                      onClick={() => setIsMenuOpen(false)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <X size={18} />
                    </button>
                  </div>
                  
                  <div className="px-4 py-3 border-b">
                    <div className="flex items-center gap-3">
                      {user?.avatarUrl ? (
                        <img
                          src={user.avatarUrl}
                          alt={user.name}
                          className="w-12 h-12 rounded-full"
                        />
                      ) : (
                        <div className="w-12 h-12 rounded-full bg-blue-500 text-white flex items-center justify-center text-xl">
                          {user?.name?.charAt(0)?.toUpperCase() || 'U'}
                        </div>
                      )}
                      <div>
                        <p className="font-medium">{user?.name}</p>
                        <p className="text-sm text-gray-500">{user?.email}</p>
                      </div>
                    </div>
                  </div>
                  
                  <button
                    onClick={() => {/* Handle profile click */}}
                    className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center gap-2"
                  >
                    <UserIcon size={16} />
                    Profile
                  </button>
                  <button
                    onClick={() => {
                      setIsMenuOpen(false);
                      setIsSettingsOpen(true);
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center gap-2"
                  >
                    <Settings size={16} />
                    Settings
                  </button>
                  <div className="border-t my-2"></div>
                  <button
                    onClick={() => {
                      setIsMenuOpen(false);
                      api.logout();
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-50 text-red-600 flex items-center gap-2"
                  >
                    <LogOut size={16} />
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <main className="flex-grow">
        {activeTab === 'contacts' ? (
          <ContactList />
        ) : activeTab === 'groups' ? (
          <GroupManager 
            contacts={[]} // Empty array as initial state - component will fetch its own data
            onUpdateContactGroups={() => {}} // Empty function as the component handles updates internally
          />
        ) : activeTab === 'todos' ? (
          <TodoList 
            contacts={[]} // Empty array as initial state - component will fetch its own data
            onUpdateContact={() => {}} // Empty function as the component handles updates internally
          />
        ) : activeTab === 'reminders' ? (
          <ActiveReminders />
        ) : activeTab === 'calendar' ? (
          <Calendar /> // Use the Calendar component
        ) : null}
      </main>

      {/* Simple footer with privacy policy */}
      <div className="w-full p-4 text-center">
        <a
          href="/privacy-policy"
          className="text-sm text-gray-500 hover:text-gray-700"
          target="_self"
        >
          Privacy Policy
        </a>
      </div>

      {/* User Settings Modal */}
      {isSettingsOpen && (
        <UserSettings onClose={() => setIsSettingsOpen(false)} />
      )}
      
      {/* Enhanced AI Assistant */}
      {user && (
        <EnhancedAIAssistant
          user={user}
          onCreateContact={async (contactData) => {
            try {
              const newContact = await api.createContact(contactData);
              return newContact;
            } catch (error) {
              console.error('Error creating contact from AI:', error);
              throw error;
            }
          }}
          onUpdateContact={async (id, contactData) => {
            try {
              const updatedContact = await api.updateContact(id, contactData);
              return updatedContact;
            } catch (error) {
              console.error('Error updating contact from AI:', error);
              throw error;
            }
          }}
          onDeleteContact={async (id) => {
            try {
              await api.deleteContact(id);
            } catch (error) {
              console.error('Error deleting contact from AI:', error);
              throw error;
            }
          }}
          onRefreshContacts={async () => {
            try {
              return await api.getContacts();
            } catch (error) {
              console.error('Error refreshing contacts from AI:', error);
              throw error;
            }
          }}
          onNavigate={(route) => {
            if (route === 'settings') {
              setIsSettingsOpen(true);
            } else {
              setActiveTab(route);
            }
          }}
        />
      )}
    </div>
  );
}

export default App;