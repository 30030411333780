import React, { useState, useEffect } from 'react';
import { X, ThermometerSun, ThermometerSnowflake, Linkedin, Facebook, MessageCircle, Twitter } from 'lucide-react';
import { Contact } from '../../types';
import GroupInput from '../groups/GroupInput';

interface EditContactModalProps {
  contact: Contact | null;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (updatedContact: Contact) => void;
  existingGroups: string[];
}

const EditContactModal: React.FC<EditContactModalProps> = ({
  contact,
  isOpen,
  onClose,
  onUpdate,
  existingGroups
}) => {
  const [formData, setFormData] = useState<Omit<Contact, 'id'>>({
    name: '',
    email: '',
    phone: '',
    groups: [],
    notes: '',
    warmth: 'warm',
    lastInteraction: new Date().toISOString().split('T')[0],
    interactions: [],
    todos: [],
    birthday: '',
    linkedin: '',
    facebook: '',
    telegram: '',
    xcom: ''
  });

  const [showSocialMedia, setShowSocialMedia] = useState(false);

  useEffect(() => {
    if (contact) {
      setFormData(contact);
      // If any social media fields have values, show the social media section
      if (contact.linkedin || contact.facebook || contact.telegram || contact.xcom) {
        setShowSocialMedia(true);
      }
      
      // Log groups data for debugging
      console.log("EditContactModal - contact groups:", contact.groups);
      console.log("EditContactModal - existingGroups:", existingGroups);
    }
  }, [contact, existingGroups]);

  // Log state changes for debugging
  useEffect(() => {
    console.log("EditContactModal - form data updated:", formData);
  }, [formData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (contact) {
      console.log("EditContactModal - submitting with groups:", formData.groups);
      onUpdate({ ...formData, id: contact.id });
      onClose();
    }
  };

  if (!isOpen || !contact) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto my-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Edit Contact</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              required
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <input
              type="tel"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Groups
            </label>
            <GroupInput
              selectedGroups={formData.groups}
              existingGroups={existingGroups}
              onChange={(groups) => setFormData({ ...formData, groups })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Birthday
            </label>
            <input
              type="date"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={formData.birthday}
              onChange={(e) => setFormData({ ...formData, birthday: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Warmth Level
            </label>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => setFormData({ ...formData, warmth: 'warm' })}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  formData.warmth === 'warm'
                    ? 'bg-orange-100 text-orange-800'
                    : 'bg-gray-100 text-gray-600 hover:bg-orange-50'
                }`}
              >
                <ThermometerSun size={20} />
                Warm
              </button>
              <button
                type="button"
                onClick={() => setFormData({ ...formData, warmth: 'cold' })}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  formData.warmth === 'cold'
                    ? 'bg-blue-100 text-blue-800'
                    : 'bg-gray-100 text-gray-600 hover:bg-blue-50'
                }`}
              >
                <ThermometerSnowflake size={20} />
                Cold
              </button>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-1">
              <label className="block text-sm font-medium text-gray-700">
                Social Media
              </label>
              <button
                type="button"
                onClick={() => setShowSocialMedia(!showSocialMedia)}
                className={`px-3 py-1 rounded-md text-sm flex items-center gap-1 ${
                  showSocialMedia 
                  ? 'bg-gray-200 text-gray-700'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                {showSocialMedia ? 'Hide' : 'Add Social Media Links'}
                {!showSocialMedia && <span>→</span>}
              </button>
            </div>
            
            {showSocialMedia && (
              <div className="space-y-3 p-3 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2">
                  <Linkedin size={18} className="text-blue-700 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="LinkedIn URL or username"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.linkedin || ''}
                    onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
                  />
                </div>
                
                <div className="flex items-center gap-2">
                  <Facebook size={18} className="text-blue-600 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="Facebook URL or username"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.facebook || ''}
                    onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
                  />
                </div>
                
                <div className="flex items-center gap-2">
                  <MessageCircle size={18} className="text-blue-400 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="Telegram username (without @)"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.telegram || ''}
                    onChange={(e) => setFormData({ ...formData, telegram: e.target.value })}
                  />
                </div>
                
                <div className="flex items-center gap-2">
                  <Twitter size={18} className="text-gray-800 flex-shrink-0" />
                  <input
                    type="text"
                    placeholder="X.com username (without @)"
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.xcom || ''}
                    onChange={(e) => setFormData({ ...formData, xcom: e.target.value })}
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notes
            </label>
            <textarea
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              rows={3}
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
            />
          </div>

          <div className="flex justify-end gap-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditContactModal;