import React, { useState } from 'react';
import { Linkedin, Facebook, MessageCircle, Twitter } from 'lucide-react';

interface SocialLinksProps {
  linkedin?: string;
  facebook?: string;
  telegram?: string;
  xcom?: string;
  onUpdate: (updates: {
    linkedin?: string;
    facebook?: string;
    telegram?: string;
    xcom?: string;
  }) => void;
}

const SocialLinks: React.FC<SocialLinksProps> = ({
  linkedin,
  facebook,
  telegram,
  xcom,
  onUpdate
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    linkedin: linkedin || '',
    facebook: facebook || '',
    telegram: telegram || '',
    xcom: xcom || ''
  });

  const handleSave = () => {
    onUpdate(formData);
    setIsEditing(false);
  };

  // Format URL correctly based on the type of social media
  const formatUrl = (type: 'linkedin' | 'facebook' | 'telegram' | 'xcom', username: string) => {
    if (!username) return '';
    
    // If it already has http/https, assume it's a full URL
    if (username.startsWith('http')) return username;
    
    switch (type) {
      case 'linkedin':
        return `https://linkedin.com/in/${username}`;
      case 'facebook':
        return `https://facebook.com/${username}`;
      case 'telegram':
        return `https://t.me/${username}`;
      case 'xcom':
        return `https://x.com/${username}`;
      default:
        return username;
    }
  };

  if (isEditing) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">Social Media</h3>
          <div className="flex gap-2">
            <button
              onClick={() => setIsEditing(false)}
              className="px-2 py-1 text-sm border rounded text-gray-600 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-2 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </div>
        
        <div className="space-y-3">
          <div className="flex items-center gap-2">
            <Linkedin size={20} className="text-blue-700 flex-shrink-0" />
            <input
              type="text"
              placeholder="LinkedIn URL or username"
              value={formData.linkedin}
              onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
              className="flex-1 px-3 py-2 border rounded-lg"
            />
          </div>
          
          <div className="flex items-center gap-2">
            <Facebook size={20} className="text-blue-600 flex-shrink-0" />
            <input
              type="text"
              placeholder="Facebook URL or username"
              value={formData.facebook}
              onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
              className="flex-1 px-3 py-2 border rounded-lg"
            />
          </div>
          
          <div className="flex items-center gap-2">
            <MessageCircle size={20} className="text-blue-400 flex-shrink-0" />
            <input
              type="text"
              placeholder="Telegram username (without @)"
              value={formData.telegram}
              onChange={(e) => setFormData({ ...formData, telegram: e.target.value })}
              className="flex-1 px-3 py-2 border rounded-lg"
            />
          </div>
          
          <div className="flex items-center gap-2">
            <Twitter size={20} className="text-gray-800 flex-shrink-0" />
            <input
              type="text"
              placeholder="X.com username (without @)"
              value={formData.xcom}
              onChange={(e) => setFormData({ ...formData, xcom: e.target.value })}
              className="flex-1 px-3 py-2 border rounded-lg"
            />
          </div>
        </div>
      </div>
    );
  }

  // Display mode with no social links
  if (!linkedin && !facebook && !telegram && !xcom) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">Social Media</h3>
          <button
            onClick={() => setIsEditing(true)}
            className="text-blue-500 text-sm hover:underline"
          >
            Add links
          </button>
        </div>
        <p className="text-gray-500 text-sm mt-2">No social media links added</p>
      </div>
    );
  }

  // Display mode with social links
  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-lg font-semibold">Social Media</h3>
        <button
          onClick={() => setIsEditing(true)}
          className="text-blue-500 text-sm hover:underline"
        >
          Edit
        </button>
      </div>
      
      <div className="space-y-2">
        {linkedin && (
          <a 
            href={formatUrl('linkedin', linkedin)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-gray-700 hover:text-blue-700"
          >
            <Linkedin size={20} className="text-blue-700" />
            <span className="hover:underline">LinkedIn</span>
          </a>
        )}
        
        {facebook && (
          <a 
            href={formatUrl('facebook', facebook)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-gray-700 hover:text-blue-600"
          >
            <Facebook size={20} className="text-blue-600" />
            <span className="hover:underline">Facebook</span>
          </a>
        )}
        
        {telegram && (
          <a 
            href={formatUrl('telegram', telegram)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-gray-700 hover:text-blue-400"
          >
            <MessageCircle size={20} className="text-blue-400" />
            <span className="hover:underline">Telegram</span>
          </a>
        )}
        
        {xcom && (
          <a 
            href={formatUrl('xcom', xcom)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-gray-700 hover:text-gray-900"
          >
            <Twitter size={20} className="text-gray-800" />
            <span className="hover:underline">X.com</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default SocialLinks;