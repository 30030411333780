import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { 
  Send, Minimize, Maximize, X, Bot, Loader, User, Mic, MicOff, 
  Volume2, Expand, Shrink, Columns, TerminalSquare, GripVertical, GripHorizontal 
} from 'lucide-react';
import { User as UserType, Contact } from '../../types';
import { api } from '../../services/api';
import './EnhancedAIAssistant.css';

// CSS classes for the layout container approach
const LAYOUT_CONTAINER_ID = 'ai-assistant-layout-container';

interface Message {
  id: string;
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

interface SuggestedPrompt {
  id: string;
  text: string;
  icon?: React.ReactNode;
}

export type ViewType = 'terminal' | 'split' | 'closed';

interface EnhancedAIAssistantProps {
  user: UserType;
  onCreateContact?: (contactData: Omit<Contact, 'id'>) => Promise<Contact>;
  onUpdateContact?: (id: number, contactData: Partial<Contact>) => Promise<Contact>;
  onDeleteContact?: (id: number) => Promise<void>;
  onRefreshContacts?: () => Promise<void>;
  onNavigate?: (route: 'contacts' | 'groups' | 'todos' | 'settings') => void;
}

const EnhancedAIAssistant: React.FC<EnhancedAIAssistantProps> = ({
  user,
  onCreateContact,
  onUpdateContact,
  onDeleteContact,
  onRefreshContacts,
  onNavigate
}) => {
  // Load view preference from localStorage
  const getInitialViewType = (): ViewType => {
    const savedView = localStorage.getItem('aiAssistantViewType');
    return (savedView as ViewType) || 'closed';
  };

  const getInitialTerminalHeight = (): string => {
    const savedHeight = localStorage.getItem('aiAssistantTerminalHeight');
    return savedHeight || '120px';
  };

  const getInitialSplitWidth = (): string => {
    const savedWidth = localStorage.getItem('aiAssistantSplitWidth');
    return savedWidth || '350px';
  };

  // State for view configuration
  const [viewType, setViewType] = useState<ViewType>(getInitialViewType());
  const [terminalHeight, setTerminalHeight] = useState<string>(getInitialTerminalHeight());
  const [isTerminalExpanded, setIsTerminalExpanded] = useState<boolean>(false);
  const [splitWidth, setSplitWidth] = useState<string>(getInitialSplitWidth());
  const [isResizingTerminal, setIsResizingTerminal] = useState<boolean>(false);
  const [isResizingSplit, setIsResizingSplit] = useState<boolean>(false);
  const resizeStartY = useRef<number>(0);
  const resizeStartX = useRef<number>(0);
  const initialHeight = useRef<number>(0);
  const initialWidth = useRef<number>(0);

  // Chat state
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '1',
      role: 'assistant',
      content: `Hi ${user.name}, I'm your Inner Circle assistant. What would you like to do today?`,
      timestamp: new Date()
    }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  // Voice recording states
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessingSpeech, setIsProcessingSpeech] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  
  // Contact suggestion state
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isContactsLoading, setIsContactsLoading] = useState(false);

  // Suggested prompts
  const [suggestedPrompts, setSuggestedPrompts] = useState<SuggestedPrompt[]>([
    { id: '1', text: 'Who have I not contacted recently?' },
    { id: '2', text: 'What upcoming birthdays do I have?' },
    { id: '3', text: 'Help me plan a meetup with my friends' },
  ]);
  
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const terminalRef = useRef<HTMLDivElement>(null);
  const splitRef = useRef<HTMLDivElement>(null);
  
  // Persist view settings to localStorage
  useEffect(() => {
    localStorage.setItem('aiAssistantViewType', viewType);
    
    // If the view is changing to terminal or split, save it as the last active view
    if (viewType !== 'closed') {
      localStorage.setItem('aiAssistantLastActiveView', viewType);
    }
    
    // Apply body classes to create proper layout  
    // First, remove all AI assistant layout classes
    document.body.classList.remove('ai-terminal-active', 'ai-side-active');
    
    // Set CSS variables based on current dimensions
    document.documentElement.style.setProperty('--ai-terminal-height', isTerminalExpanded ? '40vh' : terminalHeight);
    document.documentElement.style.setProperty('--ai-side-width', splitWidth);
    
    // Apply appropriate class based on current view
    if (viewType === 'terminal') {
      document.body.classList.add('ai-terminal-active');
      
      // The CSS variable will be used to control how much of the main app is visible
      document.documentElement.style.setProperty('--ai-terminal-height', isTerminalExpanded ? '40vh' : terminalHeight);
    } else if (viewType === 'split') {
      document.body.classList.add('ai-side-active');
      
      // Very simple approach - just set CSS variables
      document.documentElement.style.setProperty('--ai-side-width', splitWidth);
      
      // Make sure we have the navbar height
      const navbar = document.querySelector('nav, header, .navbar, .header');
      if (navbar) {
        const navbarHeight = navbar.getBoundingClientRect().height;
        document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
      } else {
        // Use a reasonable default if we can't find the navbar
        document.documentElement.style.setProperty('--navbar-height', '60px');
      }
      
      // No cleanup needed
      return () => {};
    }
  }, [viewType]);

  useEffect(() => {
    localStorage.setItem('aiAssistantTerminalHeight', terminalHeight);
    
    // Update CSS variable when terminal height changes
    if (viewType === 'terminal') {
      document.documentElement.style.setProperty('--ai-terminal-height', isTerminalExpanded ? '40vh' : terminalHeight);
    }
  }, [terminalHeight, viewType]);

  useEffect(() => {
    localStorage.setItem('aiAssistantSplitWidth', splitWidth);
    
    // Update CSS variable when split width changes
    if (viewType === 'split') {
      document.documentElement.style.setProperty('--ai-side-width', splitWidth);
    }
  }, [splitWidth, viewType]);

  // Enhanced navbar detection and setup on mount
  useEffect(() => {
    // Find the navbar by using multiple common selectors
    const navbarSelectors = [
      'nav', 'header', '.navbar', '.header', '.top-bar',
      '.navigation', '.app-header', '.main-header', '#navbar', '#header'
    ];
    
    // Try each selector in order
    let navbar = null;
    for (const selector of navbarSelectors) {
      const element = document.querySelector(selector);
      if (element) {
        navbar = element;
        break;
      }
    }
    
    // If we found a navbar, get its height and set CSS variable
    if (navbar) {
      const navbarHeight = navbar.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
      
      // Add an additional class to the navbar for our styles to target
      navbar.classList.add('ic-navbar');
      
      // Find and mark common navbar components for styling
      const userElements = navbar.querySelectorAll('.avatar, .user-avatar, .user-menu, [class*="user"], [class*="avatar"]');
      userElements.forEach(el => el.classList.add('ic-navbar-user'));
      
      const notificationElements = navbar.querySelectorAll('.notifications, .notification-icon, [class*="notif"]');
      notificationElements.forEach(el => el.classList.add('ic-navbar-notification'));
      
      // Find right-aligned elements
      const rightAlignedElements = navbar.querySelectorAll('[class*="right"]');
      rightAlignedElements.forEach(el => el.classList.add('ic-navbar-right'));
    }
    
    // Fetch contacts if needed
    if (viewType !== 'closed') {
      fetchContacts();
    }
  }, [viewType]);
  
  // Handle terminal and split resizing
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isResizingTerminal && terminalRef.current) {
        // Use requestAnimationFrame for smoother performance
        requestAnimationFrame(() => {
          if (terminalRef.current) {
            const deltaY = resizeStartY.current - e.clientY;
            const newHeight = Math.min(
              Math.max(120, initialHeight.current + deltaY),
              window.innerHeight * 0.8
            );
            
            // Direct style update for immediacy
            terminalRef.current.style.height = `${newHeight}px`;
            
            // Also update state for persistence
            setTerminalHeight(`${newHeight}px`);
          }
        });
      }

      if (isResizingSplit && splitRef.current) {
        const deltaX = resizeStartX.current - e.clientX;
        const containerWidth = document.documentElement.clientWidth;
        const newWidth = Math.min(
          Math.max(280, initialWidth.current + deltaX),
          containerWidth - 300
        );
        // Direct style update
        splitRef.current.style.width = `${newWidth}px`;
        setSplitWidth(`${newWidth}px`);
      }
    };

    const handleMouseUp = () => {
      setIsResizingTerminal(false);
      setIsResizingSplit(false);
    };

    if (isResizingTerminal || isResizingSplit) {
      // Add the passive: false option to prevent browser delays
      document.addEventListener('mousemove', handleMouseMove, { passive: false });
      document.addEventListener('mouseup', handleMouseUp);
      
      // Disable text selection during resize for better performance
      document.body.style.userSelect = 'none';
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      
      // Reset text selection
      document.body.style.userSelect = '';
    };
  }, [isResizingTerminal, isResizingSplit]);

  // Detect if user is typing a name and show suggestions
  useEffect(() => {
    // Check if user is typing something that might be a name
    const words = inputValue.split(/\s+/);
    const lastWord = words[words.length - 1]?.toLowerCase();
    
    if (lastWord && lastWord.length >= 2) {
      // Filter contacts based on the last word
      const filtered = contacts.filter(contact => 
        contact.name.toLowerCase().includes(lastWord)
      );
      
      setFilteredContacts(filtered);
      setShowSuggestions(filtered.length > 0);
    } else {
      setShowSuggestions(false);
    }
  }, [inputValue, contacts]);
  
  // Close suggestions when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node) &&
          inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Scroll to bottom of messages when new messages are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Focus input when chat is opened
  useEffect(() => {
    if (viewType !== 'closed' && inputRef.current) {
      inputRef.current.focus();
    }
  }, [viewType]);
  
  // Focus input after the AI responds
  useEffect(() => {
    if (messages.length > 0 && 
        messages[messages.length - 1].role === 'assistant' && 
        inputRef.current && 
        !isLoading) {
      // Short timeout to ensure DOM is updated
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [messages, isLoading]);
  
  // Clean up media recorder on unmount
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
      }
    };
  }, [isRecording]);
  
  // Clean up app layout classes and restore original state on unmount
  useEffect(() => {
    // Keep track of all elements we modified
    const modifiedElements: HTMLElement[] = [];
    
    // Function to reset an element's styles
    const resetElementStyles = (element: HTMLElement) => {
      if (element) {
        // Store the element to reset on unmount
        modifiedElements.push(element);
      }
    };
    
    // Add modified elements to our tracking
    document.querySelectorAll('button, a, h1, h2, h3, [class*="header"], [class*="title"], [class*="add"], [class*="import"]')
      .forEach(el => resetElementStyles(el as HTMLElement));
    
    return () => {
      // Remove all AI assistant layout classes from body
      document.body.classList.remove('ai-terminal-active', 'ai-side-active');
      
      // Reset CSS variables
      document.documentElement.style.removeProperty('--ai-terminal-height');
      document.documentElement.style.removeProperty('--ai-side-width');
      document.documentElement.style.removeProperty('--navbar-height');
      
      // Remove all custom classes we added to navbar elements
      const navbarElements = document.querySelectorAll('.ic-navbar, .ic-navbar-user, .ic-navbar-notification, .ic-navbar-right');
      navbarElements.forEach(el => {
        el.classList.remove('ic-navbar', 'ic-navbar-user', 'ic-navbar-notification', 'ic-navbar-right');
      });
      
      // Reset inline styles we added to elements
      modifiedElements.forEach(el => {
        if (el) {
          el.style.visibility = '';
          el.style.display = '';
          el.style.opacity = '';
          el.style.position = '';
          el.style.maxWidth = '';
          el.style.width = '';
          el.style.overflow = '';
          el.style.zIndex = '';
          el.style.marginLeft = '';
          el.style.paddingLeft = '';
          el.style.flexWrap = '';
          el.style.alignItems = '';
          el.style.gap = '';
          el.style.padding = '';
        }
      });
    };
  }, []);

  // Generate contextual suggested prompts based on active view/route
  useEffect(() => {
    // This would be enhanced in a real implementation to use the actual current route/view
    const updatedPrompts: SuggestedPrompt[] = [
      { id: '1', text: 'Who have I not contacted recently?' },
      { id: '2', text: 'What upcoming birthdays do I have?' },
      { id: '3', text: 'Help me plan a meetup with my friends' },
    ];
    
    setSuggestedPrompts(updatedPrompts);
  }, [/* dependencies would include current route */]);
  
  const fetchContacts = async () => {
    setIsContactsLoading(true);
    try {
      const data = await api.getContacts();
      setContacts(data);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    } finally {
      setIsContactsLoading(false);
    }
  };
  
  // Start audio recording
  const startRecording = async () => {
    try {
      console.log('Starting audio recording');
      
      // Request access to microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Got microphone access');
      
      // Determine if we're on Safari/iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      
      let mediaRecorder;
      
      // For Safari/iOS
      if (isIOS || isSafari) {
        try {
          mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/mp4' });
        } catch (e) {
          console.log('Safari rejected audio/mp4, using default');
          mediaRecorder = new MediaRecorder(stream);
        }
      } 
      // For other browsers, prefer webm
      else {
        try {
          mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        } catch (e) {
          console.log('WebM not supported, using default');
          mediaRecorder = new MediaRecorder(stream);
        }
      }
      
      console.log(`MediaRecorder created with mimeType: ${mediaRecorder.mimeType}`);
      
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];
      
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log(`Received audio chunk of size: ${event.data.size} bytes`);
          audioChunksRef.current.push(event.data);
        }
      };
      
      mediaRecorder.onstop = () => {
        console.log('Recording stopped');
        const audioBlob = new Blob(audioChunksRef.current, { type: mediaRecorder.mimeType });
        console.log(`Created audio blob of size: ${audioBlob.size} bytes, type: ${audioBlob.type}`);
        setAudioBlob(audioBlob);
        
        stream.getTracks().forEach(track => track.stop());
        processAudioWithWhisper(audioBlob);
      };
      
      // Start recording - capture first 30 seconds max
      mediaRecorder.start(1000);
      console.log('MediaRecorder started');
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
      alert('Could not access microphone. Please check your permissions.');
    }
  };
  
  // Stop audio recording
  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsProcessingSpeech(true);
    }
  };
  
  // Toggle audio recording
  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };
  
  // Process audio with OpenAI Whisper API
  const processAudioWithWhisper = async (audioBlob: Blob) => {
    try {
      console.log(`Processing audio - size: ${audioBlob.size} bytes, type: ${audioBlob.type}`);
      setIsProcessingSpeech(true);
      
      // Determine the appropriate file extension based on the actual MIME type
      let fileExtension = '.webm';  // Default
      const mimeType = audioBlob.type.toLowerCase();
      
      if (mimeType.includes('mp4')) {
        fileExtension = '.m4a';  // Safari's audio/mp4 should use .m4a extension
      } else if (mimeType.includes('mpeg') || mimeType.includes('mp3')) {
        fileExtension = '.mp3';
      } else if (mimeType.includes('wav')) {
        fileExtension = '.wav';
      } else if (mimeType.includes('ogg')) {
        fileExtension = '.ogg';
      }
      
      console.log(`Using file extension: ${fileExtension} for MIME type: ${mimeType}`);
      console.log(`Audio blob MIME type: ${audioBlob.type}, File extension: ${fileExtension}`);
      // Create form data with the audio file - using appropriate extension
      const formData = new FormData();
      formData.append('file', audioBlob, `recording${fileExtension}`);
      
      console.log(`Sending audio file to server as recording${fileExtension}`);
      
      // Send to our backend
      const response = await fetch('/api/speech-to-text', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });
      
      console.log(`Server response status: ${response.status}`);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Speech to text error response:', errorText);
        throw new Error(`Speech to text failed with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Speech to text result:', data);
      
      // Set the transcribed text to the input field
      setInputValue(data.text);
      
      // Focus on the input field
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } catch (error) {
      console.error('Error processing speech:', error);
      alert('Failed to process speech. Please try again.');
    } finally {
      setIsProcessingSpeech(false);
    }
  };
  
  const handleContactSelect = (contact: Contact) => {
    // Get the current cursor position in the input
    const cursorPosition = inputRef.current?.selectionStart || inputValue.length;
    
    // Split the input text at the cursor position
    const textBeforeCursor = inputValue.substring(0, cursorPosition);
    const textAfterCursor = inputValue.substring(cursorPosition);
    
    // Find the last word before the cursor
    const wordsBeforeCursor = textBeforeCursor.split(/\s+/);
    const lastWordIndex = textBeforeCursor.lastIndexOf(wordsBeforeCursor[wordsBeforeCursor.length - 1]);
    
    // Replace the last word with the contact reference
    const newText = 
      textBeforeCursor.substring(0, lastWordIndex) + 
      `@${contact.name}` + 
      (textBeforeCursor.endsWith(' ') ? '' : ' ') +
      textAfterCursor;
    
    // Update the input value
    setInputValue(newText);
    setShowSuggestions(false);
    
    // Focus on the input field and place cursor after the inserted text
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const newCursorPosition = lastWordIndex + `@${contact.name}`.length + 1;
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }, 10);
  };
  
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;
    
    // If recording, stop it
    if (isRecording) {
      stopRecording();
      return; // Will continue after processing finishes
    }
    
    const newUserMessage: Message = {
      id: Date.now().toString(),
      role: 'user',
      content: inputValue,
      timestamp: new Date()
    };
    
    setMessages(prev => [...prev, newUserMessage]);
    setInputValue('');
    setIsLoading(true);
    
    try {
      // Get app context to send to the AI
      const context = await getAppContext();
      
      // Process the message with your AI service
      const response = await processWithAI(newUserMessage.content, context);
      
      // Execute any actions from the AI response
      if (response.actions && response.actions.length > 0) {
        try {
          const actionResponse = await fetch('/api/ai/execute-actions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              actions: response.actions
            }),
            credentials: 'include'
          });
          
          if (!actionResponse.ok) {
            throw new Error(`Failed to execute actions: ${actionResponse.status}`);
          }
          
          const actionResult = await actionResponse.json();
          console.log('Actions executed:', actionResult);
          
          // You might want to refresh your data after executing actions
          if (onRefreshContacts) {
            await onRefreshContacts();
          }
        } catch (error) {
          console.error('Error executing actions:', error);
        }
      }
      
      // Add the AI's response to the chat
      const newAssistantMessage: Message = {
        id: (Date.now() + 1).toString(),
        role: 'assistant',
        content: response.reply,
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, newAssistantMessage]);
    } catch (error) {
      console.error('Error processing message with AI:', error);
      
      // Add error message
      const errorMessage: Message = {
        id: (Date.now() + 1).toString(),
        role: 'assistant',
        content: 'Sorry, I encountered an error processing your request. Please try again.',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getAppContext = async () => {
    // Get relevant data from the app to provide context to the AI
    try {
      // Fetch more detailed contact data (to help the AI remember existing contacts)
      const contacts = await api.getContacts();
      
      // Get all todos and interactions for each contact - create promises for parallel fetching
      const todoPromises = contacts.map((contact: Contact) => 
        api.getTodos(contact.id)
          .catch(err => {
            console.error(`Error fetching todos for contact ${contact.id}:`, err);
            return []; // Return empty array if there's an error
          })
      );
      
      const interactionPromises = contacts.map((contact: Contact) => 
        api.getInteractions(contact.id)
          .catch(err => {
            console.error(`Error fetching interactions for contact ${contact.id}:`, err);
            return []; // Return empty array if there's an error
          })
      );
      
      // Wait for all requests to complete
      const todosResults = await Promise.all(todoPromises);
      const interactionsResults = await Promise.all(interactionPromises);
      
      // Create maps of contact ID to todos and interactions
      const todosByContactId: Record<number, any[]> = {};
      const interactionsByContactId: Record<number, any[]> = {};
      
      contacts.forEach((contact: Contact, index: number) => {
        todosByContactId[contact.id] = todosResults[index] || [];
        interactionsByContactId[contact.id] = interactionsResults[index] || [];
      });
      
      // Create the context with todos and interactions included
      const contactsContext = contacts.map((c: Contact) => ({
        id: c.id,
        name: c.name,
        email: c.email || "",
        phone: c.phone || "",
        warmth: c.warmth,
        groups: c.groups,
        telegram: c.telegram || "",
        facebook: c.facebook || "",
        linkedin: c.linkedin || "",
        xcom: c.xcom || "",
        lastInteraction: c.lastInteraction,
        birthday: c.birthday, // Include birthday information
        // Include active todos for this contact
        todos: todosByContactId[c.id]
          .filter(todo => !todo.completed) // Only include incomplete todos
          .map(todo => ({
            id: todo.id,
            task: todo.task,
            dueDate: todo.dueDate,
            completed: todo.completed
          })),
        // Include interactions for this contact
        interactions: interactionsByContactId[c.id]
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) // Sort newest first
          .slice(0, 5) // Only include the 5 most recent interactions
          .map(interaction => ({
            id: interaction.id,
            type: interaction.type,
            date: interaction.date,
            notes: interaction.notes
          }))
      }));
      
      // Add other context as needed
      return {
        user: {
          id: user.id,
          name: user.name
        },
        contacts: contactsContext,
        currentDate: new Date().toISOString()
      };
    } catch (error) {
      console.error('Error getting app context:', error);
      return {
        user: {
          id: user.id,
          name: user.name
        },
        currentDate: new Date().toISOString()
      };
    }
  };
  
  const processWithAI = async (message: string, context: any) => {
    // Call your AI service (ChatGPT API)
    try {
      const response = await fetch('/api/ai/process', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message,
          context,
          history: messages.map(m => ({
            role: m.role,
            content: m.content
          }))
        }),
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error(`AI request failed with status ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error calling AI service:', error);
      throw error;
    }
  };
  
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (showSuggestions && filteredContacts.length > 0) {
        // Select the first suggestion when pressing Enter
        handleContactSelect(filteredContacts[0]);
      } else {
        handleSendMessage();
      }
    } else if (e.key === 'Escape') {
      setShowSuggestions(false);
    } else if (e.key === 'ArrowDown' && showSuggestions) {
      // Could implement keyboard navigation through suggestions
      e.preventDefault();
    } else if (e.key === 'ArrowUp' && showSuggestions) {
      // Could implement keyboard navigation through suggestions
      e.preventDefault();
    }
  };
  
  // Handle view transitions
  const toggleChatOpen = () => {
    if (viewType === 'closed') {
      // Get the last active view before closing
      const lastActiveView = localStorage.getItem('aiAssistantLastActiveView') as ViewType;
      // Default to terminal if no last active view is saved
      setViewType(lastActiveView || 'terminal');
    } else {
      // Save the current view type before closing
      localStorage.setItem('aiAssistantLastActiveView', viewType);
      setViewType('closed');
    }
  };

  const switchToTerminalView = () => {
    setViewType('terminal');
  };

  const switchToSplitView = () => {
    setViewType('split');
  };

  const toggleTerminalExpand = () => {
    setIsTerminalExpanded(!isTerminalExpanded);
  };

  const startResizingTerminal = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizingTerminal(true);
    resizeStartY.current = e.clientY;
    initialHeight.current = terminalRef.current?.offsetHeight || 0;
  };

  const startResizingSplit = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizingSplit(true);
    resizeStartX.current = e.clientX;
    initialWidth.current = splitRef.current?.offsetWidth || 0;
  };

  const handlePromptClick = (promptText: string) => {
    setInputValue(promptText);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Exit early if the view is closed
  if (viewType === 'closed') {
    return (
      <div className="fixed bottom-4 right-4 flex flex-col gap-2 z-40">
        {/* Emergency reset button - in case the main UI is lost */}
        <button
          onClick={() => {
            // Get the last active view or default to terminal
            const lastActiveView = localStorage.getItem('aiAssistantLastActiveView') as ViewType || 'terminal';
            localStorage.setItem('aiAssistantViewType', lastActiveView);
            setViewType(lastActiveView);
          }}
          className="bg-gray-200 text-gray-600 p-2 rounded-full shadow-lg hover:bg-gray-300 transition-colors"
          aria-label="Reset AI Assistant View"
          title="Reset AI View"
        >
          <TerminalSquare size={16} />
        </button>
        
        {/* Main chat open button */}
        <button
          onClick={toggleChatOpen}
          className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition-colors"
          aria-label="Open AI Assistant"
        >
          <Bot size={24} />
        </button>
      </div>
    );
  }

  // Render the chat interface based on the view type
  if (viewType === 'terminal') {
    return (
      <div 
        ref={terminalRef}
        className={`ai-terminal-panel z-50 ${
          isResizingTerminal ? '' : 'transition-all duration-300'
        }`}
        style={{ 
          height: isTerminalExpanded ? '40vh' : terminalHeight, 
        }}
      >
        {/* Terminal header - slim design */}
        <div className="flex items-center justify-between bg-gray-100 text-gray-700 px-2 py-1 border-b border-gray-200">
          <div className="flex items-center gap-1">
            <Bot size={14} className="text-blue-500" />
            <h3 className="text-xs font-medium">AI</h3>
          </div>
          <div className="flex items-center gap-1">
            {/* View toggle buttons - compact icons */}
            <button
              onClick={switchToTerminalView}
              className={`p-1 rounded transition-colors ${
                viewType === ('terminal' as ViewType) ? 'bg-blue-100 text-blue-600' : 'text-gray-500 hover:bg-gray-200'
              }`}
              aria-label="Terminal View"
              title="Terminal View"
            >
              <TerminalSquare size={14} />
            </button>
            <button
              onClick={switchToSplitView}
              className={`p-1 rounded transition-colors ${
                viewType === ('split' as ViewType) ? 'bg-blue-100 text-blue-600' : 'text-gray-500 hover:bg-gray-200'
              }`}
              aria-label="Split View"
              title="Side View"
            >
              <Columns size={14} />
            </button>
            <button
              onClick={toggleTerminalExpand}
              className="p-1 text-gray-500 hover:bg-gray-200 rounded"
              aria-label={isTerminalExpanded ? 'Collapse' : 'Expand'}
              title={isTerminalExpanded ? 'Collapse' : 'Expand'}
            >
              {isTerminalExpanded ? <Minimize size={14} /> : <Maximize size={14} />}
            </button>
            <button
              onClick={toggleChatOpen}
              className="p-1 text-gray-500 hover:bg-gray-200 rounded"
              aria-label="Close"
              title="Close"
            >
              <X size={14} />
            </button>
          </div>
        </div>
        
        {/* Resizing handle - full width and taller for better responsiveness */}
        <div 
          className="absolute top-0 left-0 right-0 h-3 bg-transparent cursor-ns-resize flex items-center justify-center"
          onMouseDown={startResizingTerminal}
        >
          <div className="w-full px-4 flex items-center justify-center">
            <div className="w-20 h-1 bg-gray-300 hover:bg-blue-400 rounded-full flex-shrink-0"></div>
          </div>
        </div>

        {/* Chat messages */}
        <div className="p-3 overflow-y-auto" style={{ height: 'calc(100% - 120px)' }}>
          {messages.map(message => (
            <div
              key={message.id}
              className={`mb-3 ${
                message.role === 'user' ? 'text-right' : 'text-left'
              }`}
            >
              <div
                className={`inline-block px-3 py-2 rounded-lg ${
                  message.role === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-800'
                }`}
              >
                {message.role === 'assistant' ? (
                  <div className="whitespace-pre-line" dangerouslySetInnerHTML={{ 
                    __html: message.content
                      .replace(/\*\*(.*?)\*\*/g, '<strong class="text-blue-600">$1</strong>')
                      .replace(/\n- /g, '<br/><span class="text-blue-500">•</span> ')
                      .replace(/\n(\d+)\. /g, '<br/><span class="text-purple-500">$1.</span> ')
                      .replace(/✓/g, '<span class="text-green-600">✓</span>')
                      .replace(/\(due: (.*?)\)/g, '(<span class="text-orange-500">due: $1</span>)')
                      .replace(/(March|April|May|June|July|August|September|October|November|December|January|February) \d{1,2}, \d{4}/g, '<span class="text-indigo-500">$&</span>')
                      .replace(/\n/g, '<br/>')
                  }} />
                ) : (
                  message.content
                )}
              </div>
              <div className="text-xs text-gray-500 mt-1">
                {message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="flex items-center gap-2 text-gray-500 text-sm">
              <Loader size={16} className="animate-spin" />
              <span>Thinking...</span>
            </div>
          )}
          {isProcessingSpeech && (
            <div className="flex items-center gap-2 text-blue-500 text-sm">
              <Loader size={16} className="animate-spin" />
              <span>Processing speech...</span>
            </div>
          )}
          {isRecording && (
            <div className="flex items-center gap-2 text-red-500 text-sm">
              <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
              </span>
              <span>Recording... Click mic again to stop</span>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        
        
        {/* Chat input */}
        <div className="p-3 border-t relative">
          <div className="flex items-center gap-2">
            <div className="flex-1 relative">
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Ask me anything about your contacts or relationships..."
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                disabled={isLoading || isRecording || isProcessingSpeech}
              />
              
              {/* Contact suggestions dropdown */}
              {showSuggestions && (
                <div 
                  ref={suggestionsRef}
                  className="absolute left-0 right-0 bottom-full mb-1 bg-white border rounded-lg shadow-lg z-10 max-h-60 overflow-y-auto"
                >
                  {isContactsLoading ? (
                    <div className="p-2 text-center text-gray-500">
                      <Loader size={16} className="animate-spin inline-block mr-2" />
                      Loading contacts...
                    </div>
                  ) : filteredContacts.length > 0 ? (
                    filteredContacts.map((contact) => (
                      <button
                        key={contact.id}
                        onClick={() => handleContactSelect(contact)}
                        className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center gap-2"
                      >
                        <div className="w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-xs">
                          {contact.name.charAt(0).toUpperCase()}
                        </div>
                        <div className="flex-1">
                          <div>{contact.name}</div>
                          <div className="text-xs text-gray-500">
                            {contact.email || contact.phone || 'No contact details'}
                          </div>
                        </div>
                      </button>
                    ))
                  ) : (
                    <div className="p-2 text-center text-gray-500">
                      No contacts found
                    </div>
                  )}
                </div>
              )}
            </div>
            
            {/* Microphone button */}
            <button
              onClick={toggleRecording}
              className={`p-2 rounded-lg ${
                isRecording 
                  ? 'bg-red-500 text-white hover:bg-red-600' 
                  : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
              }`}
              disabled={isLoading || isProcessingSpeech}
              aria-label={isRecording ? "Stop recording" : "Start voice recording"}
            >
              {isRecording ? <MicOff size={20} /> : <Mic size={20} />}
            </button>
            
            <button
              onClick={handleSendMessage}
              disabled={isLoading || (!inputValue.trim() && !isRecording) || isProcessingSpeech}
              className={`p-2 rounded-lg ${
                isLoading || (!inputValue.trim() && !isRecording) || isProcessingSpeech
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
              aria-label="Send message"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Split view
  return (
    <>
      {/* Resizer handle for side panel - uses CSS variable for navbar height */}
      <div 
        className="fixed bottom-0 w-1 bg-gray-200 hover:bg-blue-400 cursor-ew-resize z-50 transition-colors"
        style={{ 
          right: `calc(${splitWidth} - 1px)`,
          top: 'var(--navbar-height, 60px)'
        }}
        onMouseDown={startResizingSplit}
      ></div>
      
      {/* AI panel - positioned on the right side with top offset for navbar */}
      <div 
        ref={splitRef}
        className="ai-side-panel flex flex-col overflow-hidden z-40"
        style={{ 
          width: splitWidth,
          top: 'var(--navbar-height, 60px)'  
        }}
      >
        {/* Panel header - slim design */}
        <div className="flex items-center justify-between bg-gray-100 text-gray-700 px-2 py-1 border-b border-gray-200 sticky top-0">
          <div className="flex items-center gap-1">
            <Bot size={14} className="text-blue-500" />
            <h3 className="text-xs font-medium">AI</h3>
          </div>
          <div className="flex items-center gap-1">
            {/* View toggle buttons - compact icons */}
            <button
              onClick={switchToTerminalView}
              className={`p-1 rounded transition-colors ${
                viewType === ('terminal' as ViewType) ? 'bg-blue-100 text-blue-600' : 'text-gray-500 hover:bg-gray-200'
              }`}
              aria-label="Terminal View"
              title="Terminal View"
            >
              <TerminalSquare size={14} />
            </button>
            <button
              onClick={switchToSplitView}
              className={`p-1 rounded transition-colors ${
                viewType === ('split' as ViewType) ? 'bg-blue-100 text-blue-600' : 'text-gray-500 hover:bg-gray-200'
              }`}
              aria-label="Split View"
              title="Side View"
            >
              <Columns size={14} />
            </button>
            <button
              onClick={toggleChatOpen}
              className="p-1 text-gray-500 hover:bg-gray-200 rounded"
              aria-label="Close"
              title="Close"
            >
              <X size={14} />
            </button>
          </div>
        </div>
        
        {/* Chat messages */}
        <div className="flex-1 p-4 overflow-y-auto">
          {messages.map(message => (
            <div
              key={message.id}
              className={`mb-4 ${
                message.role === 'user' ? 'text-right' : 'text-left'
              }`}
            >
              <div
                className={`inline-block px-4 py-3 rounded-lg ${
                  message.role === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-800'
                }`}
              >
                {message.role === 'assistant' ? (
                  <div className="whitespace-pre-line" dangerouslySetInnerHTML={{ 
                    __html: message.content
                      .replace(/\*\*(.*?)\*\*/g, '<strong class="text-blue-600">$1</strong>')
                      .replace(/\n- /g, '<br/><span class="text-blue-500">•</span> ')
                      .replace(/\n(\d+)\. /g, '<br/><span class="text-purple-500">$1.</span> ')
                      .replace(/✓/g, '<span class="text-green-600">✓</span>')
                      .replace(/\(due: (.*?)\)/g, '(<span class="text-orange-500">due: $1</span>)')
                      .replace(/(March|April|May|June|July|August|September|October|November|December|January|February) \d{1,2}, \d{4}/g, '<span class="text-indigo-500">$&</span>')
                      .replace(/\n/g, '<br/>')
                  }} />
                ) : (
                  message.content
                )}
              </div>
              <div className="text-xs text-gray-500 mt-1">
                {message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="flex items-center gap-2 text-gray-500 text-sm mb-4">
              <Loader size={16} className="animate-spin" />
              <span>Thinking...</span>
            </div>
          )}
          {isProcessingSpeech && (
            <div className="flex items-center gap-2 text-blue-500 text-sm mb-4">
              <Loader size={16} className="animate-spin" />
              <span>Processing speech...</span>
            </div>
          )}
          {isRecording && (
            <div className="flex items-center gap-2 text-red-500 text-sm mb-4">
              <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
              </span>
              <span>Recording... Click mic again to stop</span>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        
        
        {/* Chat input */}
        <div className="p-4 border-t">
          <div className="flex items-center gap-2">
            <div className="flex-1 relative">
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Ask me anything about your contacts or relationships..."
                className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                disabled={isLoading || isRecording || isProcessingSpeech}
              />
              
              {/* Contact suggestions dropdown */}
              {showSuggestions && (
                <div 
                  ref={suggestionsRef}
                  className="absolute left-0 right-0 bottom-full mb-1 bg-white border rounded-lg shadow-lg z-10 max-h-60 overflow-y-auto"
                >
                  {isContactsLoading ? (
                    <div className="p-2 text-center text-gray-500">
                      <Loader size={16} className="animate-spin inline-block mr-2" />
                      Loading contacts...
                    </div>
                  ) : filteredContacts.length > 0 ? (
                    filteredContacts.map((contact) => (
                      <button
                        key={contact.id}
                        onClick={() => handleContactSelect(contact)}
                        className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center gap-2"
                      >
                        <div className="w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-xs">
                          {contact.name.charAt(0).toUpperCase()}
                        </div>
                        <div className="flex-1">
                          <div>{contact.name}</div>
                          <div className="text-xs text-gray-500">
                            {contact.email || contact.phone || 'No contact details'}
                          </div>
                        </div>
                      </button>
                    ))
                  ) : (
                    <div className="p-2 text-center text-gray-500">
                      No contacts found
                    </div>
                  )}
                </div>
              )}
            </div>
            
            {/* Microphone button */}
            <button
              onClick={toggleRecording}
              className={`p-2 rounded-lg ${
                isRecording 
                  ? 'bg-red-500 text-white hover:bg-red-600' 
                  : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
              }`}
              disabled={isLoading || isProcessingSpeech}
              aria-label={isRecording ? "Stop recording" : "Start voice recording"}
            >
              {isRecording ? <MicOff size={20} /> : <Mic size={20} />}
            </button>
            
            <button
              onClick={handleSendMessage}
              disabled={isLoading || (!inputValue.trim() && !isRecording) || isProcessingSpeech}
              className={`p-2 rounded-lg ${
                isLoading || (!inputValue.trim() && !isRecording) || isProcessingSpeech
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
              aria-label="Send message"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnhancedAIAssistant;