import { Contact } from '../types';

// Function to check if a contact should be cold based on threshold days
export const shouldBeCold = (
  contact: Contact, 
  thresholdDays: number
): boolean => {
  // Parse the last interaction date
  const lastInteractionDate = new Date(contact.lastInteraction);
  
  // Calculate the threshold date
  const thresholdDate = new Date();
  thresholdDate.setDate(thresholdDate.getDate() - thresholdDays);
  
  // Return true if the contact should be cold
  return lastInteractionDate < thresholdDate;
};

// Function to calculate days since last interaction
export const daysSinceLastInteraction = (dateString: string): number => {
  const lastInteractionDate = new Date(dateString);
  const now = new Date();
  
  const diffInMs = now.getTime() - lastInteractionDate.getTime();
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
};

// Function to get a friendly time ago string
export const getTimeAgo = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  
  const diffInMs = now.getTime() - date.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  
  if (diffInDays === 0) {
    return 'today';
  } else if (diffInDays === 1) {
    return 'yesterday';
  } else if (diffInDays < 7) {
    return `${diffInDays} days ago`;
  } else if (diffInDays < 30) {
    const diffInWeeks = Math.floor(diffInDays / 7);
    return `${diffInWeeks} ${diffInWeeks === 1 ? 'week' : 'weeks'} ago`;
  } else if (diffInDays < 365) {
    const diffInMonths = Math.floor(diffInDays / 30);
    return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
  } else {
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
  }
};